<template>
    <el-footer class="footer-view">
        <div class="footer">
            <h4> © {{ new Date().getFullYear() }} Personal invest Co., Inc. Todos os direitos reservados. </h4>
        </div>
    </el-footer>
</template>
  
<script>

export default {
    name: 'footer-view'
}

</script>
  
<style lang="scss" scoped>

    .footer-view {
        display: flex;
        bottom: 0;
        align-items: center;
    }

    .footer {
        display: flex;
        bottom: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: $default-layout-color;
        font-weight: bold;
        color: white;
        box-shadow: rgba(0.1, 0.1, 0.1, 0.1) 10px 10px 15px;
    }

    .el-footer {
        --el-footer-padding: 0px;
    }

</style>
