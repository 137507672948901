import { DEFAULT_REQUEST_TIMEOUT } from '../constants/AppConstants';
import axios from 'axios';
import { getUserTokenCache } from '../util/UserUtils';
import { refreshToken } from '../util/UserUtils';

export const httpConfiguration = (baseURL) => {

    const http = axios.create({
        baseURL,
        timeout: DEFAULT_REQUEST_TIMEOUT
    });

    http.defaults.headers.common['Content-Type'] = 'application/json'
    http.defaults.headers.common['Accept'] = 'application/json'
    http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    http.interceptors.request.use(
        config => {

            let token = getUserTokenCache();

            if(token) {
                config.headers['x-invest-api-token'] = `${token}`;
            }

            return config;

        },
        error => Promise.reject(error)
    );

    http.interceptors.response.use(
        response => response,
        error => {

            if (error !== null && error.response !== null && error.response !== undefined && error.response !== null && error.response.status !== null && error.response.status !== undefined && error.response.status === 401) {
               return refreshToken(error);
            }

            return Promise.reject(error);

        }
    );

    return http;

};
