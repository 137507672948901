<template>

    <div class="update-company-alert-dialog">

        <div v-if="alert.company !== null && alert.company.type !== undefined" class="body-info">
            <div class="image-name">
                <img v-if="alert.company.imageUrl != null" style="margin-right: 10px" :src="alert.company.imageUrl" width="30" height="20">
                <span :class="alert.tagTypeClassName"> {{ alert.company.name }} </span>
            </div>
        </div>

        <div class="body-info">
            <span class="price"> Preço atual </span>
            <span v-loading="companyDetailsLoading" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">
                {{ getFormattedValueByType() }}
            </span>
        </div>

        <div v-if="alert.id !== null && alert.id !== undefined" class="body-info">

            <span class="price"> <b> Tipo de alerta </b></span>
            <el-select v-model="alert.type" class="m-2 company-alert-type-select" size="small" filterable>
                <el-option v-for="alertType in alertTypes" :key="alertType" :value="alertType" :label="getAlertTypeDescription(alertType)">
                    <div class="company-alert-option">
                        <span> {{ getAlertTypeDescription(alertType) }} </span>
                    </div>
                </el-option>
            </el-select>

        </div>

        <div v-if="alert.id !== null && alert.id !== undefined" class="body-info">

            <span class="price"> <b> Valor </b></span>
            <currency-input v-if="alert.alertPrice !== null" v-model="alert.alertPrice" :options="getCompanyCurrency(alert.company)" class="price-input"/>

        </div>

        <div v-if="alert.id !== null && alert.id !== undefined" class="body-info">
            <span class="price"> <b> Notificação por e-mail </b></span>
            <el-switch v-model="alert.sendEmail"></el-switch>
        </div>

        <div v-if="alert.id !== null && alert.id !== undefined" class="body-info">
            <span class="price"> <b> Notificação por Web Push </b></span>
            <el-switch v-model="alert.sendWebPush"></el-switch>
        </div>

    </div>

</template>

<script>

    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
    import CompanyType from '@/constants/CompanyType';
    import CompanyAlertType from '@/constants/CompanyAlertType';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { getFormattedValue } from '@/util/MoneyUtils';
    import { getCompanyDetails } from '@/http/bff/company-bff-service';

    export default {
        name: 'update-company-alert-dialog',
        components: {
            CurrencyInput
        },
        data() {
            return {
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                CompanyType,
                selectedCompanyDetails: null,
                companyDetailsLoading: false,
                loadingSvg: DEFAULT_LOADING_SVG
            }
        },
        props: {
            companyAlert: Object,
            currencyInputOptions: Function
        },
        created() {
            this.getCompanyDetails(this.companyAlert.company.name);
        },
        methods: {
            getCompanyDetails(name) {
                this.companyDetailsLoading = true;
                getCompanyDetails(name, "DAY").then(response => {
                    this.selectedCompanyDetails = response.data;
                    this.companyDetailsLoading = false;
                }).catch(() => this.companyDetailsLoading = false);
            },
            getCompanyCurrency(company) {
                return this.currencyInputOptions(company);
            },
            getCompanyAmountClass() {
                return this.companyAlert.company.type !== this.CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";
            },
            getFormattedValue(company) {

                if(company.type !== null) {
                    
                    const total = company.amount * company.price;
                    const locale = CompanyType[company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getAlertTypeDescription(type) {
                return CompanyAlertType[type].description;
            },
            getFormattedValueByType() {

                if(this.selectedCompanyDetails !== null && this.selectedCompanyDetails.type !== null) {
                    const locale = CompanyType[this.selectedCompanyDetails.type].locale;
                    return getFormattedValue(this.selectedCompanyDetails.currentMarketValue, locale);
                }

                return "-";

            }
        },
        computed: {
            alert() {
                return this.companyAlert;
            },
            alertTypes() {
                return Object.keys(CompanyAlertType);
            }
        }
    }

</script>

<style lang="scss">

    .update-company-alert-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .body-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px !important;
    }

    .amount {
        margin-bottom: 5px;
        text-align: start;
    }

    .price {
        margin-bottom: 5px;
        text-align: start;
    }

    .total {
        text-align: start;
    }

    .price-input {
        width: 60% !important;
        text-align: center;
    }

    .el-input-number {
        width: 60% !important;
    }

    .company-alert-option {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2px;
    }

    .image-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1024px) {

        .amount {
            font-size: 8;
        }

        .price {
            font-size: 8;
        }

        .total {
            font-size: 8;
        }

        .price-input {
            padding: 6px 0px;
        }

        .company-alert-type-select {
            width: 150px;
            height: 30px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .amount {
            font-size: 12;
        }

        .price {
            font-size: 12;
        }

        .total {
            font-size: 12;
        }

        .price-input {
            padding: 8px 0px;
        }

    }

</style>
