export const convertBase64ToImage = (base64) => {

    const imageContent = Buffer.from(base64, 'base64');
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.at(n);
    }
    const type = 'image/png';
    const blob = new Blob([buffer], { type });

    return URL.createObjectURL(blob);

}
