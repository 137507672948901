<template>

    <div class="section-row" :class="{ 'first-section-row': isFirstSection }">
        <slot></slot>
    </div>

</template>

<script>
    export default {
        name: 'section-row',
        props: {
            isFirstSection: Boolean
        }
    }
</script>

<style lang="scss" scoped>

    .section-row {
        width: 100%;
    }

    .first-section-row {
        margin-top: 20px !important;
    }

    @media screen and (max-width: 1024px) {

        .section-row {
            margin-top: 20px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .section-row {
            margin-top: 100px;
        }

    }

</style>
