import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import mitt from 'mitt'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { vMaska } from "maska"
import { Buffer } from 'buffer';
import { Chart as ChartJS } from 'chart.js'
import { getSensitiveDataFlag } from  '../src/util/LocalStorageUtils.js';

ChartJS.defaults.color = "white";

window.Buffer = Buffer;
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router);
app.use(ElementPlus);
app.directive("maska", vMaska);
app.mount('#app');
app.directive("check-sensitive-data", (el) => {

    const showSensitiveData = getSensitiveDataFlag();
    if(showSensitiveData !== null && showSensitiveData !== undefined) {

        if(showSensitiveData === "false") {
            el.innerText = "-";
        }

    } else {
        el.innerText = "-";
    }

});

export const EMITTER = emitter;
