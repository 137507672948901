<template>

    <div class="execute-pending-order-dialog">

        <div v-if="executedPendingOrder.status === OrderStatus.EXECUTED.value" class="dates">
        <span> Data de execução </span>
        <el-date-picker v-model="executedPendingOrder.executedAt" type="datetime" format="DD/MM/YYYY HH:mm:ss" placeholder="Data de execução" size="small"/>
      </div>

    </div>

</template>

<script>

    import OrderStatus from '@/constants/OrderStatus';

    export default {
        name: 'execute-pending-order-dialog',
        data() {
            return {
                OrderStatus
            }
        },
        props: {
            pendingOrder: Object
        },
        computed: {
            executedPendingOrder() {
                return this.pendingOrder;
            }
        }
    }

</script>

<style lang="scss" scoped>

    .execute-pending-order-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    @media screen and (max-width: 1024px) {

        .dates {
            width: 80%;
        }
        
    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .dates {
            width: 80%;
        }

    }

</style>
