<template>
  <div class="table-information">
    <div :style="getBorderStyle" class="border-info"></div>
    <span class="text-info"> {{ text }} </span>
  </div>
</template>

<script>

export default {
  name: 'table-information',
  props: {
    bgcolor: String,
    bcolor: String,
    text: String
  },
  computed: {
    getBorderStyle() {

      if(this.bgcolor && this.bcolor) {
        return {
          "backgroundColor": this.bgcolor,
          "borderColor": this.bcolor
        };
      }

      return {};
      
    }
  }
}
</script>

<style scoped>

  .table-information {
    width: 50%;
    text-align: left;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .border-info {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-style: solid;
    border-width: 0.5px;
  }

  .text-info {
    font-style: italic;
    color: #ddd !important;
  }

</style>
