import { locale } from '../constants/LocaleConstants';

export const getTotalValue = (value1, value2) => {

    if(value1 > 0 && value2 > 0) {
        let total = value1 * value2;
        return total.toFixed(2);
    }

    return 0;

};

export const getFormattedValue = (value, valueLocale) => {

    const localeValue = locale[valueLocale];

    if(localeValue != null) {

        let valuePtFormatted = value.toLocaleString("pt-br", { style: 'currency', currency: "BRL" });

        if(localeValue.lang == "us") {
            valuePtFormatted = valuePtFormatted.replace("R$", "US$");
        }

        return valuePtFormatted;

    }

    return "-";

};
