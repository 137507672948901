<template>

    <div class="user-view">
        <router-view></router-view>
    </div>

</template>
  
<script>
    export default {
        name: 'user-view'
    }
</script>
  
<style lang="scss" scoped>

.user-view {
    width: inherit;
    height: inherit;
}

</style>
