<template>

    <div class="update-recurrence-dialog">

        <div class="recurrence-info-type">

            <div class="recurrence-info-company-row">

                <div class="dates recurrence-info-recurrence-container">
                    <span> Data de execução </span>
                    <el-date-picker v-model="recurrence.scheduleDate" type="date" format="DD/MM/YYYY" placeholder="Data de execução" size="small"/>
                </div>

            </div>

            <div class="recurrence-info-company-row">

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Categoria </span>
                    <el-tag :key="recurrence.company.id" class="mx-1 table-tag" :disable-transitions="false">
                        <span class="el-tag__content" :class="getTagClass(recurrence.company.type)"> {{ getCompanyTypeText(recurrence.company.type) }} </span>
                    </el-tag>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Ativo </span>
                    <el-tag :key="recurrence.company.id" class="mx-1 table-tag" :disable-transitions="false">
                        <img v-if="recurrence.company.imageUrl != null" style="margin-right: 10px; cursor: pointer;" :src="recurrence.company.imageUrl" width="30" height="20">
                        <span class="el-tag__content"> {{ recurrence.company.name }} </span>
                    </el-tag>
                </div>

            </div>

            <div class="recurrence-info-company-row">

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Quantidade </span>
                    <div v-if="recurrence.company.id !== null && recurrence.company.type !== null && recurrence.company.type !== undefined">
                        <el-input-number v-if="recurrence.company.type !== CompanyType.STOCK.value" v-model="recurrence.amount" :min="1"/>
                        <el-input-number v-if="recurrence.company.type === CompanyType.STOCK.value" v-model="recurrence.amount" :min="DEFAULT_STOCK_INITIAL_VALUE" :precision="DEFAULT_STOCK_DECIMAL" :step="DEFAULT_STOCK_INITIAL_VALUE"/>
                    </div>
                    <div v-if="recurrence.company.id === null || recurrence.company.type === null || recurrence.company.type === undefined">
                    -
                    </div>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Preço </span>
                    <el-tooltip v-if="recurrence.atMarketValue === true" class="box-item" effect="dark" content="Preço de mercado" placement="top">
                        <currency-input :disabled='recurrence.atMarketValue' :class="isDisabledClass" v-if="recurrence.company.id !== null" v-model="recurrence.price" :options="getCompanyCurrency(recurrence.company.type)" class="price-input"/>
                    </el-tooltip>
                    <currency-input :disabled='recurrence.atMarketValue' :class="isDisabledClass" v-if="recurrence.atMarketValue === false && recurrence.company.id !== null" v-model="recurrence.price" :options="getCompanyCurrency(recurrence.company.type)" class="price-input"/>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Preço de mercado? </span>
                    <el-switch v-if="recurrence.company.id !== null && recurrence.company.type !== null && recurrence.company.type !== undefined" v-model="recurrence.atMarketValue"/>
                    <div v-if="recurrence.company.id === null || recurrence.company.id === undefined || recurrence.price === null">
                    -
                    </div>
                </div>

            </div>

            </div>

            <div class="recurrence-total-info-type">

            <span class="recurrence-info-type-title"> Total: </span>
            <div class="recurrence-info-row">

                <div class="recurrence-info-company-container">
                    <el-tag :key="recurrence.company.id" class="mx-1 table-tag" :disable-transitions="false">
                        <span class="el-tag__content"> {{ total }} </span>
                    </el-tag>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import CompanyType from '@/constants/CompanyType';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL } from '@/constants/AppConstants';
    import { getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'update-recurrence-dialog',
        components: { CurrencyInput },
        props: {
            recurrenceToUpdate: Object,
            dollarValue: Number
        },
        data() {
            return {
                recurrence: {
                    company: {
                        id: null,
                        type: null
                    },
                    amount: 1,
                    price: null,
                    atMarketValue: true,
                    scheduleDate: null
                },
                CompanyType,
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL
            }
        },
        created() {
            this.recurrence = this.recurrenceToUpdate;
        },
        methods: {
            getTagClass(type) {
                return CompanyType[type].tagTypeClassName;
            },
            getCompanyTypeText(type) {
                return CompanyType[type].description;
            },
            getCompanyCurrency(type) {

                if(type === CompanyType.STOCK.value) {
                    return { currency: "USD" };
                }

                return { currency: "BRL" };

            }
        },
        computed: {
            isDisabledClass() {
                return this.recurrence.atMarketValue === true ? "price-disabled" : "";
            },
            total() {

                if(this.recurrence.company.id !== null && this.recurrence.company.type !== null && this.recurrence.price !== null) {

                    const locale = CompanyType[this.recurrence.company.type].locale;
                    let total = this.recurrence.amount * this.recurrence.price;
                    if(this.dollarValue !== null && this.recurrence.company.type === CompanyType.STOCK.value) {
                        total = total * this.dollarValue;
                    }

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            }
        }
    }

</script>

<style lang="scss" scoped>

    .update-recurrence-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .price-disabled {
        opacity: 0.6;
    }

    .table-tag {
        width: 90%;
    }

    .recurrence-info-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .recurrence-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .recurrence-info-title {
        font-weight: bold;
        font-size: 14px;
        margin-top: 5px;
        color: $default-primary-title-text-color !important;
    }

    .recurrence-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .recurrence-total-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .recurrence-info-company-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .recurrence-info-recurrence-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    @media screen and (max-width: 1024px) {

        .dates {
            width: 80%;
        }

        .recurrence-info-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .recurrence-info-company-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        
    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .dates {
            width: 80%;
        }

        .recurrence-info-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .recurrence-info-company-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

    }

</style>
