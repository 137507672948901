import { httpConfiguration } from '../base-service';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/user-bff-service/v1');

export const getUserInfo = () => http.get('/user');

export const updateUser = (user) => {
  return http.put('/user/', user);
};

// notification = { 'token': 'ey...' }
export const setUserNotificationToken = (notification) => http.post('/user/notification', notification);

export const getUserNotificationsConfig = () => http.get('/notification/config');

export const updateUserNotificationsConfig = (id, body) => http.put('/notification/config/' + id, body);

export const getUserPrivacyConfig = () => http.get('/privacy/config');

export const updateUserPrivacyConfig = (id, body) => http.put('/privacy/config/' + id, body);

export const getUsersByUsername = (username) => {
  return http.get('/user/' + username + "/username");
};

export const createUserViewerConfig = (body) => {
  return http.post('/privacy/view', body);
};

export const deleteUserViewerConfig = (id) => http.delete('/privacy/view/' + id);

export const updateUserViewerConfig = (id) => http.put('/privacy/view/' + id);
