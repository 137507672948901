<template>

    <confirm-dialog :visible="updateCompanyBalanceModalVisible"
        headerText="Realmente deseja ajustar o balanço?"
        confirmText="Confirmar"
        cancelText="Cancelar"
        @confirm="updateCompanyLastBalance()"
        @cancel="closeUpdateCompanyLastBalanceDialog()">
        <template #body>
          <update-company-last-balance-dialog :companyLastBalance="companyLastBalance" :currencyInputOptions="getCompanyCurrency"/>
        </template>
    </confirm-dialog>

    <h3 style="margin-top: 40px;">
      CARTEIRA
      <el-tooltip class="box-item" effect="dark" content="Ativos da carteira" placement="top">
          <el-icon class="info-icon"><InfoFilled/></el-icon>
      </el-tooltip>
    </h3>
    <div class="filter-collapse">

        <el-collapse>

            <el-collapse-item name="1">

              <template #title>
                <span class="filter-class collapse-border"> Filtros </span>
              </template>

              <div class="tags">
                <span> Categoria: </span>
                <div>
                  <el-select v-if="typeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2" placeholder="Selecione" size="small">
                    <el-option v-for="companyType in typeList" :key="companyType" :label="getTypeText(companyType)" :value="companyType"/>
                  </el-select>
                  <el-tag v-for="selectedType in filter.selectedTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(selectedType)">
                      {{ getTypeText(selectedType) }}
                  </el-tag>
                </div>
              </div>

            </el-collapse-item>

        </el-collapse>

    </div>

    <div class="filter-buttons">
      <button @click="clearFilters()" class="clear-button"> Limpar </button>
      <button @click="applyFilter()" class="search-button"> Buscar </button>
    </div>

    <div class="table-buttons">
      <el-tooltip class="box-item" effect="dark" content="Buscar pelo nome do ativo" placement="top">
        <el-input v-model="filter.searchText" suffix-icon="Search" class="w-10 m-2 search-input" placeholder="ex.: AMZN" @keyup="getCompaniesWithText()"/>
      </el-tooltip>
    </div>

    <table v-loading="tableLoading" class="table-responsive" element-loading-text="Carregando..." :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">
        <thead>

            <tr>
              <th>
                <span> Categoria </span>
                <el-icon v-if="getOrder('type') === 'decrescente'" @click="changeSortOrder('type')"><CaretBottom/></el-icon>
                <el-icon v-if="getOrder('type') === 'crescente' || getOrder('type') === 'cresc'" @click="changeSortOrder('type')"><CaretTop/></el-icon>
              </th>
              <th>
                <span> Nome </span>
                <el-icon v-if="getOrder('name') === 'decrescente'" @click="changeSortOrder('name')"><CaretBottom/></el-icon>
                <el-icon v-if="getOrder('name') === 'crescente' || getOrder('name') === 'cresc'" @click="changeSortOrder('name')"><CaretTop/></el-icon>
              </th>
              <th> Quantidade </th>
              <th> Preço médio </th>
              <th> Investido </th>
              <th> Diferença </th>
              <th> Patrimônio </th>
              <th> Ações </th>
            </tr>

        </thead>

        <tbody v-if="companies.length > 0">

            <tr v-for="company in companyList" :key="company.id">
              <td>
                <span :class="company.typeClassName"> {{ getType(company) }} </span>
              </td>
              <td @click="goToCompanyDetails(company)" style="cursor: pointer;">
                <el-tooltip class="box-item" effect="dark" content="Ir para a página do Status Invest" placement="left">

                  <div class="image-name">
                    <img v-if="company.imageUrl != null" style="margin-right: 10px" :src="company.imageUrl" width="30" height="20">
                    <span :class="company.tagTypeClassName" style="cursor: pointer;"> {{ company.name }} </span>
                  </div>
                  
                </el-tooltip>
              </td>
              <td>
                <span v-if="company.amount != null"> {{ company.amount.toString().replace(".", ",") }} </span>
              </td>
              <td>
                <span v-if="company.amount > 0"> {{ getFormattedPriceValue(company) }} </span>
                <span v-if="company.amount === 0"> - </span>
              </td>
              <td>
                <span v-if="company.amount > 0"> {{ getFormattedTotalValue(company) }} </span>
                <span v-if="company.amount === 0"> - </span>
              </td>
              <td>
                <span v-if="company.amount > 0" :class="getDifferenceClass(company)"> {{ getFormattedDifferenceValue(company) }} </span>
                <span v-if="company.amount === 0" :class="getDifferenceClass(company)"> - </span>
              </td>
              <td>
                <span> {{ getFormattedValue(company) }} </span>
              </td>
              <td class="actions">
                <el-tooltip class="box-item" effect="dark" content="Ajustar ativo" placement="top">
                  <el-button type="info" icon="Edit" circle @click="openUpdateCompanyLastBalanceDialog(company)" class="action-button"/>
                </el-tooltip>
              </td>
            </tr>

        </tbody>

    </table>

    <div v-if="companies.length === 0">
      <EmptyResult></EmptyResult>
    </div>

    <div v-if="companies.length > 0">
        <div class="pagination">
          <el-select v-model="pagination.limit" @change="getCompanies()" class="pagination-select">

            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>

          </el-select>
          <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" @current-page="page" @current-change="setPage"/>
        </div>
        <div class="number-register">
            {{ getNumberOfRegisterText() }}
        </div>
    </div>

</template>

<script>

import { BLANK_PAGE_ARG } from '@/constants/AppConstants';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
import { ElMessage } from 'element-plus';
import { getFormattedValue } from '@/util/MoneyUtils';
import { DEFAULT_SORT_DIVISOR, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import { getCompaniesPaged, updateCompanyLastBalance } from '@/http/bff/company-bff-service';
import CompanyStatus from '@/constants/CompanyStatus';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';
import ConfirmDialog from "@/component/common/ConfirmDialog";
import UpdateCompanyLastBalanceDialog from "./dialog/UpdateCompanyLastBalanceDialog";

export default {
  name: 'company-table-section',
  components: { 
    ConfirmDialog, 
    'update-company-last-balance-dialog': UpdateCompanyLastBalanceDialog, 
    EmptyResult
  },
  data() {
    return {
        companies: [],
        pagination: {
            offset: 0,
            limit: 10,
            total: 0,
            sorts: [
              "type###asc",
              "name###asc"
            ]
        },
        CompanyStatus,
        CompanyType,
        page: 1,
        filter: {
          selectedTypes: [],
          selectedStatus: [ CompanyStatus.ACTIVE.value ],
          searchText: null,
          startDateAt: null,
          endDateAt: null,
          avoidUserCompanies: false
        },
        selectedType: null,
        selectedStatus: null,
        companyInactivatedAux: {},
        tableLoading: false,
        updateCompanyBalanceModalVisible: false,
        id: null,
        loadingSvg: DEFAULT_LOADING_SVG,
        companyLastBalance: {},
        options: [{
          value: '5',
          label: '5'
        }, {
          value: '10',
          label: '10'
        }, {
          value: '20',
          label: '20'
        }, {
          value: '1000',
          label: 'TODOS'
        }]
    }
  },
  mounted() {
    document.title = "Ativos - Personal Invest";
  },
  created() {
    this.getCompanies();
  },
  methods: {
    updateCompanyLastBalance() {

      const newBalance = {
        id: this.companyLastBalance.id,
        amount: this.companyLastBalance.amount,
        price: this.companyLastBalance.price
      };

      updateCompanyLastBalance(this.companyLastBalance.id, newBalance).then(() => {
        ElMessage({
          message: 'Balanço do ativo ajustado com sucesso!',
          type: 'success'
        });
        this.getCompanies();
        this.updateCompanyBalanceModalVisible = false;
        this.companyLastBalance = {};
      }).catch(() => {
        ElMessage({
          message: 'O Balanço do ativo não pode ser ajustado, tente novamente mais tarde!',
          type: 'error'
        });
        this.updateCompanyBalanceModalVisible = false;
        this.companyLastBalance = {};
      })
    },
    closeUpdateCompanyLastBalanceDialog() {
      this.updateCompanyBalanceModalVisible = false;
      this.companyLastBalance = {};
    },
    closeCreateDialog() {
      this.createCompanyModalVisible = false;
    },
    closeUpdateDialog() {
      this.updateCompanyModalVisible = false;
    },
    resetFilterAndPagination() {
      this.filter.offset = 0;
      this.filter.limit = 10;
      this.pagination.offset = 0;
      this.pagination.limit = 10;
    },
    getCompaniesWithText() {
      if(this.filter.searchText !== null && this.filter.searchText !==  undefined && (this.filter.searchText.length >= 3 || this.filter.searchText.length === 0)) {
        this.getCompanies();
      }
    },
    applyFilter() {
      this.resetFilterAndPagination();
      this.getCompanies();
      ElMessage({
        message: 'Filtro aplicado com sucesso!',
        type: 'success'
      });
    },
    clearFilters() {
      this.selectedType = null;
      this.selectedStatus = null;
      this.filter = {
        selectedTypes: [],
        selectedStatus: [ CompanyStatus.ACTIVE.value ],
        searchText: null,
        startDateAt: null,
        endDateAt: null,
        avoidUserCompanies: false
      };
    },
    changeSortOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "desc";
        } else if (sortOrder === "desc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "asc";
        }

        this.getCompanies();

      }

    },
    getOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          return "crescente";
        } else if (sortOrder === "desc") {
          return "decrescente"
        }

      }

      return "cresc";

    },
    getNumberOfRegisterText() {

      const offset = this.pagination.offset + 1;
      const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

      return offset + " - " + limit + " de " + this.pagination.total + " ativos";

    },
    getDifferenceClass(company) {

      if(company != null && company.type !== null && company.type !== undefined) {

        const capital = company.amount * company.currentPrice;
        const total = company.amount * company.price;

        const result = capital - total;

        return result >= 0 ? "above-limit" : "under-limit";

      }

      return "";

    },
    getFormattedCurrentPriceValue(company) {

      if(company.type !== null) {
        const locale = CompanyType[company.type].locale;
        return getFormattedValue(company.currentPrice, locale);
      }

      return "-";

    },
    getFormattedTotalValue(company) {

      if(company.type !== null) {

        const locale = CompanyType[company.type].locale;
        const total = company.amount * company.price;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    getFormattedDifferenceValue(company) {

      if(company.type !== null) {

        const locale = CompanyType[company.type].locale;
        const capital = company.amount * company.currentPrice;
        const total = company.amount * company.price;

        const result = capital - total;

        return getFormattedValue(result, locale);

      }

      return "-";

    },
    getFormattedPriceValue(company) {

      if(company.type !== null) {
        const locale = CompanyType[company.type].locale;
        return getFormattedValue(company.price, locale);
      }

      return "-";

    },
    getFormattedValue(company) {

      if(company.type !== null) {
        const locale = CompanyType[company.type].locale;
        return getFormattedValue(company.balance, locale);
      }

      return "-";

    },
    refreshPage() {
      this.$router.go();
    },
    getCompanies() {
      this.tableLoading = true;
      this.filter.avoidUserCompanies = false;
      getCompaniesPaged(this.pagination.offset, this.pagination.limit, this.pagination.sorts, this.filter).then(response => {
        this.companies = response.data.companies;
        this.pagination = response.data.pagination;
        this.tableLoading = false;
      });
    },
    getType(company) {
      return CompanyType[company.type].description;
    },
    getCompanyCurrency(company) {

      if(company.type == CompanyType.STOCK.value) {
        return { currency: "USD", };
      }
      
      return { currency: "BRL" };

    },
    getEditStatusText(status) {
      return status == CompanyStatus.ACTIVE.value ? "Inativar" : "Ativar";
    },
    setPage(page) {
      this.page = page;
      this.pagination.offset = (this.page - 1) * this.pagination.limit;
      this.getCompanies();
    },
    openUpdateCompanyLastBalanceDialog(company) {
      this.companyLastBalance = company;
      this.updateCompanyBalanceModalVisible = true;
    },
    pages() {
      return this.pagination.total % this.pagination.limit == 0 ? this.pagination.total / this.pagination.limit : parseInt(this.pagination.total / this.pagination.limit) + 1;
    },
    changeTypeFilter() {
      this.filter.selectedTypes.push(this.selectedType);
      this.selectedType = null;
    },
    getStatusText(status) {
      return status === null ? "Todos" : CompanyStatus[status].description;
    },
    getTypeText(type) {
      return type === null ? "Todos" : CompanyType[type].description;
    },
    removeTypeFromList(type) {
      
      const typeIndex = this.filter.selectedTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedTypes.splice(typeIndex, 1);
        this.selectedType = null;
      }

    },
    goToCompanyDetails(company) {
      const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
      window.open(formattedUrl, BLANK_PAGE_ARG).focus();
    }
  },
  computed: {
    companyList() {
      this.companies.forEach(company => {
        company.typeClassName = CompanyType[company.type].className;
        company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;
        company.statusClassName = CompanyStatus[company.status].className;
        company.amountClassTypeName = company.type !== CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";
      });
      return this.companies;
    },
    typeList() {
      let typeList = Object.keys(CompanyType);
      return typeList.filter(type => !this.filter.selectedTypes.includes(type));
    },
    statusList() {
      let statusList = Object.keys(CompanyStatus);
      return statusList.filter(status => !this.filter.selectedStatus.includes(status));
    }
  }
}
</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-responsive {
      width: 100%;
      display: block;
      font-size: 8px;
      overflow: auto;
    }

    .el-switch  {
      height: 15px;
      width: 15px;
    }

  }

  .dates {
    margin: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .date-filter {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }

  .date-picker {
    margin-left: 5px;
    margin-right: 5px;
  }

  .current-sort-order {
    color: #ddd;
    font-size: 12px;
  }

  .filter-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .remove-company {
    display: flex;
    flex-direction: column;
  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

</style>
