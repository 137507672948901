<template>

  <div class="dividend-view">

    <h1> Proventos </h1>
    <section-row :isFirstSection="true">
      <dividend-summary-section/>
    </section-row>

  </div>

</template>

<script>

  import SectionRow from '@/component/common/SectionRow';
  import { DASHBOARD_PATH } from '@/constants/RoutesConstants';
  import DividendSummarySection from '@/section/dividend/DividendSummarySection';

  export default {
    name: 'dividend-view',
    components: { SectionRow, DividendSummarySection },
    mounted() {
      document.title = "Proventos - Personal Invest";
    },
    methods: {
      goToHome() {
        this.$router.push({ path: DASHBOARD_PATH });
      }
    }
  }

</script>

<style scoped>

  .dividend-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

</style>
