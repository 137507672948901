<template>
  <div class="empty-result">
    <span class="no-results"> Sem resultados </span>
  </div>
</template>

<script>

export default {
  name: 'empty-result'
}
</script>

<style lang="scss" scoped>

  .empty-result {
    margin: auto;
    width: 90%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 60px;
    color: $default-primary-text-color;
    font-weight: bold;
  }

</style>
