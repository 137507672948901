<template>

  <div class="user-profile">
    
    <h1> Perfil </h1>

    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }" @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Minhas informações
          </span>
        </template>
        <section-row :isFirstSection="true">

          <div v-if="this.user" class="form">

            <div class="photo-el-input">

              <h3> Foto de perfil </h3>

              <div class="update-el-input">

                <el-upload v-model:file-list="fileList" accept="image/png" class="upload-demo" :on-change="uploadImage" :auto-upload="false" :limit="1" list-type="picture">
                  <el-avatar :size="100" :src="getUserPhoto" class="avatar"/>
                  <template #file>
                    <i class="el-icon el-icon--close" @click="removeImage()">
                      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                      </svg>
                    </i>
                      {{ fileName }}
                  </template>
                </el-upload>

              </div>

            </div>

            <el-divider direction="vertical"></el-divider>

            <div class="common-el-input">

              <h3> Usuário </h3>

              <div class="update-el-input">
                <span> Username </span>
                <el-input type="text" prefix-icon="User" @change="initFieldEdit('username')" minlength="1" maxlength="255" v-model="user.username" :class="invalidUsernameFieldValue()" class="name-el-input"/>
              </div>

              <h3> Informações pessoais </h3>

              <div class="update-el-input">
                <span> Primeiro nome </span>
                <el-input type="text" @change="initFieldEdit('firstName')" minlength="1" maxlength="255" v-model="user.firstName" :class="invalidFirstNameFieldValue()" class="name-el-input"/>
                <i> Começa com letra maiúscula </i>
              </div>

              <div class="update-el-input">
                <span> Sobrenome </span>
                <el-input type="text" @change="initFieldEdit('lastName')" minlength="1" maxlength="255" v-model="user.lastName" :class="invalidLastNameFieldValue()" class="name-el-input"/>
                <i> Começa com letra maiúscula </i>
              </div>

              <div class="update-el-input">
                <span> CPF: </span>
                <el-input type="text" @change="initFieldEdit('socialId')" :class="invalidSocialIdFieldValue()" minlength="1" maxlength="255" v-maska data-maska="###.###.###-##" placeholder="123.456.789-10" v-model="user.socialId" class="name-el-input"/>
              </div>

              <div class="update-el-input">
                <span> E-mail </span>
                <el-input type="text" @change="initFieldEdit('email')" :class="invalidEmailFieldValue()" minlength="1" maxlength="255" v-model="user.email" placeholder="foo@foo.com" class="name-el-input"/>
              </div>

              <div class="foot-buttons">
                <el-button type="success" :disabled="!validRequest(user)" @click="updateUser()"> Salvar </el-button>
              </div>

            </div>

          </div>

        </section-row>

      </el-tab-pane>

      <el-tab-pane v-if="notificationsConfig.length > 0" name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }" @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Configurações
          </span>
        </template>
        <section-row :isFirstSection="true">

          <div v-if="notificationsConfig.length > 0" class="notification-section">

            <h3 style="margin-top: 0px;"> Notificações </h3>
            <div v-for="(config, index) in notificationsConfig" :key="config.id" class="notification-config">

              <el-collapse v-model="notificationActiveNames">

                <el-collapse-item :name="index">

                  <template #title>
                    <span class="filter-class collapse-border"> {{ notificationTypeMap[config.type].description }} </span>
                  </template>

                  <div class="notification-collapse-description">
                    <p class="notification-title">
                      {{ notificationTypeMap[config.type].collapseTitle }}
                    </p>
                    <p class="notification-sub-title">
                      {{ notificationTypeMap[config.type].collapseSubTitle }}
                    </p>
                  </div>
                  
                  <div class="notification-config-info">

                    <span> Habilitado </span>
                    <el-switch v-model="config.enabled" @change="config.edited = true"></el-switch>

                    <span v-if="config.enabled === true" style="margin-left: 10px;"> Envio de email </span>
                    <el-switch v-if="config.enabled === true" v-model="config.sendEmail" @change="config.edited = true" style="margin-left: 10px;"></el-switch>

                    <span v-if="config.enabled === true" style="margin-left: 10px;"> Envio de Web Push </span>
                    <el-switch v-if="config.enabled === true" v-model="config.sendWebPush" @change="config.edited = true" style="margin-left: 10px;"></el-switch>

                    <span v-if="config.enabled === true" style="margin-left: 10px;"> Data da notificação </span>
                    <el-date-picker v-if="config.enabled === true" v-model="config.scheduleDate" style="margin-left: 10px; margin-top: 5px;" @change="config.edited = true" :clearable="false" size="small" type="datetime" placeholder="Selecione a data da notificação"/>

                  </div>

                  <div class="foot-buttons">
                    <el-button type="success" :disabled="config.edited === false || config.edited === undefined || config.edited === null" @click="updateUserNotificationsConfig(config)"> Salvar </el-button>
                  </div>

                </el-collapse-item>

              </el-collapse>

            </div>

          </div>

          <div v-if="privacyConfig.configurations.length > 0" class="privacy-section">

            <h3 style="margin-top: 0px;"> Privacidade </h3>
            <div v-for="(config, index) in privacyConfig.configurations" :key="config.id" class="privacy-config">

              <el-collapse v-model="privacyActiveNames">

                <el-collapse-item :name="index">

                  <template #title>
                    <span class="filter-class collapse-border"> {{ privacyTypeMap[config.type].description }} </span>
                  </template>

                  <div class="notification-collapse-description">
                    <p class="notification-title">
                      {{ privacyTypeMap[config.type].collapseTitle }}
                    </p>
                    <p class="notification-sub-title">
                      {{ privacyTypeMap[config.type].collapseSubTitle }}
                    </p>
                  </div>
                  
                  <div class="notification-config-info">

                    <span> Habilitado </span>
                    <el-switch v-model="config.enabled" @change="config.edited = true"></el-switch>

                  </div>

                  <div class="foot-buttons">
                    <el-button type="success" :disabled="config.edited === false || config.edited === undefined || config.edited === null" @click="updateUserPrivacyConfig(config)"> Salvar </el-button>
                  </div>

                </el-collapse-item>

              </el-collapse>

            </div>

          </div>

        </section-row>

      </el-tab-pane>

      <el-tab-pane name="3">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverThree, 'is-active': activeName === '3' }" @mouseover="isMouseOverThree = true" @mouseout="isMouseOverThree = false">
            Ajuda
          </span>
        </template>
        <section-row :isFirstSection="true">

          <div class="help-section">

            <h3> Ajuda </h3>
            <div class="password">
              <span> Esqueceu sua senha? </span>
              <el-button v-loading="loginLoading" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" type="success" @click="resetPassword()" class="reset-button"> Redefinir senha </el-button>
            </div>

          </div>

        </section-row>

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()" > Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { getUserIdentifierFromCache, refreshTokenAndGetUserInfo } from '@/util/UserUtils';
  import { sendResetPasswordToken } from '@/http/user/anonymous-user-service';
  import { getUserInfo, updateUser, getUserNotificationsConfig, updateUserNotificationsConfig, getUserPrivacyConfig, updateUserPrivacyConfig } from '@/http/bff/user-bff-service';
  import { convertBase64ToImage } from '@/util/AppUtils';
  import { DEFAULT_AVATAR_IMAGE_URL } from '@/constants/AppConstants';
  import PrivacyViewerConfigStatus from '@/constants/PrivacyViewerConfigStatus';
  import { ElMessage } from 'element-plus'
  import { getFormattedValue } from '@/util/MoneyUtils';
  import CompanyType from '@/constants/CompanyType';
  import SectionRow from '@/component/common/SectionRow';

  export default {
    name: 'user-profile-view',
    components: { SectionRow },
    data() {
      return {
        PrivacyViewerConfigStatus,
        user: {},
        imageChanged: false,
        fileName: "",
        fileList: [],
        CompanyType,
        notificationsConfig: [],
        privacyConfig: {
          configurations: [],
          viewers: [],
          viewing: []
        },
        notificationTypeMap: {
          PLANNING: {
            description: "Planejamento",
            collapseTitle: "Esqueceu de planejar seu investimento do mês?",
            collapseSubTitle: "Habilite notificações mensais para nunca mais vacilar!"
          },
          INVESTMENT: {
            description: "Investimento",
            collapseTitle: "Esqueceu de investir?",
            collapseSubTitle: "Habilite notificações mensais para nunca mais vacilar!"
          }
        },
        privacyTypeMap: {
          VISIBILITY: {
            value: "VISIBILITY",
            description: "Visibilidade",
            collapseTitle: "Escolha se pode ser encontrado por outros usuários do site",
            collapseSubTitle: "Ao habilitar essa opção seu usuário poderá ser buscado por outros usuários"
          },
          WALLET_VISIBILITY: {
            value: "WALLET_VISIBILITY",
            description: "Carteira",
            collapseTitle: "Escolha se sua cateira pode ser compartilhada",
            collapseSubTitle: "Ao habilitar essa opção sua carteira pode receber solicitações de compartilhamento para ser visualizada"
          }
        },
        companiesTypesConfigs: {
          companyTypeConfig: [],
          total: 0
        },
        getFormattedValue,
        fields: {
          username: false,
          firstName: false,
          lastName: false,
          socialId: false,
          email: false
        },
        activeNames: 1,
        activeName: '1',
        isMouseOverOne: false,
        isMouseOverTwo: false,
        isMouseOverThree: false,
        lastActiveName: '1',
        notificationActiveNames: [],
        privacyActiveNames: []
      }
    },
    mounted() {
      document.title = "Perfil - Personal Invest";
    },
    created() {
      this.getUser();
    },
    methods: {
      initFieldEdit(field) {
        this.fields[field] = true;
      },
      invalidUsernameFieldValue() {

        if(!this.fields.username) {
          return "";
        }

        return this.user.username === null || this.user.username === undefined || this.user.username === "" || this.user.username.length <= 0 || this.user.username.length > 10 ?
        "invalid-field" :
        "valid-field";

      },
      invalidFirstNameFieldValue() {

        if(!this.fields.firstName) {
          return "";
        }

        return this.user.firstName === null ||
        this.user.firstName === undefined ||
        this.user.firstName.length === "" ||
        this.user.firstName.length <= 1 || 
        !/^[A-Z]/.test(this.user.firstName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidLastNameFieldValue() {

        if(!this.fields.lastName) {
          return "";
        }

        return this.user.lastName === null ||
        this.user.lastName === undefined ||
        this.user.lastName.length === "" ||
        this.user.lastName.length <= 1 ||
        !/^[A-Z]/.test(this.user.lastName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidSocialIdFieldValue() {

        if(!this.fields.socialId) {
          return "";
        }

        return this.user.socialId === null || this.user.socialId === undefined || this.user.socialId.length === "" || this.user.socialId.length !== 14 ?
        "invalid-field" :
        "valid-field";

      },
      invalidEmailFieldValue() {

        if(!this.fields.email) {
          return "";
        }

        const emailSpecialCharIndex = this.user.email.indexOf("@");
        const emailDotCharIndex = this.user.email.indexOf(".");

        return this.user.email === null ||
        this.user.email === undefined ||
        this.user.email === "" ||
        this.user.email.length <= 0 || 
        emailSpecialCharIndex <= 0 || 
        emailSpecialCharIndex >= emailDotCharIndex - 1 || 
        emailDotCharIndex >= this.user.email.length - 1 ?
        "invalid-field" :
        "valid-field";

      },
      resetPassword() {

        if(this.user.email !== null && this.user.email !== undefined) {

          const body = {
            email: this.user.email,
          }

          sendResetPasswordToken(body).then(() => {

            ElMessage({
              message: 'Um e-mail será enviado com as instruções necessarias!',
              type: 'success'
            });

            }).catch(error => {

            if(this.hasBadRequestMessage(error)) {

              ElMessage({
                message: error.response.data._embedded.errors[0].message,
                type: 'error'
              });

            } else {
              ElMessage({
                message: "Ocorreu um erro ao tentar enviar o e-mail de recuperação de senha, por favor tente novamente mais tarde!",
                type: 'error'
              });
            }

          });

        }

      },
      updateUser() {

        if(this.validRequest(this.user)) {

          if(!this.imageChanged) {
            this.user.photoBase64 = null;
          }

          this.user.socialId = this.user.socialId.replaceAll(".", "").replaceAll("-", "");
          updateUser(this.user).then(() => {

            ElMessage({
              message: 'Usuário atualizado com sucesso!',
              type: 'success'
            });
            refreshTokenAndGetUserInfo();

          }).catch(() => {
            ElMessage({
              message: 'Usuário não pode ser atualizado, tente novamente mais tarde!',
              type: 'error'
            });
          });

        }

      },
      getUser() {

        const userIdentifier = getUserIdentifierFromCache();
        if(userIdentifier !== null) {

          getUserInfo().then(response => {
            this.user = response.data;
            this.getUserNotificationsConfig();
            this.getUserPrivacyConfig();
          }).catch(() => this.$router.push({ path: HOME_PATH }));

        } else {
          this.$router.push({ path: HOME_PATH });
        }

      },
      uploadImage(e){

        const image = e.raw;
        this.fileName = e.name;
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            const previewImage = e.target.result;
            const base64 = previewImage.split(',');
            this.user.image = {
              base64: base64[1]
            };
            this.user.photoBase64 = base64[1];
            this.imageChanged = true;
        };

      },
      removeImage() {
        this.user.image = null;
        this.user.photoBase64 = null;
        this.fileName = "";
        this.fileList = [];
      },
      validRequest(user) {

        if(user.username === null || user.username === undefined || user.username === "" || user.username.length <= 0 || user.username.length > 10) {
          return false;
        }

        if(user.firstName === null ||
          user.firstName === undefined ||
          user.firstName.length === "" ||
          user.firstName.length <= 1 || 
          !/^[A-Z]/.test(user.firstName)) {
          return false;
        }

        if(user.lastName === null ||
          user.lastName === undefined ||
          user.lastName.length === "" ||
          user.lastName.length <= 1 ||
          !/^[A-Z]/.test(user.lastName)) {
          return false;
        }

        if(user.socialId === null || user.socialId === undefined || user.socialId.length === "" || user.socialId.length !== 14) {
          return false;
        }

        const emailSpecialCharIndex = user.email.indexOf("@");
        const emailDotCharIndex = user.email.indexOf(".");
        if(user.email === null ||
          user.email === undefined ||
          user.email === "" ||
          user.email.length <= 0 || 
          emailSpecialCharIndex <= 0 || 
          emailSpecialCharIndex >= emailDotCharIndex - 1 || 
          emailDotCharIndex >= user.email.length - 1) {
          return false;
        }

        return true;

      },
      updateUserNotificationsConfig(config) {

        updateUserNotificationsConfig(config.id, config).then(() => {

          ElMessage({
            message: 'Configuração atualizada com sucesso!',
            type: 'success'
          });

          this.getUserNotificationsConfig();

          }).catch(() => {
          ElMessage({
              message: 'Configuração não pode ser atualizada, tente novamente mais tarde!',
              type: 'error'
          });
        });

      },
      getUserNotificationsConfig() {
        getUserNotificationsConfig().then(response => {
          this.notificationsConfig = response.data.configurations;
        });
      },
      updateUserPrivacyConfig(config) {

        updateUserPrivacyConfig(config.id, config).then(() => {

          ElMessage({
            message: 'Configuração atualizada com sucesso!',
            type: 'success'
          });

          this.getUserPrivacyConfig();

        }).catch(() => {
          ElMessage({
              message: 'Configuração não pode ser atualizada, tente novamente mais tarde!',
              type: 'error'
          });
        });

      },
      getUserPrivacyConfig() {
        getUserPrivacyConfig().then(response => {
          this.privacyConfig = response.data;
        });
      },
      getPtCurrency() {
        return { currency: "BRL" };
      },
      getType(type) {
        return CompanyType[type].description;
      },
      getDollarValueFormatted() {
        return "Cotação atual " + getFormattedValue(this.companiesTypesConfigs.dollarValue, "pt_br");
      },
      getCompanyConfigPercentage(companyConfig) {
        const percentage = ((companyConfig.monthLimit * 100)/this.userCompanyConfigTotal).toFixed(2);
        return Math.round(percentage) + "%";
      },
      handleChange() {
        this.lastActiveName = this.activeName;
        window.scroll(0, 0);
      },
      goBack() {

        const lastActiveName = this.activeName;

        this.activeName = this.lastActiveName;
        this.lastActiveName = lastActiveName;
        window.scroll(0, 0);

      }
    },
    computed: {
      getUserPhoto() {

        if(this.user.photoBase64 !== undefined && this.user.photoBase64 !== null && this.user.photoBase64 !== '') {
            return convertBase64ToImage(this.user.photoBase64);
        }

        if(this.user.photoUrl !== undefined && this.user.photoUrl !== null && this.user.photoUrl !== '') {
            return this.user.photoUrl;
        }

        return DEFAULT_AVATAR_IMAGE_URL;

      },
      companyTypeList() {
        return Object.keys(CompanyType);
      },
      userCompanyConfigList() {
          this.companiesTypesConfigs.companyTypeConfig.forEach(companyTypeConfig => {
            companyTypeConfig.tagTypeClassName = CompanyType[companyTypeConfig.type].tagTypeClassName;
            companyTypeConfig.edited = false;
          });
          return this.companiesTypesConfigs.companyTypeConfig;
      },
      userCompanyConfigTotal() {
        return this.userCompanyConfigList.reduce((partialSum, config) => {
          return partialSum + config.monthLimit;
        }, 0);
      }
    }
  }

</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-tag {
      width: 80%;
    }

    .el-collapse {
      width: 80%;
    }

    .notification-title {
      font-size: 9px;
    }

    .notification-sub-title {
      font-size: 8px;
    }

    .privacy-title {
      font-size: 9px;
    }

    .privacy-sub-title {
      font-size: 8px;
    }

    .viewers-config-table {
      overflow: auto;
    }

    .user-search-config-table {
      overflow: auto;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width: 20%;
    }

    .el-collapse {
      width: 96%;
    }

    .notification-title {
      font-size: 16px;
    }

    .notification-sub-title {
      font-size: 14px;
    }

    .privacy-title {
      font-size: 16px;
    }

    .privacy-sub-title {
      font-size: 14px;
    }

    .user-search-select {
      width: 100%;
      height: 30px;
    }

  }

  .user-profile {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .form {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .update-el-input {
    float: left;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    flex-direction: column;
    font-weight: bold;
  }

  .name-el-input {
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
  }

  .complete-name-el-input {
    font-weight: bold;
    width: 110%;
    margin-top: 5px;
    background-color: gray;
  }

  .common-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .photo-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }

  .avatar {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avatar:hover {
      cursor: pointer;
      border: 1px solid gray;
  }

  .foot-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;
    width: 100%;
  }

  .company-config-input {
    margin-top: 50px;
  }

  .company-config-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-divider--vertical {
    height: 30em;
    border-left: 1px $default-secondary-color var(--el-border-style);
  }

  .el-divider--horizontal {
    border-top: 1px $default-secondary-color var(--el-border-style);
  }

  .reset-button {
    margin-bottom: 40px;
    margin-top: 5px;
    margin-left: 5px;
  }

  i {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }

  .notification-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .privacy-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
  }

  .notification-config {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .privacy-config {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .notification-config-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
  }

  .privacy-config-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
  }

  .back-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }

  .notification-title {
    font-weight: bold;
    float: left;
    margin-bottom: 0;
  }

  .notification-sub-title {
    font-weight: bold;
    float: left;
    margin: 0;
    font-style: italic;
  }

  .help-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .notification-collapse-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .privacy-collapse-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 20px;
  }

  .icon {
    color: gray !important;
    cursor: default;
  }

</style>