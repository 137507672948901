<template>

    <div class="wallet-summary-section">

        <h3 style="margin-top: 0px;">
            PATRIMÔNIO
            <el-tooltip class="box-item" effect="dark" content="Carteira por categorias e ordens pendentes" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>

        <div class="wallet-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Categoria </th>
                        <th> Patrimônio </th>
                        <th> Pendente </th>
                        <th> Planejado </th>
                        <th> % Atual </th>
                        <th> % Planejada </th>
                    </tr>
                </thead>
                <tbody v-if="walletSummary.walletType.length > 0">
                    <tr v-for="companyType in walletTypeList" :key="companyType">
                        <td class="company-name" style="font-weight: bold;">
                            <span :class="companyType.typeClassName"> {{ getType(companyType.type) }} </span>
                        </td>

                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getConvertedStockValueFormatted(companyType.walletTypeTotal)" placement="top">
                                <span v-check-sensitive-data> {{ getWalletFormattedValue(companyType.type, companyType.walletTypeTotal) }} </span>
                            </el-tooltip>
                            <span v-check-sensitive-data v-if="companyType.type !== CompanyType.STOCK.value"> {{ getWalletFormattedValue(companyType.type, companyType.walletTypeTotal) }} </span>
                        </td>
                        
                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getStockPendingTooltipContent(companyType.walletTypePendingTotal, companyType.type)" placement="top">
                                <span v-check-sensitive-data v-if="this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0" :class="getTotalMonthLimitClass(companyType)">
                                    {{ getWalletFormattedValue(companyType.type, companyType.walletTypePendingTotal) }}
                                    <el-tooltip class="box-item" effect="dark" :content="getPendingTooltipContent(companyType.type)" placement="top">
                                        <el-icon class="info-icon"><InfoFilled/></el-icon>
                                    </el-tooltip>
                                </span>
                                <span v-if="this.walletSummary.userCompaniesConfig.companyTypeConfig.length === 0"> - </span>
                            </el-tooltip>
                            <span v-if="this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0 && companyType.type !== CompanyType.STOCK.value"  v-check-sensitive-data :class="getTotalMonthLimitClass(companyType)">
                                {{ getWalletFormattedValue(companyType.type, companyType.walletTypePendingTotal) }}
                                <el-tooltip class="box-item" effect="dark" :content="getPendingTooltipContent(companyType.type)" placement="top">
                                    <el-icon class="info-icon"><InfoFilled/></el-icon>
                                </el-tooltip>
                            </span>
                        </td>
                        
                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getConvertedStockValueFormatted(getWalletTotal(companyType))" placement="top">
                                <span v-check-sensitive-data> {{ getWalletTotalFormattedValue(companyType) }} </span>
                            </el-tooltip>
                            <span v-check-sensitive-data v-if="companyType.type !== CompanyType.STOCK.value"> {{ getWalletTotalFormattedValue(companyType) }} </span>
                        </td>
                        
                        <td>
                            <span> {{ companyType.walletTypePercentage }} </span>
                        </td>

                        <td>
                            <span> {{ companyType.walletTypePercentageWithPending }} </span>
                        </td>
                    </tr>
                    <tr>

                        <td class="total-row">
                            <span> GERAL </span>
                        </td>

                        <td class="total-row">
                            <span v-check-sensitive-data> {{ geTotalFormatted(walletSummary.walletTypesTotal) }} </span>
                        </td>

                        <td  class="total-row">
                            <span v-check-sensitive-data :class="getTotalLimitClass">
                                {{ geTotalFormatted(walletSummary.walletTypesPendingTotal) }}
                                <el-tooltip class="box-item" effect="dark" :content="getPendingTotalTooltipContent()" placement="top">
                                    <el-icon class="info-icon"><InfoFilled/></el-icon>
                                </el-tooltip>
                            </span>
                        </td>
                        
                        <td class="total-row">
                            <span v-check-sensitive-data> {{ geTotalFormatted(walletSummary.walletTotal) }} </span>
                        </td>

                        <td class="total-row">
                            <span> 100% </span>
                        </td>

                        <td class="total-row">
                            <span> 100% </span>
                        </td>

                    </tr>
                </tbody>

                </table>

                <div v-if="walletSummary.walletType.length === 0">
                <EmptyResult></EmptyResult>
            </div>

        </div>

        <div v-if="walletSummary.walletType.length > 0" class="table-info">
            <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Dentro do limite mensal'"/>
            <table-information :bgcolor="'red'" :bcolor="'red'" :text="'Acima do limite mensal'"/>
        </div>

    </div>
    
</template>

<script>

import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import { getFormattedValue } from '@/util/MoneyUtils';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';
import TableInformation from '@/component/common/TableInformation';

export default {
    name: "wallet-summary-section",
    components: { EmptyResult, TableInformation },
    props: {
        tableLoading: Boolean,
        walletSummary: Object
    },
    data() {
        return {
            CompanyType,
            configTableLoading: false,
            loadingSvg: DEFAULT_LOADING_SVG
        }
    },
    mounted() {
        document.title = "Planejamento - Personal Invest";
    },
    methods: {
        getProgressPendingPercentage(companyType) {

            const monthLimit = this.walletSummary.userCompaniesConfig.companyTypeConfig.find(config => config.type === companyType.type).monthLimit;
            const typePendingTotal = companyType.type === CompanyType.STOCK.value ? companyType.walletTypePendingTotal * this.walletSummary.dollarValue : companyType.walletTypePendingTotal;

            return ((typePendingTotal * 100)/monthLimit).toFixed(2);

        },
        getProgressPendingTypeColor(type) {
            return CompanyType[type].color;
        },
        getTotalMonthLimitClass(company) {

            if(company.type !== null  && company.type !== undefined && this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const config = this.walletSummary.userCompaniesConfig.companyTypeConfig.find(typeConfig => typeConfig.type === company.type);
                const monthLimit = config.monthLimit;
                
                const dividendTotal = this.userCompanyConfigDividendTotalByType(company.type);
                let walletTypePendingTotal = company.walletTypePendingTotal;
                if(company.type === CompanyType.STOCK.value) {
                    walletTypePendingTotal = walletTypePendingTotal * this.walletSummary.dollarValue;
                }

                const total = dividendTotal + monthLimit;

                return walletTypePendingTotal >= total ?  "above-limit" : "under-limit";

            }
            
            return "el-tag__content";

        },
        getWalletTotal(companyType) {

            if(companyType != null && companyType != undefined && companyType.type != null && companyType.type != undefined) {
                return companyType.walletTypePendingTotal + companyType.walletTypeTotal;
            }

            return 0.00;

        },
        getWalletTotalFormattedValue(companyType) {

            if(companyType != null && companyType != undefined && companyType.type != null && companyType.type != undefined) {

                const locale = CompanyType[companyType.type].locale;
                const total = this.getWalletTotal(companyType);

                return getFormattedValue(total, locale);

            }

            return 0.00;

        },
        getWalletFormattedValue(type, value) {

            if(type != null && type != undefined) {
                const locale = CompanyType[type].locale;
                return getFormattedValue(value, locale);
            }

            return 0.00;

        },
        getType(type) {
            return CompanyType[type].description;
        },
        geTotalFormatted(value) {
            return getFormattedValue(value, "pt_br");
        },
        getConvertedStockValueFormatted(value) {
            value = this.walletSummary.dollarValue * value;
            return getFormattedValue(value, "pt_br");
        },
        getStockPendingTooltipContent(value) {

            value = value * this.walletSummary.dollarValue;
            const formattedValue = getFormattedValue(value, "pt_br");
            const formattedDollarValue = getFormattedValue(this.walletSummary.dollarValue, "pt_br");

            return formattedValue + " calculado na cotação de valor " + formattedDollarValue;

        },
        getPendingTooltipContent(companyType) {

            if(this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const typeConfig = this.walletSummary.userCompaniesConfig.companyTypeConfig.find(typeConfig => typeConfig.type === companyType);
                const dividendTotal = this.userCompanyConfigDividendTotalByType(companyType);
                const configTotal = typeConfig.monthLimit + dividendTotal;
                const formattedMonthLimit = getFormattedValue(configTotal, "pt_br");

                return "Limite mensal - " + formattedMonthLimit;

            }

            return "-";

        },
        getPendingTotalTooltipContent() {

            if(this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const total = this.walletSummary.userCompaniesConfig.total;
                const configTotal = total + this.userCompanyConfigDividendTotal;
                const formattedMonthLimit = getFormattedValue(configTotal, "pt_br");
                
                return "Limite geral mensal - " + formattedMonthLimit;

            }

            return "-";

        },
        userCompanyConfigDividendTotalByType(type) {
            return this.userCompanyConfigList.reduce((partialSum, config) => {

                if(type === config.type && config.includeDividend === true) {
                    return partialSum + config.dividendTotal;
                }
                
                return partialSum;

            }, 0);
        }
    },
    computed: {
        walletTypeList() {
            this.walletSummary.walletType.forEach(type => {
                type.typeClassName = CompanyType[type.type].className;
                type.tagTypeClassName = CompanyType[type.type].tagTypeClassName;
            });
            return this.walletSummary.walletType;
        },
        getTotalLimitClass() {
            return this.walletSummary.walletTypesPendingTotal >= this.walletSummary.userCompaniesConfig.total ? "above-limit total-row" : "under-limit total-row";
        },
        userCompanyConfigList() {
            this.walletSummary.userCompaniesConfig.companyTypeConfig.forEach(companyTypeConfig => {
                companyTypeConfig.typeClassName = CompanyType[companyTypeConfig.type].className;
                companyTypeConfig.edited = false;
            });
            return this.walletSummary.userCompaniesConfig.companyTypeConfig;
        },
        userCompanyConfigDividendTotal() {
            return this.userCompanyConfigList.reduce((partialSum, config) => {

                if(config.includeDividend === true) {
                    return partialSum + config.dividendTotal;
                }
                
                return partialSum;

            }, 0);
        }
    }
}

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .wallet-summary-section {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 7px;
        }
        
        .company-name {
            font-size: 7px;
        }

        .table-tag {
            width: 90%;
        }

        .wallet-table {
            overflow: auto;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .wallet-summary-section {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .table-tag {
            width: 80%;
        }

        .table-tag-percentage {
            width: 55px;
        }

        .wallet-summary-section {
            width: 100%;
        }

    }

    

    .section-title {
        text-align: left;
    }

    .wallet-collapse-title {
        color: $default-primary-title-text-color;
    }

    .config-table {
        margin-bottom: 30px;
    }

    .config-table table th {
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: $default-table-total-row-color;
        text-transform: uppercase;
    }

    .wallet-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .above-limit {
      color: #ff484e !important;
    }

    .above-limit span {
      color: #ff484e !important;
    }

    .under-limit {
      color: #008000 !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #008000 !important;
    }

</style>
