export const locale = {
    pt_br: {
        lang: "pt",
        currency: "R$",
        currencyFilter: "BRL",
        langFilter: "pt-br"
    },
    us: {
        lang: "us",
        currency: "US$",
        currencyFilter: "USD",
        langFilter: "en"
    }
};
