import { httpConfiguration } from '../base-service';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/planning-bff-service/v1');

export const getSummary = () => http.get('/summary');

export const getCompanies = (filter = {}) => {

  let params =  { sorts: [], status: [], types: [] };

  if(filter.sorts.length > 0) {
    params["sorts"] = filter.sorts.toString();
  }

  if(filter.selectedStatus.length > 0) {
    params["status"] = filter.selectedStatus.toString();
  }

  if(filter.selectedTypes.length > 0) {
    params["types"] = filter.selectedTypes.toString();
  }

  return http.get('/company/all', { params });

};

export const getCompanyCurrentPrice = (name, id) => {
  let params =  { id: id };
  return http.get('/company/' + name + "/current-price", { params } );
};

export const createPlanningOrder = (order) => {
  return http.post('/order', order);
};

export const getPlanningOrders = (offset = 0, limit = 10, sorts = [], filter = {}) => {

  let params =  { offset, limit, sorts: [], status: [], types: [],  companyTypes: [], startDateAt: "null", endDateAt: "null" };

  if(sorts.length > 0) {
    params["sorts"] = sorts.toString();
  }

  if(filter.selectedStatus.length > 0) {
    params["status"] = filter.selectedStatus.toString();
  }

  if(filter.selectedTypes.length > 0) {
    params["types"] = filter.selectedTypes.toString();
  }

  if(filter.selectedCompanyTypes.length > 0) {
    params["companyTypes"] = filter.selectedCompanyTypes.toString();
  }

  if(filter.searchText !== null && filter.searchText !== undefined && filter.searchText.length > 0) {
    params["searchText"] = filter.searchText;
  }

  if(filter.startDateAt !== null) {
    params["startDateAt"] = filter.startDateAt;
  }

  if(filter.endDateAt !== null) {
    params["endDateAt"] = filter.endDateAt;
  }

  return http.get('/order', { params });

};

export const updatePlanningOrder = (order, id) => http.put('/order/' + id, order);

export const deletePlanningOrder = (id, status) => http.delete('/order/' + id, { headers: { 'status': status } });

export const updateUserCompaniesConfig = (userCompanyConfig) => http.put('/company/config', userCompanyConfig);

export const getPendingOrdersRecurrenceConfig = () => {
  return http.get('/recurrence/config');
};

export const createRecurrence = (recurrence) => {
  return http.post('/recurrence/config', recurrence);
};

export const updateRecurrence = (id, recurrence) => http.put('/recurrence/config/' + id, recurrence);

export const deleteRecurrence = (id) => http.delete('/recurrence/config/' + id);
