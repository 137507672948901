<template>

  <div class="company-dividends-section">

    <h3>
      PROVENTOS
    </h3>
    <div class="table-info">

        <table :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">

            <thead>
                <tr>
                    <th> Data de pagamento </th>
                    <th> Multplicador </th>
                </tr>
            </thead>
            <tbody v-if="companiesDividendsList.length > 0">
                <tr v-for="companyDividend in companiesDividendsList" :key="companyDividend">

                    <td>
                        <el-tag :key="companyDividend.id" class="mx-1 table-tag" :disable-transitions="false">
                        <span> {{ getDateTime(companyDividend.paymentDate) }} </span>
                        </el-tag>
                    </td>

                    <td>
                        <el-tag :key="companyDividend.id" class="mx-1 table-tag" :disable-transitions="false">
                        <span> {{ companyDividend.rate.toString().replace(".", ",") }} </span>
                        </el-tag>
                    </td>

                </tr>
                
            </tbody>

        </table>

        <div v-if="companiesDividendsList.length === 0">
            <EmptyResult></EmptyResult>
        </div>

    </div>

  </div>

</template>

<script>

import EmptyResult from '@/component/common/EmptyResult';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import CompanyType from '@/constants/CompanyType';

export default {
  name: 'company-dividends-section',
  components: { EmptyResult },
  data() {
    return {
      companyDetails: {
        dividendDetails: []
      },
      loadingSvg: DEFAULT_LOADING_SVG
    }
  },
  created() {
    this.registerEvents();
  },
  methods: {
    getDateTime(dateTime) {

      if(dateTime !== undefined && dateTime !== null) {
        var date = new Date(dateTime);
        return date.toLocaleDateString();
      }

      return null;

    },
    registerEvents() {
      this.emitter.on('setCompanyDetailsDividends', (companyDetails) => {
        this.companyDetails = companyDetails;
      });
    }
  },
  computed: {
    companiesDividendsList() {
        this.companyDetails.dividendDetails.forEach(dividend => {
          dividend.typeClassName = CompanyType[this.companyDetails.type].className;
          dividend.tagTypeClassName = CompanyType[this.companyDetails.type].tagTypeClassName;
          dividend.type = this.companyDetails.type;
        });
        return this.companyDetails.dividendDetails;
      },
      companiesDividendsListTotal() {
        return this.companiesDividendsList.reduce((partialSum, companyDividend) => {

          if(CompanyType.STOCK.value === companyDividend.type) {
              return (companyDividend.total * this.companiesDividends.dollarValue) + partialSum;
          }

          return companyDividend.total + partialSum;

        }, 0);
      }
  }
}
</script>

<style lang="scss" scoped>

  .company-dividends-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .table-info {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {

    .table-info {
      overflow: auto;
    }

  }

</style>