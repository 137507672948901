<template>

    <h3>
        COMPOSIÇÃO
        <el-tooltip class="box-item" effect="dark" content="Resumo do total investido" placement="top">
            <el-icon class="info-icon"><InfoFilled/></el-icon>
        </el-tooltip>
    </h3>
    <div class="wallet-summary-section">

        <div class="wallet-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Categoria </th>
                        <th> Patrimônio </th>
                        <th> Pendente </th>
                        <th> Planejado </th>
                        <th> % Atual </th>
                        <th> % Planejada </th>
                    </tr>
                </thead>
                <tbody v-if="walletSummary.walletType.length > 0">
                    <tr v-for="companyType in walletTypeList" :key="companyType">
                        <td class="company-name" style="font-weight: bold;">
                            <span :class="companyType.typeClassName"> {{ getType(companyType.type) }} </span>
                        </td>

                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getConvertedStockValueFormatted(companyType.walletTypeTotal)" placement="top">
                                <span v-check-sensitive-data> {{ getWalletFormattedValue(companyType.type, companyType.walletTypeTotal) }} </span>
                            </el-tooltip>
                            <span v-check-sensitive-data v-if="companyType.type !== CompanyType.STOCK.value"> {{ getWalletFormattedValue(companyType.type, companyType.walletTypeTotal) }} </span>
                        </td>
                        
                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getStockPendingTooltipContent(companyType.walletTypePendingTotal, companyType.type)" placement="top">
                                <span v-check-sensitive-data v-if="walletSummary.userCompaniesConfig.companyTypeConfig.length > 0" :class="getTotalMonthLimitClass(companyType)">
                                    {{ getWalletFormattedValue(companyType.type, companyType.walletTypePendingTotal) }}
                                    <el-tooltip class="box-item" effect="dark" :content="getPendingTooltipContent(companyType.type)" placement="top">
                                        <el-icon class="info-icon"><InfoFilled/></el-icon>
                                    </el-tooltip>
                                </span>
                                <span v-if="walletSummary.userCompaniesConfig.companyTypeConfig.length === 0"> - </span>
                            </el-tooltip>
                            <span v-if="walletSummary.userCompaniesConfig.companyTypeConfig.length > 0 && companyType.type !== CompanyType.STOCK.value"  v-check-sensitive-data :class="getTotalMonthLimitClass(companyType)">
                                {{ getWalletFormattedValue(companyType.type, companyType.walletTypePendingTotal) }}
                                <el-tooltip class="box-item" effect="dark" :content="getPendingTooltipContent(companyType.type)" placement="top">
                                    <el-icon class="info-icon"><InfoFilled/></el-icon>
                                </el-tooltip>
                            </span>
                        </td>
                        
                        <td>
                            <el-tooltip v-if="companyType.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getConvertedStockValueFormatted(getWalletTotal(companyType))" placement="top">
                                <span v-check-sensitive-data> {{ getWalletTotalFormattedValue(companyType) }} </span>
                            </el-tooltip>
                            <span v-check-sensitive-data v-if="companyType.type !== CompanyType.STOCK.value"> {{ getWalletTotalFormattedValue(companyType) }} </span>
                        </td>
                        
                        <td>
                            <span> {{ companyType.walletTypePercentage }} </span>
                        </td>

                        <td>
                            <span> {{ companyType.walletTypePercentageWithPending }} </span>
                        </td>
                    </tr>
                    <tr>

                        <td class="total-row">
                            <span> GERAL </span>
                        </td>

                        <td class="total-row">
                            <span v-check-sensitive-data> {{ geTotalFormatted(walletSummary.walletTypesTotal) }} </span>
                        </td>

                        <td  class="total-row">
                            <span v-check-sensitive-data :class="getTotalLimitClass">
                                {{ geTotalFormatted(walletSummary.walletTypesPendingTotal) }}
                                <el-tooltip class="box-item" effect="dark" :content="getPendingTotalTooltipContent()" placement="top">
                                    <el-icon class="info-icon"><InfoFilled/></el-icon>
                                </el-tooltip>
                            </span>
                        </td>
                        
                        <td class="total-row">
                            <span v-check-sensitive-data> {{ geTotalFormatted(walletSummary.walletTotal) }} </span>
                        </td>

                        <td class="total-row">
                            <span> 100% </span>
                        </td>

                        <td class="total-row">
                            <span> 100% </span>
                        </td>

                    </tr>
                </tbody>

            </table>

            <div v-if="walletSummary.walletType.length === 0">
                <EmptyResult></EmptyResult>
            </div>
            <div v-if="walletSummary.walletType.length > 0 && !isPhoneScreenWidth" class="table-info">
                <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Dentro do limite mensal'"/>
                <table-information :bgcolor="'#ff484e'" :bcolor="'#ff484e'" :text="'Acima do limite mensal'"/>
            </div>

        </div>

        <div v-if="walletSummary.walletType.length > 0 && isPhoneScreenWidth" class="table-info">
            <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Dentro do limite mensal'"/>
            <table-information :bgcolor="'#ff484e'" :bcolor="'#ff484e'" :text="'Acima do limite mensal'"/>
        </div>

        <div v-if="walletSummary.walletType.length > 0" class="doughnut">
            <div class="doughnut-data">
                <Doughnut :data="getData" :options="options" />
            </div>
        </div>

    </div>

</template>

<script>

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import { USER_INFO_CACHE_KEY } from '@/constants/CacheConstants';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import { getFormattedValue } from '@/util/MoneyUtils';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';
import TableInformation from '@/component/common/TableInformation';

ChartJS.register(ArcElement, Tooltip, Legend, Colors)

export default {
    name: "wallet-summary-section",
    components: { EmptyResult, TableInformation, Doughnut },
    props: {
        tableLoading: Boolean,
        walletSummary: Object
    },
    data() {
        return {
            plannedOrders: [],
            CompanyType,
            userCompaniesConfig: {
                companyTypeConfig: [],
                total: 0
            },
            configTableLoading: false,
            loadingSvg: DEFAULT_LOADING_SVG,
            data: {
                labels: [ ],
                datasets: [
                    {
                        labels: [],
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    created() {
        this.setUser();
    },
    methods: {
        setUser() {

            const userJson = localStorage.getItem(USER_INFO_CACHE_KEY);
            if(userJson !== undefined || userJson !== null) {
                this.user = JSON.parse(userJson);
                this.getWalletSummaryBarData();
            }

        },
        getTotalMonthLimitClass(company) {

            if(company.type !== null  && company.type !== undefined && this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const monthLimit = this.walletSummary.userCompaniesConfig.companyTypeConfig.find(typeConfig => typeConfig.type === company.type).monthLimit;
                let walletTypePendingTotal = company.walletTypePendingTotal;
                if(company.type === CompanyType.STOCK.value) {
                    walletTypePendingTotal = walletTypePendingTotal * this.walletSummary.dollarValue;
                }

                return walletTypePendingTotal >= monthLimit ?  "above-limit" : "under-limit";

            }
            
            return "el-tag__content";

        },
        getWalletSummaryBarData() {
     
            if(this.walletSummary.walletType.length > 0) {
                this.data.labels = this.doughnutLabels;
                this.data.datasets[0].backgroundColor = this.doughnutBackgroundColors;
                this.data.datasets[0].data = this.doughnutData;
            }

        },
        getWalletTotal(companyType) {

            if(companyType != null && companyType != undefined && companyType.type != null && companyType.type != undefined) {
                return companyType.walletTypePendingTotal + companyType.walletTypeTotal;
            }

            return 0.00;

        },
        getWalletTotalFormattedValue(companyType) {

            if(companyType != null && companyType != undefined && companyType.type != null && companyType.type != undefined) {

                const locale = CompanyType[companyType.type].locale;
                const total = this.getWalletTotal(companyType);

                return getFormattedValue(total, locale);

            }

            return 0.00;

        },
        getWalletFormattedValue(type, value) {

            if(type != null && type != undefined) {
                const locale = CompanyType[type].locale;
                return getFormattedValue(value, locale);
            }

            return 0.00;

        },
        getType(type) {
            return CompanyType[type].description;
        },
        geTotalFormatted(value) {
            return getFormattedValue(value, "pt_br");
        },
        getConvertedStockValueFormatted(value) {
            value = this.walletSummary.dollarValue * value;
            return getFormattedValue(value, "pt_br");
        },
        getStockPendingTooltipContent(value) {

            value = value * this.walletSummary.dollarValue;
            const formattedValue = getFormattedValue(value, "pt_br");
            const formattedDollarValue = getFormattedValue(this.walletSummary.dollarValue, "pt_br");

            return formattedValue + " calculado na cotação de valor " + formattedDollarValue;

        },
        getPendingTooltipContent(companyType) {

            if(this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const typeConfig = this.walletSummary.userCompaniesConfig.companyTypeConfig.find(typeConfig => typeConfig.type === companyType);
                const formattedMonthLimit = getFormattedValue(typeConfig.monthLimit, "pt_br");

                return "Limite mensal - " + formattedMonthLimit;

            }

            return "-";

        },
        getPendingTotalTooltipContent() {

            if(this.walletSummary.userCompaniesConfig.companyTypeConfig.length > 0) {

                const formattedMonthLimit = getFormattedValue(this.walletSummary.userCompaniesConfig.total, "pt_br");
                
                return "Limite geral mensal - " + formattedMonthLimit;

            }

            return "-";

        }
    },
    computed: {
        walletTypeList() {
            this.walletSummary.walletType.forEach(type => {
                type.typeClassName = CompanyType[type.type].className;
                type.tagTypeClassName = CompanyType[type.type].tagTypeClassName;
            });
            return this.walletSummary.walletType;
        },
        getTotalLimitClass() {
            return this.walletSummary.walletTypesPendingTotal >= this.walletSummary.userCompaniesConfig.total ? "above-limit total-row" : "under-limit total-row";
        },
        userCompanyConfigList() {
            this.walletSummary.userCompaniesConfig.companyTypeConfig.forEach(companyTypeConfig => {
                companyTypeConfig.typeClassName = CompanyType[companyTypeConfig.type].className;
                companyTypeConfig.edited = false;
            });
            return this.walletSummary.userCompaniesConfig.companyTypeConfig;
        },
        getData() {
            return this.data;
        },
        doughnutLabels() {
            return this.walletSummary.walletType.map(wt => CompanyType[wt.type].description);
        },
        doughnutBackgroundColors() {
            return this.walletSummary.walletType.map(wt => CompanyType[wt.type].color);
        },
        doughnutData() {
            return this.walletSummary.walletType.map(wt => {

                if(wt.type === CompanyType.STOCK.value && this.walletSummary.dollarValue > 0) {
                    return wt.walletTypeTotal * this.walletSummary.dollarValue;
                }

                return wt.walletTypeTotal;

            });
        },
        isPhoneScreenWidth() {
            return window.screen.width < 1024;
        }
    }
}

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .doughnut {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 8px;
            margin-top: 20px;
        }

        .doughnut-data {
            height: 300px !important;
        }

        .wallet-summary-section {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 8px;
        }
        
        .company-name {
            font-size: 7px;
        }

        .table-tag {
            width: 90%;
        }

        .wallet-table {
            overflow: auto;
        }

        .progress-summary {
            width: 100%;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .doughnut {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
        }

        .doughnut-data {
            height: 300px;
            width: 300px;
        }

        .wallet-summary-section {
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }

        .table-tag {
            width: 80%;
        }

        .table-tag-percentage {
            width: 55px;
        }

        .progress-summary {
            width: 30%;
        }

    }

    .section-title {
        text-align: left;
    }

    .wallet-collapse-title {
        color: $default-primary-title-text-color;
    }

    .config-table {
        margin-bottom: 30px;
    }

    .config-table table th {
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: $default-table-total-row-color;
        text-transform: uppercase;
    }

    .wallet-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .above-limit {
      color: #ff484e !important;
    }

    .above-limit span {
      color: #ff484e !important;
    }

    .under-limit {
      color: #008000 !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #008000 !important;
    }

    .progress-summary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .progress-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .progress {
        margin-top: 5px;
        width: 100%;
    }

    .table-info {
        width: 100%;
    }

</style>
