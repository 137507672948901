<template>

    <div class="currency-summary-section">

        <h3> 
            COTAÇÃO
            <el-tooltip class="box-item" effect="dark" :content="getTooltipContent()" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        
        <div class="filter-options">

            <div class="flag-options">
                <div @click="selectCurrencyType(currencyType.USD.value)">
                    <div class="flag-content" :class="getFlagOptionInactive(currencyType.USD.value)" @mouseover="isMouseOverUsdOption = true" @mouseout="isMouseOverUsdOption = false">
                        <img src="@/assets/usa-flag.png" class="usd-flag-option">
                        <span> <b> Dólar </b> </span>
                    </div>
                    <div v-if="currencyType.USD.value == selectedCurrencyType || isMouseOverUsdOption" class="active-flag-option"></div>
                </div>
                <div @click="selectCurrencyType(currencyType.EUR.value)">
                    <div class="flag-content"  :class="getFlagOptionInactive(currencyType.EUR.value)" @mouseover="isMouseOverEuroOption = true" @mouseout="isMouseOverEuroOption = false">
                        <img src="@/assets/euro-flag.png" class="flag-option">
                        <span> <b> Euro </b> </span>
                    </div>
                    <div v-if="currencyType.EUR.value == selectedCurrencyType || isMouseOverEuroOption" class="active-flag-option"></div>
                </div>
                <div @click="selectCurrencyType(currencyType.BTC.value)">
                    <div class="flag-content" :class="getFlagOptionInactive(currencyType.BTC.value)" @mouseover="isMouseOverBitcoinOption = true" @mouseout="isMouseOverBitcoinOption = false">
                        <img src="@/assets/bitcoin.png" class="flag-option" >
                        <span> <b> Bitcoin </b> </span>
                    </div>
                    <div v-if="currencyType.BTC.value == selectedCurrencyType || isMouseOverBitcoinOption" class="active-flag-option"></div>
                </div>
            </div>

        </div>

        <div class="date-filter">
            <el-select v-model="selectedDateInterval" @change="getCurrencyHistory()" class="m-2" placeholder="Selecione um intervalo" size="small">
                <el-option v-for="dateIntervalOption in dateIntervalOptions" :key="dateIntervalOption" :label="dateInternvalMap[dateIntervalOption].label" :value="dateIntervalOption"/>
            </el-select>
            <span v-if="currencySummary.items.length > 0 && areCurrenciesFromYesterdayOrToday" class="day-info"> Referente ao dia {{ getLastCurrencyDateFormatted() }} </span>
        </div>

        <div class="statistics">

            <div class="statistic-item">
                <span> <b> {{ dateInternvalMap.DAY.value === selectedDateInterval ? "VALOR ATUAL" : "COTAÇÃO FINAL" }} </b> </span>
                <el-tag :key="currencySummary.currentValue" class="mx-1 current-currency-tag table-tag currency-tag" :disable-transitions="false">
                    <span> {{ getFormattedValue(currencySummary.currentValue) }} </span>
                </el-tag>
            </div>

            <div class="statistic-item">
                <span> <b> MINÍMO {{ dateInternvalMap[selectedDateInterval].description.toUpperCase() }} </b> </span>
                <el-tag :key="currencySummary.mincurrencyValue" class="mx-1 table-tag currency-tag" :disable-transitions="false">
                    <span> {{ getFormattedValue(currencySummary.minValue) }} </span>
                </el-tag>
            </div>

            <div class="statistic-item">
                <span> <b> MÁXIMO {{ dateInternvalMap[selectedDateInterval].description.toUpperCase() }} </b> </span>
                <el-tag :key="currencySummary.maxValue" class="mx-1 table-tag currency-tag" :disable-transitions="false">
                    <span> {{ getFormattedValue(currencySummary.maxValue) }} </span>
                </el-tag>
            </div>

        </div>
        <div v-loading="barLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" class="line">
            <Line v-if="showLine" :data="getData" :options="options"/>
        </div>

    </div>

</template>

<script>

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { Line } from 'vue-chartjs'
import { getCurrencyHistory } from '@/http/user/anonymous-user-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import { getFormattedValue } from '@/util/MoneyUtils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
    name: "currency-summary-section",
    components: { Line },
    data() {
        return {
            loadingSvg: DEFAULT_LOADING_SVG,
            barLoading: false,
            currencyType: {
                USD: {
                    value: "USD",
                    label: "Dólar"
                },
                EUR: {
                    value: "EUR",
                    label: "Euro"
                },
                BTC: {
                    value: "BTC",
                    label: "Bitcoin"
                }
            },
            dateInternvalMap: {
              YESTERDAY: {
                value: "YESTERDAY",
                label: "Ontem",
                description: " ontem"
              },
              DAY: {
                value: "DAY",
                label: "Hoje",
                description: " hoje"
              },
              WEEK: {
                value: "WEEK",
                label: "Semana",
                description: " na semana"
              },
              MONTH: {
                value: "MONTH",
                label: "Mês",
                description: " no mês"
              },
              YEAR: {
                value: "YEAR",
                label: "Ano",
                description: " no ano"
              }
            },
            dateIntervalOptions: [
                "YESTERDAY",
                "DAY",
                "WEEK",
                "MONTH",
                "YEAR"
            ],
            selectedCurrencyType: "USD",
            selectedDateInterval: "DAY",
            currencySummary: {
                items: [],
                currentValue: 0,
                minValue: 0,
                maxValue: 0
            },
            data: {
                labels: [ ],
                datasets: [
                    {
                        label: "Variação",
                        color: "blue",
                        backgroundColor: [ ],
                        data: [ ]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements:{
                    point:{
                        borderWidth: 0,
                        radius: 1,
                        backgroundColor: 'rgba(0,0,0,0)'
                    }
                }
            },
            freshData: false,
            isMouseOverUsdOption: false,
            isMouseOverEuroOption: false,
            isMouseOverBitcoinOption: false
        }
    },
    created() {
        this.getCurrencyHistory();
    },
    mounted() {
        document.title = "Dashboard - Personal Invest";
    },
    methods: {
        getLastCurrencyDateFormatted() {

            if(this.currencySummary.items.length > 0) {

                const date = this.getLastCurrencyDate();
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1;
                let dd = date.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                return dd + '/' + mm + '/' + yyyy;

            }

            return "";

        },
        getLastCurrencyDate() {
            const length = this.currencySummary.items.length;
            return length > 0 ? new Date(this.currencySummary.items[length - 1].createdAt) : null;
        },
        getFlagOptionInactive(currencyType) {

            if(currencyType === this.selectedCurrencyType) {
                return "";
            }

            if(currencyType === this.currencyType.USD.value && this.isMouseOverUsdOption) {
                return "over-flag-option";
            }

            if(currencyType === this.currencyType.EUR.value && this.isMouseOverEuroOption) {
                return "over-flag-option";
            }

            if(currencyType === this.currencyType.BTC.value && this.isMouseOverBitcoinOption) {
                return "over-flag-option";
            }

            return "inactive-flag-option";

        },
        selectCurrencyType(currencyType) {
            this.selectedCurrencyType = currencyType;
            this.getCurrencyHistory();
        },
        getTooltipContent() {
            return "Cotação do " + this.currencyType[this.selectedCurrencyType].label.toLowerCase() + " em relação ao real";
        },
        disabledDate(date) {
            const currentYear = new Date().getFullYear();
            return date.getFullYear() > currentYear || date.getFullYear() < currentYear - 3;
        },
        getFormattedValue(value) {
            return getFormattedValue(value, "pt_br");
        },
        getCurrencyHistory() {

            getCurrencyHistory(this.selectedDateInterval, this.selectedCurrencyType).then(response => {

                this.data = {
                    labels: [ ],
                    datasets: [
                        {
                            label: "",
                            color: "#03a94e",
                            backgroundColor: [ ],
                            data: [ ]
                        }
                    ]
                };
                this.currencySummary = response.data;
                this.changeData();

            });

        },
        changeData() {

            this.currencySummary.items.forEach(item => {

                const date = new Date(item.createdAt).toLocaleDateString();
                const time = new Date(item.createdAt).toLocaleTimeString().substring(0, 5);
                let label = "";
                if(this.selectedDateInterval === "DAY") {
                    label = time;
                } else {
                    label = date + " " + time;
                }

                this.data.labels.push(label);
                this.data.datasets[0].label = "Variação";
                this.data.datasets[0].data.push(item.value);
                this.data.datasets[0].backgroundColor = "#03a94e";
                this.data.datasets[0].fill = true;

            });
            this.freshData = true;

        }
    },
    computed: {
        showLine() {
            return this.freshData;
        },
        getData() {
            return this.data;
        },
        areCurrenciesFromYesterdayOrToday() {

            if(this.selectedDateInterval === this.dateInternvalMap.YESTERDAY.value) {

                const lastCurrencyDate = this.getLastCurrencyDate();
                if(lastCurrencyDate != null) {
                    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                    return lastCurrencyDate.getDay() !== yesterday.getDay();
                }

                return false;

            }

            if(this.selectedDateInterval === this.dateInternvalMap.DAY.value) {

                const lastCurrencyDate = this.getLastCurrencyDate();
                if(lastCurrencyDate != null) {
                    const today = new Date();
                    return lastCurrencyDate.getDay() !== today.getDay();
                }

                return false;

            }

            return false;

        }
    }
}

</script>

<style lang="scss" scoped>
    
    .currency-summary-section {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .el-input__prefix-inner {
        color: black !important;
    }

    .statistics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100px;
        margin-top: 10px;
    }

    .statistic-item {
        width: 33%;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .currency-tag {
        margin-top: 5px;
        padding: 12px !important;
    }

    .current-currency-tag {
        box-shadow:  inset 0px 0px 7px 3px $default-primary-tag-bg-color;
        background-color: $default-primary-tag-bg-color !important;
        color: "#03a94e" !important;
    }

    @media screen and (max-width: 1024px) {

        .el-select {
            width: 21%;
            float: left;
            text-align: left;
            margin-top: 5px;
        }

        .currency-tag {
            width: 60px;
        }

        .flag-options {
            width: 100%;
        }

        .usd-flag-option {
            margin: 20px;
            width: 35px;
            height: 20px;
        }

        .flag-option {
            margin: 20px;
            width: 20px;
            height: 20px;
        }

        .line {
            height: 300px !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .el-select {
            width: 10%;
            float: left;
            text-align: left;
            margin-top: 5px;
        }

        .currency-tag {
            width: 120px;
        }

        .flag-options {
            width: 40%;
        }

        .usd-flag-option {
            margin: 20px;
            width: 55px;
            height: 40px;
        }

        .flag-option {
            margin: 20px;
            width: 40px;
            height: 40px;
        }

        .line {
            height: 250px;
        }

    }

    .filter-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .flag-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .flag-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .active-flag-option {
        height: 1px;
        border-bottom: 1px solid $default-primary-title-text-color;
        width: 130%;
    }

    .inactive-flag-option {
        opacity: 0.4;
    }

    .over-flag-option {
        opacity: 1;
    }

    .date-filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .day-info {
        font-weight: bold;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 10px;
        font-style: italic;
    }

</style>