<template>

    <div class="company-orders-section">

      <h3>
        MINHAS TRANSAÇÕES
      </h3>
      <div class="table-responsive">

        <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

          <thead>

            <tr>
              <th> <span> Categoria </span> </th>
              <th> Quantidade </th>
              <th> Preço </th>
              <th> Total da ordem </th>
              <th> Ação </th>
              <th>
                <span> Executada em </span>
                <el-icon v-if="getOrder('executedAt') === 'decrescente'" @click="changeSortOrder('executedAt')"><CaretBottom/></el-icon>
                <el-icon v-if="getOrder('executedAt') === 'crescente' || getOrder('executedAt') === 'cresc'" @click="changeSortOrder('executedAt')"><CaretTop/></el-icon>
              </th>
              <th> Criada em </th>
              <th> Origem </th>
            </tr>

          </thead>

          <tbody v-if="orders.length > 0">

            <tr v-for="order in orderList" :key="order.id">
              <td>
                <span :class="order.tagCompanyTypeClassName"> {{ getType(order) }} </span>
              </td>
              <td>
                  <span> {{ getFormattedAmount(order) }} </span>
              </td>
              <td>
                <span> {{ getOrderPriceValue(order) }} </span>
              </td>
              <td>
                <span> {{ getOrderTotalValue(order) }} </span>
              </td>
              <td>
                <span :class="order.tagOrderTypeClassName"> {{ getAction(order) }} </span>
              </td>
              <td>
                <span>  {{ getDateTime(order.executedAt) }} </span>
              </td>
              <td>
                <span>  {{ getDateTime(order.createdAt) }} </span>
              </td>
              <td>
                <span :class="order.originClassName"> {{ getOrigin(order.origin) }} </span>
              </td>
            </tr>

          </tbody>

        </table>

        <div v-if="orders.length === 0 && !tableLoading">
          <EmptyResult></EmptyResult>
        </div>

      </div>

      <div v-if="orders.length > 0">

        <div class="pagination">
            <el-select v-model="pagination.limit" @change="getCompanyOrders()" class="pagination-select">

            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>

            </el-select>
            <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" :current-page="page" @current-change="setPage"/>
        </div>

        <div class="number-register">
            {{ getNumberOfRegisterText() }}
        </div>

      </div>

    </div>

</template>

<script>

import { DEFAULT_STOCK_DECIMAL, COMMA, DOT, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import { getTotalValue, getFormattedValue } from '@/util/MoneyUtils';
import { getCompanyOrders } from '@/http/bff/company-bff-service';
import { DEFAULT_SORT_DIVISOR } from '@/constants/AppConstants';
import OrderStatus from '@/constants/OrderStatus';
import OrderType from '@/constants/OrderType';
import OrderOrigin from '@/constants/OrderOrigin';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';

export default {
  name: 'company-orders-section',
  components: { EmptyResult },
  props: { name: String },
  data() {
    return {
      orders: [],
      pagination: {
        offset: 0,
        limit: 10,
        total: 0,
        sorts: [
          "executedAt###desc",
          "name###asc"
        ]
      },
      OrderStatus,
      OrderType,
      OrderOrigin,
      page: 1,
      tableLoading: false,
      loadingSvg: DEFAULT_LOADING_SVG,
      options: [{
        value: '5',
        label: '5'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '10000000',
        label: 'TODOS'
      }]
    }
  },
  created() {
    this.getCompanyOrders();
  },
  methods: {
    getFormattedAmount(order) {

      if(order.company.type !== null && order.company.type === CompanyType.STOCK.value) {
        return order.amount.toFixed(DEFAULT_STOCK_DECIMAL).replace(DOT, COMMA);
      }

      return order.amount;

    },
    changeSortOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "desc";
        } else if (sortOrder === "desc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "asc";
        }

        this.getCompanyOrders();

      }

    },
    getOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          return "crescente";
        } else if (sortOrder === "desc") {
          return "decrescente"
        }

      }

      return "cresc";

    },
    removeStatusFromList(status) {
      
      const statusIndex = this.filter.selectedStatus.findIndex(statusFromList => statusFromList === status);
      if(statusIndex >= 0) {
        this.filter.selectedStatus.splice(statusIndex, 1);
        this.selectedStatus = null;
      }

    },
    removeTypeFromList(type) {
      
      const typeIndex = this.filter.selectedTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedTypes.splice(typeIndex, 1);
        this.selectedType = null;
      }

    },
    removeCompanyTypeFromList(type) {

      const typeIndex = this.filter.selectedCompanyTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedCompanyTypes.splice(typeIndex, 1);
        this.selectedCompanyType = null;
      }

    },
    getNumberOfRegisterText() {

      const offset = this.pagination.offset + 1;
      const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

      return offset + " - " + limit + " de " + this.pagination.total + " ordens";

    },
    getCompanyOrders() {
      this.tableLoading = true;
      getCompanyOrders(this.name, this.pagination.offset, this.pagination.limit, this.pagination.sorts).then(response => {
        this.orders = response.data.orders;
        this.pagination = response.data.pagination;
        this.tableLoading = false;
      }).catch(() => this.tableLoading = false);
      
    },
    getType(order) {
      return CompanyType[order.company.type].description;
    },
    getAction(order) {
      return OrderType[order.type].description;
    },
    getDateTime(dateTime) {

      if(dateTime !== undefined && dateTime !== null) {
        var date = new Date(dateTime);
        return date.toLocaleDateString();
      }

      return null;

    },
    getOrigin(origin) {
      return OrderOrigin[origin].description;
    },
    minus() {
      return "<";
    },
    plus() {
      return ">";
    },
    setPage(page) {
      this.page = page;
      this.pagination.offset = (this.page - 1) * this.pagination.limit;
      this.getCompanyOrders();
    },
    lastPage() {
      if(this.page !== undefined && this.page > 1) {
        this.page--;
        this.pagination.offset = (this.page - 1) * this.pagination.limit;
        this.getCompanyOrders();
      }
    },
    nextPage() {
      if(this.page < this.pages()) {
        this.page++;
        this.pagination.offset = (this.page - 1) * this.pagination.limit;
        this.getCompanyOrders();
      }
    },
    pages() {
      return this.pagination.total % this.pagination.limit == 0 ? this.pagination.total / this.pagination.limit : parseInt(this.pagination.total / this.pagination.limit) + 1;
    },
    changeTypeFilter() {
      this.filter.selectedTypes.push(this.selectedType);
      this.selectedType = null;
    },
    changeStatusFilter() {
      this.filter.selectedStatus.push(this.selectedStatus);
      this.selectedStatus = null;
    },
    changeCompanyTypeFilter() {
      this.filter.selectedCompanyTypes.push(this.selectedCompanyType);
      this.selectedCompanyType = null;
    },
    getTypeText(type) {
      return type === null ? "Todos" : OrderType[type].description;
    },
    getCompanyTypeText(type) {
      return type === null ? "Todos" : CompanyType[type].description;
    },
    getOrderPriceValue(order) {

      if(order.company !== null && order.company.type !== null) {
        const locale = CompanyType[order.company.type].locale;
        return getFormattedValue(order.price, locale);
      }

      return "-";

    },
    getOrderTotalValue(order) {

      if(order.company.type !== null) {

        const total = parseFloat(getTotalValue(order.amount, order.price));
        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    }
  },
  computed: {
    orderList() {
      
      this.orders.forEach(order => {
        order.typeClassName = OrderType[order.type].className;
        order.statusClassName = OrderStatus[order.status].className;
        order.companyTypeClassName = CompanyType[order.company.type].className;
        order.tagOrderTypeClassName = OrderType[order.type].tagTypeClassName;
        order.tagCompanyTypeClassName = CompanyType[order.company.type].className;
        order.originClassName = order.origin === "MANUAL" ? "local-origin-tag-class" : "cei-origin-tag-class";
      });

      return this.orders;

    },
    selectedStatusColumnText() {
      return this.filter.selectedStatus == OrderStatus.EXECUTED.value ? "Executada" : "Pendente";
    },
    typeList() {
      let typeList = Object.keys(OrderType);
      return typeList.filter(type => !this.filter.selectedTypes.includes(type));
    },
    statusList() {
      let statusList = Object.keys(OrderStatus);
      return statusList.filter(status => !this.filter.selectedStatus.includes(status));
    },
    companyTypeList() {
      let companyTypeList = Object.keys(CompanyType);
      return companyTypeList.filter(type => !this.filter.selectedCompanyTypes.includes(type));
    }
  }
}
</script>

<style lang="scss" scoped>

  .company-orders-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .table-responsive {
    margin-top: 10px;
    margin-left: 5px;
  }

  @media screen and (max-width: 1024px) {

    .table-responsive {
      width: 100%;
      display: block;
      font-size: 8px;
      overflow: auto;
    }

    .table-tag {
      width: 90%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width: 70%;
    }

  }

  .orderedHeader {
    cursor: pointer;
  }

  .executed-order {
    color: #42b983;
  }

  .pending-order {
    color: rgb(124, 124, 14);
  }
  .number-register {
    display: flex;
    flex-direction: row;
    margin: 1px;
    margin-top: 15px;
    text-align: center;
    float: right;
    color: gray;
  }

  .name-item {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
  }

  .name {
    margin-top: 10px;
  }

  .date-picker {
    margin-left: 5px;
    margin-right: 5px;
  }

  .current-sort-order {
    color: #ddd;
    font-size: 12px;
  }

  .filter-buttons {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .dates {
    margin: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .date-filter {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }

  .file-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

</style>

