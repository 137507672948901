<template>

    <default-dialog :visible="loginDialogVisible"
        headerText="Digite suas credenciais para continuar"
        :closeDialog="() => loginDialogVisible = false">
        <template #body>
            <login-section :closeDialog="() => loginDialogVisible = false"/>
        </template>
    </default-dialog>

    <div class="logout">

        <confirm-dialog :visible="logoutDialogVisible"
            headerText="Realmente deseja sair?"
            confirmText="Confirmar"
            cancelText="Cancelar"
            @confirm="logout()"
            @cancel="closeLogoutDialog()">
        </confirm-dialog>

    </div>

    <default-dialog :visible="searchCompaniesDialogVisible"
        headerText="Busque por ativos"
        :closeDialog="() => searchCompaniesDialogVisible = false">
        <template #body>
            <company-search/>
        </template>
    </default-dialog>

    <div class="header">

        <div class="left-header">

            <div @click="goToHome()" @click.middle="goToHomeWithMiddleClick()" :class="{ 'is-active': activeMenu('1') }" class="header-logo header-item">
                <img src="@/assets/logo.svg" :width="logoWidth" :height="logoHeight" class="img logo">
            </div>

            <div v-if="this.user != null && this.user.id !== null" @click="goToDashboard()" @click.middle="goToDashboard()" :class="{ 'is-active': activeMenu('2') }" class="header-item">
                <el-tooltip class="box-item" effect="dark" placement="left">
                    <el-icon><DataAnalysis/></el-icon>
                </el-tooltip>
                <span v-if="!isPhoneScreenWidth" style="margin-left: 5px;"> Carteira </span>
            </div>

        </div>

        <div class="right-header">

            <div @click="searchCompaniesDialogVisible = true" class="header-search header-item">
                <el-icon><Search/></el-icon>
            </div>

            <div v-if="this.user != null && this.user.id !== null" class="sensitive-data header-item">
                <el-icon v-if="getSensitiveDataFlag() === 'true'" @click="updateSensitiveDataFlag()"><View/></el-icon>
                <img class="closed-eyes-icon img" v-if="getSensitiveDataFlag() === 'false'" src="@/assets/closed-eyes.svg" @click="updateSensitiveDataFlag()">
            </div>

            <div v-if="this.user != null && this.user.id !== null" class="header-user">

                <span class="user-menu-item">

                    <div class="user-avatar" @click="isUserSectionVisible = !isUserSectionVisible">

                        <el-tooltip class="box-item" effect="dark" placement="left">
                            <template #content>
                                <div class="tooltip">
                                    <span> Olá, {{ getUser.name }} </span>
                                </div>
                            </template>
                            <el-avatar :size="logoWidth" :src="getUserPhoto" @click="goToUpdateUser()" class="avatar"/>
                        </el-tooltip>
                        <el-icon v-if="!isUserSectionVisible" class="arrow"><ArrowRight/></el-icon>
                        <el-icon v-if="isUserSectionVisible" class="arrow"><ArrowDown/></el-icon>

                    </div>

                </span>
                <user-section v-if="isUserSectionVisible" :logout="showLogoutDialog" :goToUpdateUser="goToUpdateUser"/>    

            </div>

            <div v-if="showLoginButton()" class="header-user">
                <el-button type="success" @click="openLoginDialog()"> ENTRAR </el-button>
            </div>

        </div>

    </div>

</template>

<script>

    import { isUserLogged } from '@/util/UserUtils';
    import User from '@/models/User';
    import UserSection from './items/UserSection';
    import LoginSection from '@/section/login/LoginSection.vue';
    import { HOME_PATH, DASHBOARD_PATH, USER_PROFILE_PATH , COMPANY_DETAILS_PATH_WITHOUT_PARAM} from '@/constants/RoutesConstants';
    import { BLANK_PAGE_ARG, DEFAULT_AVATAR_IMAGE_URL } from '@/constants/AppConstants';
    import { getUserFromCache, removeUserCache, removeUserTokenCache, removeUserRefreshTokenCache, removeDollarValueCache } from '@/util/UserUtils';
    import { updateSensitiveDataFlag, getSensitiveDataFlag } from  '@/util/LocalStorageUtils.js';
    import { ArrowDown, ArrowRight } from '@element-plus/icons-vue';
    import { getCompaniesByNameLike } from '@/http/bff/company-bff-service';
    import Dialog from "@/component/common/Dialog";
    import ConfirmDialog from "@/component/common/ConfirmDialog";
    import CompanySearch from "@/component/common/CompanySearch";

    export default {
        name: 'header-view',
        components: { UserSection, LoginSection, ArrowDown, ArrowRight, 'default-dialog': Dialog, ConfirmDialog, CompanySearch },
        data() {
            return {
                user: new User(),
                loginDialogVisible: false,
                logoutDialogVisible: false,
                isUserSectionVisible: false,
                searchCompaniesDialogVisible: false,
                companyNameFilter: "",
                companyNameSearch: null,
                companies: []
            }
        },
        created() {
            this.updateUser();
        },
        methods: {
            closeUserSection() {
                this.isUserSectionVisible = false;
            },
            updateSensitiveDataFlag() {
                updateSensitiveDataFlag();
                this.$router.go();
            },
            getSensitiveDataFlag() {
                return getSensitiveDataFlag();
            },
            goToUpdateUser() {
                this.$router.push({ path: USER_PROFILE_PATH });
                this.closeUserSection();
            },
            showLogoutDialog() {
                this.logoutDialogVisible = true;
                this.closeUserSection();
            },
            updateUser() {

                this.emitter.on('setUserInfo', (user) => {
                    this.user = user;
                });

                const user = getUserFromCache();

                if(user !== null || user !== undefined) {
                    this.user = user;
                    this.emitter.emit('setUserStatus', true);
                    this.emitter.emit('setUser', this.user);
                    this.emitter.emit('setUserInfo', this.user);
                }
                
            },
            closeLoginDialog() {
                this.loginDialogVisible = false;
            },
            openLoginDialog() {
                this.loginDialogVisible = true;
            },
            goToHome() {
                if(isUserLogged()) {
                    this.$router.push({ path: HOME_PATH });
                    this.emitter.emit('setUserStatus', true);
                    this.emitter.emit('setUser', this.user);
                }
            },
            goToDashboard() {
                this.$router.push(DASHBOARD_PATH);
            },
            goToHomeWithMiddleClick() {
                window.open(HOME_PATH, BLANK_PAGE_ARG).focus();
            },
            goToDashboardWithMiddleClick() {
                this.$router.push(DASHBOARD_PATH);
            },
            logout() {
                this.user = new User();
                removeUserCache();
                removeUserTokenCache();
                removeUserRefreshTokenCache();
                removeDollarValueCache();
                this.emitter.emit('setUserStatus', false);
                this.emitter.emit('setUser', this.user);
                this.logoutDialogVisible = false;
                this.$router.push({ path: HOME_PATH });
            },
            closeLogoutDialog() {
                this.logoutDialogVisible = false;
            },
            showLoginButton() {
                return (this.user === null || this.user.id === null ) && this.$route.path === HOME_PATH;
            },
            activeMenu(index) {

                if(index === "1" && this.$router.currentRoute.value.path === HOME_PATH) {
                    return true;
                }

                if(index === "2" && this.$router.currentRoute.value.path !== HOME_PATH && this.$router.currentRoute.value.path !== USER_PROFILE_PATH) {
                    return true;
                }

                return false;

            },
            getCompaniesWithText() {
                if(this.companyNameFilter !== null && this.companyNameFilter !==  undefined && this.companyNameFilter.length < 2) {
                    this.companies = [];
                } else if(this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6) {
                    getCompaniesByNameLike(this.companyNameFilter).then(response => {
                        this.companies = response.data.companies;
                    });
                }
            },
            goToCompanyDetails() {
                if(this.companyNameSearch !== null) {
                    const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + this.companyNameSearch.toLowerCase();
                    window.open(formattedUrl, BLANK_PAGE_ARG).focus();
                    this.companyNameSearch = null;
                }
            }
        },
        computed: {
            getUser() {
                return this.user;
            },
            getUserPhoto() {

                if(this.user.photoUrl !== undefined && this.user.photoUrl !== null && this.user.photoUrl !== '') {
                    return this.user.photoUrl;
                }

                return DEFAULT_AVATAR_IMAGE_URL;

            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            logoWidth() {
                return this.isPhoneScreenWidth ? "35" : "55";
            },
            logoHeight() {
                return this.isPhoneScreenWidth ? "35" : "55";
            },
            showCompaniesOptions() {
                return this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6;
            }
        }
    }

</script>
  
<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .header {
            display: flex;                  /* establish flex container */
            flex-direction: row;            /* default value; can be omitted */
            flex-wrap: nowrap;              /* default value; can be omitted */
            justify-content: flex;
            width: inherit;
            text-align: center;
            background-color: $default-layout-color;
            font-weight: bold;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            position: fixed;
            width: 100%;
            height: 70px;
            z-index: 3000;
        }

        .logo {
            margin-left: 10px;
            padding-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .left-header {
            width: 100%;
        }

        .right-header {
            width: 110%;
        }

        .header-user {
            width: 30%;
        }

        .header-logo {
            margin-right: 40px;
        }

        .header-user {
            margin-left: 40px;
            margin-right: 10px;
        }

        .header-search {
            margin-right: 50px;
        }

        .el-icon {
            width: 18px;
            height: 18px;
        }

        .el-icon svg {
            width: 18px;
            height: 18px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .header {
            display: flex;                  /* establish flex container */
            flex-direction: row;            /* default value; can be omitted */
            flex-wrap: nowrap;              /* default value; can be omitted */
            justify-content: space-between;
            align-items: center;
            width: inherit;
            text-align: center;
            background-color: $default-layout-color;
            font-weight: bold;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            position: fixed;
            width: 100%;
            height: 70px;
            z-index: 3000;
        }

        .logo {
            margin-left: 25px;
            padding-right: 25px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .left-header {
            width: 30%;
        }

        .right-header {
            width: 100%;
        }

        .header-user {
            width: 15%;
        }

        .header-logo {
            margin-right: 100px;
        }

        .header-user {
            margin-left: 100px;
        }

        .header-search {
            margin-right: 100px;
        }

    }

    img {
        cursor: pointer;
        margin-top: 14px;
    }

    .header-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: $default-header-text-color;
        height: 100%;
        text-align: center;
    }

    .header-item span {
        cursor: pointer;
        color: $default-header-text-color;
    }

    .header-item:hover {
        color: white;
        border-bottom: 1px solid $default-primary-title-text-color;
    }

    .header-item:hover span {
        cursor: pointer;
        color: white;
    }

    .is-active {
        border-bottom: 1px solid $default-primary-title-text-color;
    }
    
    .tooltip {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .avatar:hover {
        cursor: pointer;
        border: 1px solid gray;
        margin-bottom: 5px;
    }

    .logout {
        text-align: center;
    }

    .dialog-header {
        color: black !important;
        line-height: var(--el-dialog-font-line-height);
        font-size: var(--el-dialog-title-font-size);
        color: var(--el-text-color-primary);
    }

    .search-menu-item {
        cursor: default;
    }

    .left-header {
        display: flex;                  /* establish flex container */
        flex-direction: row;            /* default value; can be omitted */
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        background-color: inherit;
        font-weight: bold;
        color: white;
        margin: auto;
        height: 70px;
        z-index: 3000;
    }

    .right-header {
        display: flex;                  /* establish flex container */
        flex-direction: row;            /* default value; can be omitted */
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        background-color: inherit;
        font-weight: bold;
        color: white;
        margin: auto;
        height: 70px;
        z-index: 3000;
    }

    .sensitive-data {
        cursor: pointer;
    }

    .arrow {
        color: $default-primary-title-text-color;
        margin-left: 10px;
        cursor: pointer;
    }

    .arrow:hover {
        color: white;
    }

    .user-avatar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .header-logo {
        margin-left: 10px;
    }

    .company-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .closed-eyes-icon {
        height: 1em;
        width: 1em;
        color: $default-primary-title-text-color !important;
        margin-top: 0px;
    }

</style>
