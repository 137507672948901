<template>

  <div class="planning-view">

    <h1> Planejamento </h1>

    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }" @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Ordens pendentes
          </span>
        </template>

        <section-row :isFirstSection="true">
          <order-planning-section v-if="allListsLoaded" :walletSummary="summary.walletSummary" :userCompaniesConfig="summary.userCompaniesConfig"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }"  @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Agendamentos
          </span>
        </template>

        <section-row :isFirstSection="true">
          <pending-orders-recurrence-section :dollarValue="summary.userCompaniesConfig.dollarValue"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane name="3">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverThree, 'is-active': activeName === '3' }"  @mouseover="isMouseOverThree = true" @mouseout="isMouseOverThree = false">
            Limites
          </span>
        </template>

        <section-row :isFirstSection="true">
          <user-planning-section :tableLoading="tableLoading" :userCompaniesConfig="summary.userCompaniesConfig"/>
        </section-row>

      </el-tab-pane>

    </el-tabs>

    <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
      <button @click="goBack()" class="back-button"> Voltar </button>
    </el-tooltip>

  </div>

</template>

<script>

  import SectionRow from '@/component/common/SectionRow';
  import UserPlanningSection from '../../section/planning/tab/user-planning/UserPlanningSection.vue';
  import PendingOrdersRecurrenceSection from '../../section/planning/tab/recurrence-planning/PendingOrdersRecurrenceSection.vue';
  import OrderPlanningSection from '../../section/planning/tab/order-planning/OrderPlanningSection';
  import { getSummary } from '../../http/bff/planning-bff-service';
  
  export default {
    name: 'planning-view',
    components: { SectionRow, UserPlanningSection, PendingOrdersRecurrenceSection, OrderPlanningSection },
    data() {
      return {
        summary: {
          userCompaniesConfig: {
            companyTypeConfig: [],
            total: 0
          },
          walletSummary: {
            walletType: [],
            walletTypesPendingTotal: 0,
            walletTypesTotal: 0,
            walletTotal: 0
          }
        },
        activeName: '1',
        isMouseOverOne: false,
        isMouseOverTwo: false,
        isMouseOverThree: false,
        allListsLoaded: false,
        lastActiveName: '1'
      }
    },
    mounted() {
      document.title = "Planejamento - Personal Invest";
    },
    created() {
      this.getSummary();
      this.emitter.on('refreshSummary', () => {
        this.getSummary();
      });
  },
    methods: {
      handleChange() {
        this.lastActiveName = this.activeName;
        window.scroll(0, 0);
      },
      goBack() {

        const lastActiveName = this.activeName;

        this.activeName = this.lastActiveName;
        this.lastActiveName = lastActiveName;
        window.scroll(0, 0);

      },
      getSummary() {
        this.tableLoading = true;
        getSummary().then(response => {

          this.summary = response.data;
          this.tableLoading = false;
          this.allListsLoaded = true;

        }).catch(() => this.tableLoading = false);
      }
    }
  }
  </script>
  
  <style scoped>
  
    .back-button {
      margin: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      float: right;
      cursor: pointer;
    }

  </style>