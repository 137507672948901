export const addCache = (cacheKey, dollarValue) => {
    localStorage.setItem(cacheKey, dollarValue);
}

export const getCache = (cacheKey) => {
    return localStorage.getItem(cacheKey);
}

export const removeCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
}

export const cacheExists = (cacheKey) => {
    const cacheValue = getCache(cacheKey);
    return cacheValue !== undefined && cacheValue !== null;
}

export const sensitiveDataFlagExists = () => {
    const showSensitiveData = getSensitiveDataFlag();
    return showSensitiveData !== null && showSensitiveData !== undefined;
};

export const getSensitiveDataFlag = () => {
    
    let showSensitiveData = localStorage.getItem("sensitive-data-flag");
    if(showSensitiveData !== null && showSensitiveData !== undefined) {
        return showSensitiveData;
    } else {
        createSensitiveDataFlag();
        return localStorage.getItem("sensitive-data-flag");
    }

};

export const createSensitiveDataFlag = () => {
    localStorage.setItem("sensitive-data-flag", false);
};

export const updateSensitiveDataFlag = () => {
    
    let showSensitiveData = getSensitiveDataFlag();
    if(showSensitiveData !== null && showSensitiveData !== undefined) {
        const flag = showSensitiveData === "true" ? "false" : "true";
        localStorage.setItem("sensitive-data-flag", flag.toString());
    } else {
        createSensitiveDataFlag();
    }

};

export const removeSensitiveDataFlag = () => {
    localStorage.removeItem("sensitive-data-flag");
};
