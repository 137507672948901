export default {
    LOWER_THAN_OR_EQUAL: {
        value: "LOWER_THAN_OR_EQUAL",
        description: "Menor que ou igual a"
    },
    LOWER_THAN: {
        value: "LOWER_THAN",
        description: "Menor que"
    },
    HIGHER_THAN: {
        value: "HIGHER_THAN",
        description: "Maior que"
    },
    HIGHER_THAN_OR_EQUAL: {
        value: "HIGHER_THAN_OR_EQUAL",
        description: "Maior que ou igual a"
    }
}
