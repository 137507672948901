<template>

    <div class="create-recurrence-dialog">

        <div class="recurrence-info-type">

            <div class="recurrence-info-company-row">

                <div class="dates recurrence-info-recurrence-container">
                    <span> Data de execução </span>
                    <el-date-picker v-model="recurrence.scheduleDate" type="date" format="DD/MM/YYYY" placeholder="Data de execução" size="small"/>
                </div>

            </div>

            <div class="recurrence-info-company-row">

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Categoria </span>
                    <el-select v-if="companyTypeList.length > 0" v-model="recurrence.company.type" @change="resetRecurrenceAndGetCompanies()" class="m-2" placeholder="Selecione" size="small">
                        <el-option v-for="company in companyTypeList" :key="company" :label="getCompanyText(company)" :value="company"/>
                    </el-select>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Ativo </span>
                    <el-select v-if="companyList.length > 0" v-model="recurrence.company.id" @change="setCurrentPrice()" filterable class="m-2" placeholder="Selecione" size="small">
                        <el-option v-for="company in companyList" :key="company.id" :label="company.name" :value="company.id">
                            <img v-if="company.imageUrl !== undefined" style="margin-right: 10px; cursor: pointer;" :src="company.imageUrl" width="30" height="20">
                            <span> {{ company.name }} </span>
                        </el-option>
                    </el-select>
                    <span v-if="companyList.length <= 0">-</span>
                </div>

            </div>

            <div class="recurrence-info-company-row">

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Quantidade </span>
                    <div v-if="recurrence.company.id !== null && recurrence.company.type !== null && recurrence.company.type !== undefined">
                        <el-input-number v-if="recurrence.company.type !== CompanyType.STOCK.value" v-model="recurrence.amount" :min="1"/>
                        <el-input-number v-if="recurrence.company.type === CompanyType.STOCK.value" v-model="recurrence.amount" :min="DEFAULT_STOCK_INITIAL_VALUE" :precision="DEFAULT_STOCK_DECIMAL" :step="DEFAULT_STOCK_INITIAL_VALUE" @change="orderChanged()"/>
                    </div>
                    <div v-if="recurrence.company.id === null || recurrence.company.type === null || recurrence.company.type === undefined">
                    -
                    </div>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Preço </span>
                    <currency-input :disabled='recurrence.atMarketValue' :class="isDisabledClass" v-if="recurrence.company.id !== null && recurrence.price !== null" v-model="recurrence.price" :options="getCompanyCurrency(recurrence.company.type)" @change="orderChanged()" class="price-input"/>
                    <div v-if="recurrence.company.id === null || recurrence.company.id === undefined || recurrence.price === null">
                    -
                    </div>
                </div>

                <div class="recurrence-info-recurrence-container">
                    <span class="title"> Ao valor de mercado? </span>
                    <el-switch v-if="recurrence.company.id !== null && recurrence.company.type !== null && recurrence.company.type !== undefined" v-model="recurrence.atMarketValue"/>
                    <div v-if="recurrence.company.id === null || recurrence.company.id === undefined || recurrence.price === null">
                    -
                    </div>
                </div>

            </div>

        </div>

        <div class="recurrence-total-info-type">

            <span class="recurrence-info-type-title"> Total: </span>
            <div class="recurrence-info-row">

                <div class="recurrence-info-company-container">
                    <el-tag :key="recurrence.company.id" class="mx-1 table-tag" :disable-transitions="false">
                        <span class="el-tag__content"> {{ total }} </span>
                    </el-tag>
                </div>

            </div>

        </div>

        <div class="footer">
            <el-button type="success" class="dialog-confirm" @click="createRecurrence()" :disabled="!isRecurrenceRequestValid">
                <span v-if="!isPhoneScreenWidth"> Confirmar </span>
                <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Criar" placement="top">
                    <el-icon><Check/></el-icon>
                </el-tooltip>
            </el-button>
            <el-button type="danger" class="dialog-close" @click="closeDialog()">
                <span v-if="!isPhoneScreenWidth"> Cancelar </span>
                <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Cancelar" placement="top">
                    <el-icon><Close/></el-icon>
                </el-tooltip>
            </el-button>
        </div>

    </div>

</template>

<script>

    import CompanyType from '@/constants/CompanyType';
    import CompanyStatus from '@/constants/CompanyStatus';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { ElMessage } from 'element-plus';
    import { getCompanies, getCompanyCurrentPrice, createRecurrence } from '@/http/bff/planning-bff-service';
    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
    import { getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'create-recurrence-dialog',
        components: { CurrencyInput },
        data() {
            return {
                recurrence: {
                    company: {
                        id: null,
                        type: null
                    },
                    amount: 1,
                    price: null,
                    total: 0,
                    atMarketValue: true,
                    scheduleDate: new Date()
                },
                companies: [],
                loading: false,
                loadingSvg: DEFAULT_LOADING_SVG,
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                CompanyType
            }
        },
        props: {
            closeCreateRecurrenceDialog: Function,
            dollarValue: Number
        },
        methods: {
            getCompanyText(type) {
                return CompanyType[type].description;
            },
            resetRecurrenceAndGetCompanies() {
                this.companies = [];
                this.getCompanies();
            },
            getCompanies() {

                if(this.recurrence.company.type !== null) {
                    const filter = {
                        selectedTypes: [ this.recurrence.company.type ],
                        selectedStatus: [ CompanyStatus.ACTIVE.value ],
                        sorts: [
                            "type###asc",
                            "name###asc"
                        ]
                    };
                    this.loading = true;
                    getCompanies(filter).then(response => {
                        this.companies = response.data.companies;
                        this.loading = false;
                    }).catch(() => this.loading = false);
                }

            },
            setCurrentPrice() {

                this.recurrence.price = null;
                const company = this.companies.find(company => company.id === this.recurrence.company.id);
                this.loading = true;
                getCompanyCurrentPrice(company.name, this.recurrence.company.id).then(response => {
                    this.recurrence.price = response.data.currentPrice;
                    this.loading = false;
                }).catch(() => {
                    ElMessage({
                        message: "Erro ao buscar valor atual do ativo",
                        type: 'error'
                    });
                    this.loading = false;
                });

            },
            orderChanged() {
                this.recurrence.total = this.recurrence.amount * this.recurrence.price;
            },
            getCompanyCurrency(type) {

                if(type === CompanyType.STOCK.value) {
                    return { currency: "USD" };
                }

                return { currency: "BRL" };

            },
            createRecurrence() {

                if(this.isRecurrenceRequestValid) {

                    createRecurrence(this.recurrence).then(() => {
                        ElMessage({
                            message: "Agendamento criado com sucesso!",
                            type: 'success'
                        });
                        this.closeDialog();
                    }).catch(() => {
                        ElMessage({
                            message: "Agendamento não pode ser criado, tente novamente mais tarde!",
                            type: 'error'
                        });
                    });

                }

            },
            closeDialog() {
                this.$emit('getPendingOrdersRecurrenceConfig', {});
                this.$emit('closeCreateRecurrenceDialog', {});
            }
        },
        computed: {
            companyTypeList() {
                return Object.keys(CompanyType);
            },
            companyList() {
                return this.companies;
            },
            isDisabledClass() {
                return this.recurrence.atMarketValue === true ? "price-disabled" : "";
            },
            total() {

                if(this.recurrence.company.id !== null && this.recurrence.company.type !== null) {

                    const locale = CompanyType[this.recurrence.company.type].locale;
                    let total = this.recurrence.total;
                    if(this.dollarValue !== null && this.recurrence.company.type === CompanyType.STOCK.value) {
                        total = total * this.dollarValue;
                    }

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            isRecurrenceRequestValid() {
                
                if(this.recurrence.company === null || this.recurrence.company === undefined || this.recurrence.company.id === null || this.recurrence.company.id === "") {
                    return false;
                }

                if(this.recurrence.amount === null || this.recurrence.amount === undefined || this.recurrence.amount === "" || this.recurrence.amount <= 0) {
                    return false;
                }

                if(this.recurrence.price === null || this.recurrence.price === undefined || this.recurrence.price === "" || this.recurrence.price <= 0) {
                    return false;
                }

                if(this.recurrence.atMarketValue === null || this.recurrence.atMarketValue === undefined) {
                    return false;
                }

                if(this.recurrence.scheduleDate === null || this.recurrence.scheduleDate === undefined) {
                    return false;
                }

                return true;

            }
        }
    }

</script>

<style lang="scss" scoped>

    .create-recurrence-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .recurrence-info-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .recurrence-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .recurrence-info-title {
        font-weight: bold;
        font-size: 14px;
        margin-top: 5px;
        color: $default-primary-title-text-color !important;
    }

    .recurrence-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .recurrence-total-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .recurrence-info-company-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .recurrence-info-recurrence-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .price-disabled {
        opacity: 0.6;
    }

    .table-tag {
        width: 90%;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background-color: $dialog-background-color !important;
        margin-right: 50px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    @media screen and (max-width: 1024px) {

        .dates {
            width: 80%;
        }

        .recurrence-info-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .recurrence-info-company-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        
    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .dates {
            width: 80%;
        }

        .recurrence-info-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .recurrence-info-company-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

    }

</style>
