<template>

  <div class="reset-user-password">
    
    <h1> Esqueci minha senha </h1>
    
    <br>

    <div class="reset-el-input">
      <span> Senha atual </span>
      <el-input type="password" @change="initFieldEdit('currentPassword')" :class="invalidCurrentPasswordFieldValue()" size="small" minlength="6" maxlength="12" v-model="body.currentPassword" clearable show-password class="name-el-input"/>
    </div>

    <div class="reset-el-input">
      <span> Nova senha </span>
      <el-input type="password" @change="initFieldEdit('newPassword')" :class="invalidNewPasswordFieldValue()" size="small" minlength="6" maxlength="12" v-model="body.newPassword" clearable show-password class="name-el-input"/>
      <i> Possui ao menos uma letra em maiúsculo </i>
      <i> Possui ao menos um caracter especial </i>
      <i> Possui ao menos uma letra </i>
      <i> Possui ao menos um número </i>
      <i> Possui no minimo 6 caracteres </i>
      <i> Possui no máximo 12 caracteres </i>
    </div>

    <div class="reset-el-input">
      <span> Confirmar a nova senha </span>
      <el-input type="password" @change="initFieldEdit('newPasswordRepeated')" :class="invalidNewPasswordRepeatedFieldValue()" size="small" minlength="6" maxlength="12" v-model="body.newPasswordRepeated" clearable show-password class="name-el-input"/>
    </div>

    <div class="foot-buttons">
      <el-button type="success" :disabled="!validRequest()" @click="resetPasswordToken()"> Atualizar </el-button>
      <el-tooltip class="box-item" effect="dark" content="Voltar para o Dashboard" placement="top">
        <el-button type="primary" @click="goToHome()" class="back-button"> Voltar </el-button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import { resetPasswordToken } from '@/http/user/anonymous-user-service';
  import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { ElMessage } from 'element-plus'

  export default {
    name: 'reset-password-view',
    data() {
      return {
        loadingSvg: DEFAULT_LOADING_SVG,
        body: {
          currentPassword: null,
          newPassword: null,
          newPasswordRepeated: null,
          token: this.$route.params.token
        },
        fields: {
          currentPassword: false,
          newPassword: false,
          newPasswordRepeated: false
        }
      }
    },
    created() {
      
      if(this.$route.params.token === null || this.$route.params.token === undefined) {
        ElMessage({
          message: "Ocorreu um erro ao tentar recuperar o token de recuperação de senha, tente novamente mais tarde!",
          type: 'error'
        });
        this.$router.push({ path: HOME_PATH });
      }

    },
    methods: {
      initFieldEdit(field) {
        this.fields[field] = true;
      },
      invalidCurrentPasswordFieldValue() {

        if(!this.fields.currentPassword) {
          return "";
        }

        return this.body.currentPassword === null || 
          this.body.currentPassword === undefined || 
          this.body.currentPassword.length === "" || 
          this.body.currentPassword.length <= 6 || 
          this.body.currentPassword.length > 12 ||
          !/[A-Z]/.test(this.body.currentPassword) ||
          !/[1-9]/.test(this.body.currentPassword) ||
          !/[!@#$%&]/.test(this.body.currentPassword) ?
        "invalid-field" :
        "valid-field";

      },
      invalidNewPasswordFieldValue() {

        if(!this.fields.newPassword) {
          return "";
        }

        return this.body.newPassword === null || 
          this.body.newPassword === undefined || 
          this.body.newPassword.length === "" || 
          this.body.newPassword.length <= 6 || 
          this.body.newPassword.length > 12 ||
          !/[A-Z]/.test(this.body.newPassword) ||
          !/[1-9]/.test(this.body.newPassword) ||
          !/[!@#$%&]/.test(this.body.newPassword) ?
        "invalid-field" :
        "valid-field";

      },
      invalidNewPasswordRepeatedFieldValue() {

        if(!this.fields.newPasswordRepeated) {
          return "";
        }

        return this.body.newPasswordRepeated === null || 
          this.body.newPasswordRepeated === undefined || 
          this.body.newPasswordRepeated.length === "" || 
          this.body.newPasswordRepeated.length <= 6 || 
          this.body.newPasswordRepeated.length > 12 ||
          !/[A-Z]/.test(this.body.newPasswordRepeated) ||
          !/[1-9]/.test(this.body.newPasswordRepeated) ||
          !/[!@#$%&]/.test(this.body.newPasswordRepeated) ?
        "invalid-field" :
        "valid-field";

      },
      resetPasswordToken() {

        if(this.validRequest()) {

          const body = {
            currentPassword: btoa(this.body.currentPassword),
            newPassword: btoa(this.body.newPassword),
            newPasswordRepeated: btoa(this.body.newPasswordRepeated),
            token: this.$route.params.token
          };
          resetPasswordToken(body).then(() => {

            ElMessage({
              message: 'Senha atualizada com sucesso!',
              type: 'success'
            });
            this.$router.push({ path: HOME_PATH })

          }).catch(response => {
            ElMessage({
              message: response.data,
              type: 'error'
            });
          });

        }

      },
      validRequest() {

        if(this.body.currentPassword === null || 
            this.body.currentPassword === undefined || 
            this.body.currentPassword.length === "" || 
            this.body.currentPassword.length <= 6 || 
            this.body.currentPassword.length > 12 ||
            !/[A-Z]/.test(this.body.currentPassword) ||
            !/[!@#$%&]/.test(this.body.currentPassword)) {
          return false;
        }

        if(this.body.newPassword === null || 
            this.body.newPassword === undefined || 
            this.body.newPassword.length === "" || 
            this.body.newPassword.length <= 6 || 
            this.body.newPassword.length > 12 ||
            !/[A-Z]/.test(this.body.newPassword) ||
            !/[!@#$%&]/.test(this.body.newPassword)) {
          return false;
        }

        if(this.body.newPasswordRepeated === null || 
            this.body.newPasswordRepeated === undefined || 
            this.body.newPasswordRepeated.length === "" || 
            this.body.newPasswordRepeated.length <= 6 || 
            this.body.newPasswordRepeated.length > 12 ||
            !/[A-Z]/.test(this.body.newPasswordRepeated) ||
            !/[!@#$%&]/.test(this.body.newPasswordRepeated)) {
          return false;
        }

        if(this.body.newPassword !== this.body.newPasswordRepeated) {
          return false;
        }

        if(this.body.token === null || this.body.token === undefined || this.body.token === "" || this.body.token.length <= 0) {
          return false;
        }

        return true;

      },
      goToHome() {
        this.$router.push({ path: HOME_PATH });
      }
    }
  }

</script>

<style lang="scss" scoped>

  .reset-user-password {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .reset-el-input {
    float: left;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    flex-direction: column;
    font-weight: bold;
    width: 20%;
  }

  .foot-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 20%;
    margin-top: 20px;
  }

  .name-el-input {
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
  }

  .back-button {
    float: right;
  }

  i {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }

</style>
@/http/user/anonymous-user-service