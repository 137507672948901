<template>

    <el-dialog v-model="isDialogVisible" :width="dialogWidth" :show-close="!isPhoneScreenWidth" :before-close="cancel">

        <template #header>
            <span class="header-text"> {{ formattedHeaderText }} </span>
        </template>

        <div v-if="isDialogVisible" class="dialog-body">
            <slot name="body"></slot>
        </div>

        <template #footer>
            <el-button type="success" class="dialog-confirm" @click="confirm()" :disabled="isConfirmationDisabled">
                <span v-if="!isPhoneScreenWidth"> {{ formattedConfirmText }} </span>
                <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" :content="formattedConfirmText" placement="top">
                    <el-icon><Check/></el-icon>
                </el-tooltip>
            </el-button>
            <el-button type="danger" class="dialog-close" @click="cancel()">
                <span v-if="!isPhoneScreenWidth"> {{ formattedCancelText }} </span>
                <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" :content="formattedCancelText" placement="top">
                    <el-icon><Close/></el-icon>
                </el-tooltip>
            </el-button>
        </template>

    </el-dialog>

</template>

<script>

    export default {
        name: 'confirm-dialog',
        props: {
            visible: Boolean,
            headerText: String,
            confirmText: String,
            cancelText: String,
            isConfirmButtonDisabled: Boolean
        },
        methods: {
            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            confirm() {
                this.$emit('confirm', {});
            },
            cancel() {
                this.$emit('cancel', {});
            }
        },
        computed: {
            isDialogVisible() {
                return this.visible;
            },
            formattedHeaderText() {
                return this.capitalize(this.headerText);
            },
            formattedConfirmText() {
                return this.capitalize(this.confirmText);
            },
            formattedCancelText() {
                return this.capitalize(this.cancelText);
            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            dialogWidth() {
                return this.isPhoneScreenWidth ? "50%" : "30%";
            },
            isConfirmationDisabled() {
                return this.isConfirmButtonDisabled;
            }
        }
    }

</script>

<style lang="scss">

    .header-text {
        color: $dialog-header-text-color;
    }

    .dialog-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    // Element
    .el-dialog {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .el-dialog__header {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $dialog-background-color !important;
        padding: 0px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        margin-right: 0px !important;
    }

    .el-dialog__body {
        width: 100%;
        background-color: $dialog-background-color !important;
    }

    .el-dialog__footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background-color: $dialog-background-color !important;
    }

    .el-dialog__headerbtn {
        margin-right: 10px !important;
    }

    @media screen and (max-width: 1024px) {
        
        .el-dialog__body {
            padding: 0px !important;
            padding-top: 20px !important;
            padding-bottom: 10px !important;
        }

        .dialog-confirm {
            width: 30%;
        }

        .dialog-close {
            width: 30%;
        }

        .el-dialog__headerbtn {
            margin-right: 10px !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .el-dialog__body {
            padding: 0px !important;
        }

        .dialog-confirm {
            width: 15%;
        }

        .dialog-close {
            width: 15%;
        }

    }

</style>