<template>

  <div class="company-view">

    <h1> Ativos </h1>
    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"  @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Meus ativos
          </span>
        </template>
        <section-row :isFirstSection="true">
          <company-table-section/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-two': isMouseOverTwo, 'is-active': activeName === '2' }"  @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Alertas
          </span>
        </template>
        <section-row :isFirstSection="true">
          <company-alert-section/>
        </section-row>

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()" > Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import SectionRow from '@/component/common/SectionRow';
  import CompanyAlertSection from '@/section/company/tab/company-alert/CompanyAlertSection';
  import CompanyTableSection from '@/section/company/tab/company-table/CompanyTableSection';

  export default {
    name: 'company-view',
    components: { SectionRow, CompanyAlertSection, CompanyTableSection },
    data() {
      return {
        activeName: '1',
        lastActiveName: '1',
        isMouseOverOne: false,
        isMouseOverTwo: false
      }
    },
    mounted() {
      document.title = "Ativos - Personal Invest";
    },
    methods: {
      handleChange() {
        this.lastActiveName = this.activeName;
        window.scroll(0, 0);
      },
      goBack() {

        const lastActiveName = this.activeName;

        this.activeName = this.lastActiveName;
        this.lastActiveName = lastActiveName;
        window.scroll(0, 0);

      }
    }
  }

</script>

<style scoped>

  .company-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .back-button {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

</style>
