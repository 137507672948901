<template>

	<Dialog class="create-or-update-dialog" :visible="orderConfirmationAuxVisible" headerText="Criar ordem">
    <template #body>
      <create-pending-order-dialog @closePendingOrderDialog="closePendingOrderDialog()"/>
    </template>
  </Dialog>

  <confirm-dialog class="create-or-update-dialog" :visible="orderUpdateAuxVisible" 
    headerText="Editar ordem"
    confirmText="Confirmar"
    cancelText="Cancelar"
    :isConfirmButtonDisabled="!validUpdateRequest(orderExecutedAux)"
    @confirm="updatePlanningOrder()"
    @cancel="closeUpdateOrderDialog()">
    <template #body>
      <update-pending-order-dialog v-if="orderExecutedAux != null" :planningOrder="orderExecutedAux"/>
    </template>
  </confirm-dialog>

	<confirm-dialog :visible="orderExecutedAuxVisible"
    headerText="Confirmação"
    confirmText="Confirmar"
    cancelText="Cancelar"
    :isConfirmButtonDisabled="!validExecutedRequest(orderExecutedAux)"
    @confirm="updateExecutedOrder()"
    @cancel="closeExecuteOrderDialog()">
    <template #body>
      <execute-pending-order-dialog :pendingOrder="orderExecutedAux"/>
    </template>
  </confirm-dialog>

	<confirm-dialog :visible="orderNotExecutedAuxVisible"
    headerText="Confirmação"
    confirmText="Confirmar"
    cancelText="Cancelar"
    @confirm="deletePlanningOrder()"
    @cancel="closeCancelPendingOrderDialog()">
		<template #body>
      <span> Realmente deseja cancelar a ordem pendente? </span>
    </template>
  </confirm-dialog>

  <div class="order-planning-section">

    <div class="progress-summary">

      <div class="pending-info">

        <wallet-summary-section :walletSummary="walletSummary" class="wallet-summary-section"/>
        <div class="progress-div">
          <div class="progress-info" v-for="companyType in walletTypeList" :key="companyType">
            <el-tooltip class="box-item" effect="dark" :content="getPendingTooltipTypeContent(companyType.type)" placement="top">
                <label :class="companyType.typeClassName"> {{ CompanyType[companyType.type].description }} </label>
            </el-tooltip>
            <div class="progress">
                <el-progress style="margin-top: 5px;" 
                :stroke-width="16" 
                :percentage="getProgressPendingPercentage(companyType)" 
                :color="getProgressPendingTypeColor(companyType.type)"></el-progress>
            </div>
          </div>
        </div>

      </div>

    </div>

    <h3>
      ORDENS
      <el-tooltip class="box-item" effect="dark" content="Ordens planejadas" placement="top">
          <el-icon class="info-icon"><InfoFilled/></el-icon>
      </el-tooltip>
    </h3>

    <div class="filter-collapse">

      <el-collapse>

        <el-collapse-item name="1" class="filter-collapse-item">

          <template #title>
              <span class="filter-class collapse-border"> Filtros </span>
          </template>

          <div class="tags">
            <span> Operação: </span>
            <div>
              <el-select v-if="filterTypeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2" placeholder="Selecione" size="small">
                <el-option v-for="companyType in filterTypeList" :key="companyType" :label="getTypeText(companyType)" :value="companyType"/>
              </el-select>
              <el-tag v-for="selectedType in filter.selectedTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(selectedType)">
                {{ getTypeText(selectedType) }}
              </el-tag>
            </div>
          </div>

          <div class="tags">
            <span> Categoria: </span>
            <div>
              <el-select v-if="companyTypeFilterList.length > 0" v-model="selectedFilterCompanyType" @change="changeCompanyTypeFilter()" class="m-2" placeholder="Selecione" size="small">
                <el-option v-for="companyType in companyTypeFilterList" :key="companyType" :label="getCompanyTypeText(companyType)" :value="companyType"/>
              </el-select>
              <el-tag v-for="selectedType in filter.selectedCompanyTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeCompanyTypeFromList(selectedType)">
                  {{ getCompanyTypeText(selectedType) }}
              </el-tag>
            </div>
          </div>

        </el-collapse-item>

      </el-collapse>

    </div>

    <div class="filter-buttons">
      <button @click="clearFilters()" class="clear-button"> Limpar </button>
      <button @click="getPlanningOrders()" class="search-button"> Buscar </button>
    </div>

    <div class="table-buttons">

      <el-tooltip class="box-item" effect="dark" content="Buscar pelo nome do ativo" placement="top">
        <el-input v-model="filter.searchText" suffix-icon="Search" class="w-10 m-2 search-input" placeholder="ex.: AMZN" @keyup="getOrderWithText()"/>
      </el-tooltip>

      <el-button type="success" @click="showCreatePlanningOrderDialog()"> Adicionar </el-button>
    
    </div>

    <table class="table-responsive" v-loading="tableLoading" element-loading-text="Carregando..." :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

        <thead>
          <tr>
              <th> Categoria </th>
              <th> Ativo </th>
              <th> Quantidade </th>
              <th class="table-price-header">
                <span> Preço </span>
                <el-tooltip v-if="orderList.length > 0" class="box-item" effect="dark" content="Atualizar preços para o valor atual de mercado" placement="top">
                  <el-button type="primary" class="refresh" :disabled="tableLoading === true" @click="setOrdersToCurrentPrice()">
                    <el-icon><Refresh/></el-icon>
                  </el-button>
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Total da ordem" placement="top">
                  Total ordem
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Total do ativo" placement="top">
                  Total atual
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Total final com o planejamento" placement="top">
                  Total Final
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Quantidade atual" placement="top">
                  Qtd. Atual
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Quantidade com o planejamento" placement="top">
                  Qtd. Final
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Preço médio atual" placement="top">
                  P.M Atual
                </el-tooltip>
              </th>
              <th>
                <el-tooltip class="box-item" effect="dark" content="Preço médio com o planejamento" placement="top">
                  P.M Final
                </el-tooltip>
              </th>
              <th>
                <span> Operação </span>
              </th>
              <th> Ações </th>
          </tr>
        </thead>

        <tbody v-if="orderList.length > 0" class="planning-orders-total">
          <tr v-for="order in orderList" :key="order.id">
              <td>
                <span :class="order.companyTypeClassName"> {{ getType(order) }} </span>
              </td>
              <td :class="order.companyTypeClassName" style="cursor: pointer;" @click="goToCompanyDetails(order.company.name)">
                <img v-if="order.company.imageUrl != null" style="margin-right: 10px; cursor: pointer;" :src="order.company.imageUrl" width="30" height="20">
                <span style="cursor: pointer;" :class="order.tagCompanyTypeClassName"> {{ order.company.name }} </span>
              </td>
              <td> 
                <span :class="getCompanyAmountClass(order.company.type)"> {{ getFinalAmount(order) }} </span>
              </td>
              <td>
                <span> {{ getFormattedOrderPrice(order) }} </span>
              </td>

              <td>
                <span> {{ getOrderTotalValue(order) }} </span>
              </td>

              <td>
                <span> {{ getCompanyTotalValue(order) }} </span>
              </td>
              <td>
                <span> {{ getFinalValue(order) }} </span>
              </td>

              <td>
                <span> {{ order.company.amount }} </span>
              </td>

              <td>
                <span> {{ getFinalUserAmount(order) }} </span>
              </td>
              
              <td>
                <span> {{ getUserCurrentPriceValue(order) }} </span>
              </td>

              <td>
                <span> {{ getFinalUserPriceValue(order) }} </span>
              </td>

              <td>
                <span> {{ getTypeText(order.type) }} </span>
              </td>

              <td>
                <div class="actions">
                  <el-tooltip class="box-item" effect="dark" content="Executar ordem planejada" placement="top">
                    <el-button type="success" icon="Check" circle @click="setOrderExecutedAux(order)"/>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="Editar ordem planejada" placement="top">
                    <el-button type="default" icon="Edit" circle @click="setOrderUpdateAux(order)"/>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="Cancelar ordem planejada" placement="top">
                    <el-button type="danger" icon="Delete" circle @click="setOrderNotExecutedIdAux(order.id)"/>
                  </el-tooltip>
                </div>
              </td>

          </tr>

          <tr>
              <td class="total-row"> GERAL </td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"> {{ getPtFormattedPrice(pendingTotal) }} </td>
              <td class="total-row"> {{ getPtFormattedPrice(companyTotal) }} </td>
              <td class="total-row"> {{ getPtFormattedPrice(total) }} </td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"></td>
              <td class="total-row"></td>
          </tr>

        </tbody>

    </table>

    <div v-if="orders.length === 0">
      <EmptyResult></EmptyResult>
    </div>

    <div v-if="orders.length > 0">
      <div class="pagination">
        <el-input-number v-model="pagination.limit" :min="1" :max="pagination.total" @change="getPlanningOrders()"/>
        <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" @current-page="page" @current-change="setPage"/>
      </div>
      <div class="number-register">
        {{ getNumberOfRegisterText() }}
      </div>
    </div>

  </div>

</template>

<script>

import WalletSummarySection from './items/WalletSummarySection';
import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '../../../../constants/AppConstants';
import { ElMessage } from 'element-plus';
import { getTotalValue, getFormattedValue } from '../../../../util/MoneyUtils';
import { updatePlanningOrder, deletePlanningOrder, getPlanningOrders } from '../../../../http/bff/planning-bff-service';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
import OrderStatus from '../../../../constants/OrderStatus';
import OrderType from '../../../../constants/OrderType';
import CompanyType from '../../../../constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';
import ConfirmDialog from "@/component/common/ConfirmDialog";
import Dialog from "@/component/common/Dialog";
import CreatePendingOrderDialog from "./dialog/CreatePendingOrderDialog";
import UpdatePendingOrderDialog from "./dialog/UpdatePendingOrderDialog";
import ExecutePendingOrderDialog from "./dialog/ExecutePendingOrderDialog";

export default {
  name: 'order-planning-section',
  components: { ConfirmDialog, Dialog, CreatePendingOrderDialog, UpdatePendingOrderDialog, ExecutePendingOrderDialog, EmptyResult, WalletSummarySection },
  props: {
    walletSummary: Object,
    userCompaniesConfig: Object
  },
  data() {
    return {
      orders: [],
      pagination: {
        offset: 0,
        limit: 10,
        total: 0,
        sorts: [
          "createdAt###desc",
          "name###asc",
          "type###asc"
        ]
      },
      OrderStatus,
      OrderType,
      page: 1,
      filter: {
        selectedTypes: [ ],
        selectedStatus: [ OrderStatus.PENDING.value ],
        selectedCompanyTypes: [],
        range: []
      },
      companies: [],
      selectedCompanyType: null,
      selectedCompany: null,
      selectedFilterCompanyType: null,
      orderConfirmationAuxVisible: false,
      orderUpdateAuxVisible: false,
      orderExecutedAuxVisible: false,
      orderExecutedAux: {},
      orderNotExecutedAuxVisible: false,
      orderNotExecutedIdAux: null,
      tableLoading: false,
      companiesTypes: CompanyType,
      DEFAULT_STOCK_INITIAL_VALUE,
      DEFAULT_STOCK_DECIMAL,
      ordersPendingTotal: 0,
      ordersCompanyTotal: 0,
      ordersTotal: 0,
      selectedType: null,
      selectedStatus: null,
      fileName: "",
      fileList: [],
      loadingSvg: DEFAULT_LOADING_SVG,
      CompanyType
    }
  },
  mounted() {
    document.title = "Planejamento - Personal Invest";
  },
  created() {
    this.getPlanningOrders();
  },
  methods: {
    setOrdersToCurrentPrice() {
      this.orders.forEach(order => {

        if(order.company !== null && order.company.currentPrice !== null) {
          order.price = order.company.currentPrice;
        }

      });
    },
    userCompanyConfigDividendTotalByType(type) {
      return this.userCompaniesConfig.companyTypeConfig.reduce((partialSum, config) => {

          if(type === config.type && config.includeDividend) {
              return partialSum + config.dividendTotal;
          }
          
          return partialSum;

      }, 0);
    },
    goToCompanyDetails(name) {
      const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + name.toLowerCase();
      window.open(formattedUrl, BLANK_PAGE_ARG).focus();
    },
    getPendingTooltipTypeContent(type) {

      const monthLimit = this.userCompaniesConfig.companyTypeConfig.find(config => config.type === type).monthLimit;
      const dividendTotal = this.userCompanyConfigDividendTotalByType(type);
      const total = dividendTotal + monthLimit;

      return "Porcentagem de ordens pendentes em " + CompanyType[type].description.toLowerCase() + " no limite de " + getFormattedValue(total, "pt_br");

    },
    getProgressPendingPercentage(companyType) {

      const config = this.userCompaniesConfig.companyTypeConfig.find(config => config.type === companyType.type);
      const monthLimit = config.monthLimit;
      const dividendTotal = this.userCompanyConfigDividendTotalByType(companyType.type);
      const typePendingTotal = companyType.type === CompanyType.STOCK.value ? companyType.walletTypePendingTotal * this.walletSummary.dollarValue : companyType.walletTypePendingTotal;
      const total = dividendTotal + monthLimit;

      return ((typePendingTotal * 100)/total).toFixed(2);

    },
    getProgressPendingTypeColor(type) {
      return CompanyType[type].color;
    },
    getOrderWithText() {
      if(this.filter.searchText !== null && this.filter.searchText !==  undefined && (this.filter.searchText.length >= 3 || this.filter.searchText.length === 0)) {
        this.getPlanningOrders();
      }
    },
    removeCompanyTypeFromList(type) {

      const typeIndex = this.filter.selectedCompanyTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedCompanyTypes.splice(typeIndex, 1);
        this.selectedFilterCompanyType = null;
      }

    },
    getCompanyTypeText(type) {
      return type === null ? "Todos" : CompanyType[type].description;
    },
    clearFilters() {
      this.selectedType = null;
      this.selectedFilterCompanyType = null;
      this.filter = {
        selectedTypes: [],
        selectedCompanyTypes: [],
        startDateAt: null,
        endDateAt: null
      };
      this.pagination = {
        offset: 0,
        limit: 10,
        total: 0,
        sorts: [
          "type###asc",
          "name###asc"
        ]
      };
    },
    removeTypeFromList(type) {
      
      const typeIndex = this.filter.selectedTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedTypes.splice(typeIndex, 1);
        this.selectedType = null;
      }

    },
    changeCompanyTypeFilter() {
      this.filter.selectedCompanyTypes.push(this.selectedFilterCompanyType);
      this.selectedFilterCompanyType = null;
    },
    changeTypeFilter() {
      this.filter.selectedTypes.push(this.selectedType);
      this.selectedType = null;
    },
    setOrderExecutedAux(order) {
      order.updatedAt = new Date();
      order.status = OrderStatus.EXECUTED.value;
      this.orderExecutedAux = order;
      this.orderExecutedAux.updatedAt = new Date();
      this.orderExecutedAux.executedAt = new Date();
      this.orderExecutedAuxVisible = true;
    },
    setOrderUpdateAux(order) {
      order.updatedAt = new Date();
      this.orderExecutedAux = order;
      this.orderExecutedAux.updatedAt = new Date();
      this.orderExecutedAux.executedAt = new Date();
      this.orderUpdateAuxVisible = true;
    },
    setOrderNotExecutedIdAux(id) {
      this.orderNotExecutedIdAux = id;
      this.orderNotExecutedAuxVisible = true;
    },
    getNumberOfRegisterText() {

      const offset = this.pagination.offset + 1;
      const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

      return offset + " - " + limit + " de " + this.pagination.total + " ordens pendentes";

    },
    getPlanningOrders() {

      this.tableLoading = true;
      this.pagination.limit++;
      getPlanningOrders(this.pagination.offset, this.pagination.limit, this.pagination.sorts, this.filter).then(response => {

        this.orders = response.data.orders;
        this.pagination = response.data.pagination;
        this.ordersCompanyTotal = response.data.companyTotal;
        this.ordersTotal = response.data.total;

        this.tableLoading = false;

      }).catch(() => this.tableLoading = false);

    },
    getType(order) {
      return CompanyType[order.company.type].description;
    },
    updatePlanningOrder() {

      this.orderExecutedAux.companyId = this.orderExecutedAux.company.id;
      if(this.validUpdateRequest(this.orderExecutedAux)) {

        updatePlanningOrder(this.orderExecutedAux, this.orderExecutedAux.id).then(() => {

          ElMessage({
            message: 'Ordem planejada atualizada com sucesso!',
            type: 'success'
          });

          this.getPlanningOrders();
          this.emitter.emit('refreshSummary', {});
          this.orderUpdateAuxVisible = false;

        }).catch(() => {
          ElMessage({
            message: 'Ordem planejada não pode ser atualizada, tente novamente mais tarde!',
            type: 'error'
          });
        });

      }
      

    },
    updateExecutedOrder() {

      this.orderExecutedAux.companyId = this.orderExecutedAux.company.id;
      this.orderExecutedAuxVisible = false;

      if(this.validExecutedRequest(this.orderExecutedAux)) {
        updatePlanningOrder(this.orderExecutedAux, this.orderExecutedAux.id).then(() => {

          ElMessage({
            message: 'Ordem planejada atualizada com sucesso!',
            type: 'success'
          });

          this.getPlanningOrders();
          this.emitter.emit('refreshSummary', {});

        }).catch(() => {
          ElMessage({
            message: 'Ordem planejada não pode ser atualizada, tente novamente mais tarde!',
            type: 'error'
          });
        });
      }

    },
    deletePlanningOrder() {

      this.orderNotExecutedAuxVisible = false;

      deletePlanningOrder(this.orderNotExecutedIdAux, OrderStatus.NOT_EXECUTED.value).then(() => {

        ElMessage({
          message: 'Ordem planejada cancelada com sucesso!',
          type: 'success'
        });

        this.getPlanningOrders();
        this.emitter.emit('refreshSummary', {});

      }).catch(() => {
        ElMessage({
          message: 'Ordem planejada não pode ser cancelada, tente novamente mais tarde!',
          type: 'error'
        });
      });
    },
    setPage(page) {
      this.page = page;
      this.pagination.offset = (this.page - 1) * this.pagination.limit;
      this.getPlanningOrders();
    },
    getTypeText(type) {
      return type === "Todos" ? null : OrderType[type].description;
    },
    showCreatePlanningOrderDialog() {
      this.orderConfirmationAuxVisible = true;
    },
    closePendingOrderDialog() {
      this.orderConfirmationAuxVisible = false;
      this.getPlanningOrders();
    },
    closeUpdateOrderDialog() {
      this.orderUpdateAuxVisible = false;
      this.emitter.emit('refreshSummary', {});
    },
    closeExecuteOrderDialog() {
      this.orderExecutedAuxVisible = false;
    },
    closeCancelPendingOrderDialog() {
      this.orderNotExecutedAuxVisible = false;
    },
    validExecutedRequest(order) {

      if(order.company === null || order.company === undefined || order.company.id === null || order.company.id === "") {
        return false;
      }

      if(order.amount === null || order.amount === undefined || order.amount === "" || order.amount <= 0) {
        return false;
      }

      if(order.price === null || order.price === undefined || order.price === "" || order.price <= 0) {
        return false;
      }

      if(order.type === null || order.type === undefined || order.type === "") {
        return false;
      }

      if(order.status === null || order.status === undefined || order.status === "" || order.status !== OrderStatus.EXECUTED.value) {
        return false;
      }

      if(order.updatedAt === null || order.updatedAt === undefined) {
        return false;
      }

      return true;

    },
    validUpdateRequest(order) {

      if(order.company === null || order.company === undefined || order.company.id === null || order.company.id === "") {
        return false;
      }

      if(order.amount === null || order.amount === undefined || order.amount === "" || order.amount <= 0) {
        return false;
      }

      if(order.price === null || order.price === undefined || order.price === "" || order.price <= 0) {
        return false;
      }

      if(order.type === null || order.type === undefined || order.type === "") {
        return false;
      }

      if(order.status === null || order.status === undefined || order.status === "") {
        return false;
      }

      if(order.status === OrderStatus.EXECUTED.value && (order.executedAt === null || order.executedAt === undefined)) {
        return false;
      }

      if(order.updatedAt === null || order.updatedAt === undefined) {
        return false;
      }

      return true;

    },
    getFormattedOrderPrice(order) {

      if(order.company.type !== null && order.company.type !== undefined) {
        const locale = CompanyType[order.company.type].locale;
        return getFormattedValue(order.price, locale);
      }

      return "-";

    },
    getOrderTotalValue(order) {

      if(order.company.type !== null) {

        const total = parseFloat(getTotalValue(order.amount, order.price));
        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    getCompanyTotalValue(order) {

      if(order.company.type !== null) {

        const total = order.company.balance;
        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    getFinalValue(order) {

      if(order.company.type !== null && order.company.id !== undefined) {

        const orderTotalValue = getTotalValue(order.amount, order.price);

        let total = 0;
        if(order.type == OrderType.BUY.value) {
          total = order.company.balance + parseFloat(orderTotalValue);
        } else {
          total = order.company.balance - parseFloat(orderTotalValue);
        }

        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    getUserCurrentPriceValue(order) {

      if(order.company.type !== null) {

        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(order.company.price, locale);

      }

      return "-";

    },
    getFinalUserPriceValue(order) {

      if(order.company.type !== null) {

        const companyPrice = order.company.price;
        const companyAmount = order.company.amount;
        const orderPrice = order.price;
        const orderAmount = order.amount;

        let total = 0;
        if(order.type == OrderType.BUY.value) {
          total = ((companyPrice * companyAmount) + (orderPrice * orderAmount))/(orderAmount + companyAmount);
        } else {
          total = companyAmount - orderAmount === 0 ? 0 : ((companyPrice * companyAmount) - (orderPrice * orderAmount))/(companyAmount - orderAmount);
        }

        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    getFinalAmount(order) {

      if(order.company.type !== null) {

        const orderAmount = order.amount;
        if(order.company.type === CompanyType.STOCK.value) {
          return orderAmount.toFixed(DEFAULT_STOCK_DECIMAL);
        }

        return orderAmount;

      }

      return "-";

    },
    getFinalUserAmount(order) {

      if(order.company.type !== null) {

        const companyAmount = order.company.amount;
        const orderAmount = order.amount;

        let total = 0;
        if(order.type == OrderType.BUY.value) {
          
          total = orderAmount + companyAmount;

        } else {
          total = companyAmount - orderAmount;
        }

        if(order.company.type === CompanyType.STOCK.value) {
          return total.toFixed(DEFAULT_STOCK_DECIMAL);
        }

        return total;

      }

      return "-";

    },
    getPtFormattedPrice(price) {
      return getFormattedValue(price, "pt_br");
    },
    getCompanyAmountClass(type) {
      return type !== CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";
    }
  },
  computed: {
    orderList() {

      this.orders.forEach(order => {
        order.typeClassName = OrderType[order.type].className;
        order.statusClassName = OrderStatus[order.status].className;
        order.companyTypeClassName = CompanyType[order.company.type].className;
        order.tagCompanyTypeClassName = CompanyType[order.company.type].tagTypeClassName;
        order.amountEdited = false;
        order.priceEdited = false;
      });

      return this.orders;

    },
    typeList() {

      let typeList = Object.keys(OrderType);
      typeList.splice(0, 0, 'Todos');

      return typeList;

    },
    companyType() {
      return this.companies.find(company => company.name === this.selectedCompany).type;
    },
    companyTypeList() {
      return Object.keys(CompanyType);
    },
    pendingTotal() {
      return this.orders.map(order => order.amount * order.price).reduce((partialSum, a) => partialSum + a, 0);
    },
    companyTotal() {
      return this.ordersCompanyTotal;
    },
    total() {
      return this.ordersTotal;
    },
    filterTypeList() {
      let typeList = Object.keys(OrderType);
      return typeList.filter(type => !this.filter.selectedTypes.includes(type));
    },
    companyTypeFilterList() {
      let companyTypeList = Object.keys(CompanyType);
      return companyTypeList.filter(type => !this.filter.selectedCompanyTypes.includes(type));
    },
    walletTypeList() {
      this.walletSummary.walletType.forEach(type => {
          type.typeClassName = CompanyType[type.type].className;
          type.tagTypeClassName = CompanyType[type.type].tagTypeClassName;
      });
      return this.walletSummary.walletType;
    }
  }
}
</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-responsive {
      width: 100%;
      display: block;
      font-size: 8px;
      overflow: auto;
    }

    .progress-summary {
      width: 100%;
    }

    .progress-info {
      width: 100%;
    }

    .pending-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .wallet-summary-section {
      overflow: auto;
      width: 100%;
      margin-bottom: 20px;
    }

    .refresh {
      margin-left: 2px;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .progress-summary {
      width: 100%;
    }

    .progress-info {
      width: 70%;
    }

    .pending-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .price-input {
      width: 100% !important;
    }

    .integer-amount-input {
      width: 100% !important;
    }

    .double-amount-input {
      width: 100% !important;
    }

    .create-or-update-dialog {
      width: 40%;
    }

    .refresh {
      margin-left: 5px;
    }

  }

  .order-planning-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }

  .planning-orders-total {
    font-weight: bold;
  }

  .update-planning-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .filter-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .table-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .filter-dates {
    margin: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .date-filter {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }

  .table-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .executed-order-status-class {
    background-color: green;
    font-weight: bold;
  }

  .pending-order-status-class {
    background-color: rgb(124, 124, 14) !important;
    font-weight: bold;
  }

  .table-tag {
    width: 90%;
  }

  .progress-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .progress-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .progress {
    margin-top: 5px;
    width: 100%;
  }

  .progress-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .update-action {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-top: 5px;
  }

  .refresh {
    color: $default-button-color;
    height: 30px;
    width: 30px;
  }

  .table-price-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

</style>