<template>

  <div class="transaction-view">

    <h1> Transações </h1>
    <br>

    <section-row :isFirstSection="true">
      <order-section/>
    </section-row>

    <div>
      <el-tooltip class="box-item" effect="dark" content="Voltar para o Dashboard" placement="top">
        <button @click="goToHome()" class="back-button"> Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

import { DASHBOARD_PATH } from '../../constants/RoutesConstants';
import SectionRow from '@/component/common/SectionRow';
import OrderSection from '@/section/transaction/OrderSection';

export default {
  name: 'transaction-view',
  components: { SectionRow, OrderSection },
  mounted() {
    document.title = "Transações - Personal Invest";
  },
  methods: {
    goToHome() {
      this.$router.push({ path: DASHBOARD_PATH });
    }
  }
}
</script>

<style scoped>

  .transaction-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .back-button {
    margin: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    float: right;
    cursor: pointer;
  }

</style>
