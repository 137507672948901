export default {
    BUY: {
        value: "BUY",
        description: "Compra",
        className: "buy-order-type-class",
        tagTypeClassName: "buy-tag-order-type-class"
    },
    SELL:  {
        value: "SELL",
        description: "Venda",
        className: "sell-order-type-class",
        tagTypeClassName: "sell-tag-order-type-class"
    }
}
