export default {
    ACTIVE: {
        value: "ACTIVE",
        description: "Ativo",
        color: "green"
    },
    PENDING_CONFIRMATION:  {
        value: "PENDING_CONFIRMATION",
        description: "Solicitação pendente",
        color: "red"
    }
}
