<template>

  <div class="reset-user-password">
    
    <h3> Esqueci minha senha </h3>
    
    <br>

    <div class="reset-el-input">
      <span> E-mail </span>
      <el-input type="text" minlength="1" maxlength="255" v-model="email" placeholder="foo@foo.com" class="name-el-input"/>
    </div>

    <div class="foot-buttons">
      <el-button type="success" :disabled="!validRequest()" @click="sendResetPasswordToken()"> Enviar </el-button>
      <el-tooltip class="box-item" effect="dark" content="Voltar para o Dashboard" placement="top">
        <el-button type="primary" @click="goToHome()" class="back-button"> Voltar </el-button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import { sendResetPasswordToken } from '@/http/user/anonymous-user-service';
  import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { ElMessage } from 'element-plus'

  export default {
    name: 'reset-password-token-view',
    data() {
      return {
        loadingSvg: DEFAULT_LOADING_SVG,
        email: null
      }
    },
    methods: {
      hasBadRequestMessage(error) {
        return error !== null && 
              error.response !== null && 
              error.response.data !== null && 
              error.response.data._embedded !== null &&
              error.response.data._embedded.errors !== null &&
              error.response.data._embedded.errors.length > 0 &&
              error.response.data._embedded.errors[0] !== null &&
             error.response.status === 400;
      },
      sendResetPasswordToken() {

        if(this.validRequest()) {

          const body = {
            email: this.email,
          }
          sendResetPasswordToken(body).then(() => {

            ElMessage({
              message: 'Um e-mail será enviado com as instruções necessarias!',
              type: 'success'
            });
            this.$router.push({ path: HOME_PATH })

          }).catch(error => {

            if(this.hasBadRequestMessage(error)) {

              ElMessage({
                message: error.response.data._embedded.errors[0].message,
                type: 'error'
              });

            } else {
              ElMessage({
                message: "Ocorreu um erro ao tentar enviar o e-mail de recuperação de senha, por favor tente novamente mais tarde!",
                type: 'error'
              });
            }

          });

        }

      },
      validRequest() {

        if(this.email === null || this.email === undefined || this.email === "" || this.email.length <= 0) {
          return false;
        }

        return true;

      },
      goToHome() {
        this.$router.push({ path: HOME_PATH });
      }
    }
  }

</script>

<style lang="scss" scoped>

  .reset-user-password {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .reset-el-input {
    float: left;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    flex-direction: column;
    font-weight: bold;
    width: 20%;
  }

  .foot-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 20%;
    margin-top: 20px;
  }

  .name-el-input {
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
  }

  .back-button {
    float: right;
  }

</style>
@/http/user/anonymous-user-service