<template>

  <div class="profit-summary-section">
    <div :class="getMouseOverRefresClass" v-loading="mouseOverRefresh" class="table-responsive" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">
      <section-row :isFirstSection="true">
        <profit-line-section/>
      </section-row>
    </div>
  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import ProfitLineSection from '@/section/dashboard/tab/profit/items/ProfitLineSection';
import { getProfit } from '@/http/bff/dashboard-bff-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';

export default {
  name: 'profit-summary-section',
  components: { SectionRow, ProfitLineSection },
  mounted() {
    document.title = "Dashboard - Personal Invest";
  },
  data() {
    return {
      tableLoading: false,
      summary: null,
      refreshButtonDisabled: false,
      mouseOverRefresh: false,
      loadingSvg: DEFAULT_LOADING_SVG,
      selectedTypes: []
    }
  },
  methods: {
    getProfit() {

      this.refreshButtonDisabled = true;
      this.tableLoading = true;
      this.mouseOverRefresh = true;

      getProfit(this.selectedTypes).then(response => {

        this.summary = response.data;
        this.tableLoading = false;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;

        this.emitter.emit('changeData', this.summary);

      }).catch(() => {
        this.tableLoading = false;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;
      });
    },
    registerGetProfitEvent() {
      this.emitter.on('getProfit', (selectedTypes) => {
        
        this.selectedTypes = selectedTypes;
        this.getProfit();

      });
    }
  },
  computed: {
    getMouseOverRefresClass() {
      return this.mouseOverRefresh === true ? "mouse-on-refresh" : "mouse-off-refresh";
    }
  },
  created() {
    this.getProfit();
    this.registerGetProfitEvent();
  }
}
</script>

<style lang="scss" scoped>

  .profit-summary-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .mouse-off-refresh {
    opacity: 1;
  }

  .mouse-on-refresh {
    opacity: 0.5;
  }

</style>