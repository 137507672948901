<template>

    <div class="update-pending-order-dialog" v-loading="loading" element-loading-text="Carregando..." :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

        <div class="pending-order-info">

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Ativo: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Nome </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <img v-if="newPlanningOrder.company.imageUrl != null" style="margin-right: 10px; cursor: pointer;" :src="newPlanningOrder.company.imageUrl" width="30" height="20">
                            <span class="el-tag__content"> {{ newPlanningOrder.company.name }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Categoria </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content" :class="getTagClass(newPlanningOrder.company.type)"> {{ getCompanyTypeText(newPlanningOrder.company.type) }} </span>
                        </el-tag>
                    </div>

                </div>
                

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Ordem: </span>
                <div class="pending-order-info-order">

                    <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Operação </span>
                            <el-select v-model="newPlanningOrder.type" @change="newPlanningOrderTypeChange(newPlanningOrder)" class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="orderType in originalTypeList" :key="orderType" :label="getTypeText(orderType)" :value="orderType"/>
                            </el-select>
                        </div>

                        </div>

                        <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Status </span>
                            <el-select v-if="statusList.length > 0" v-model="pendingOrder.status" @change="statusChanged(newPlanningOrder)" class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="status in statusList" :key="status" :label="getStatusText(status)" :value="status"/>
                            </el-select>
                        </div>

                        <div v-if="pendingOrder.status ===  OrderStatus.EXECUTED.value" class="dates pending-order-info-container">

                        <span class="title"> Data de execução </span>
                            <div>
                                <el-date-picker v-model="pendingOrder.executedAt" type="datetime" format="DD/MM/YYYY HH:mm:ss" placeholder="Data de execução" size="medium"/>
                            </div>
                        </div>

                        </div>

                        <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Quantidade </span>
                            <div v-if="newPlanningOrder.company.type !== null && newPlanningOrder.company.type !== undefined">
                                <el-input-number v-if="newPlanningOrder.company.type !== CompanyType.STOCK.value" v-model="newPlanningOrder.amount" :min="1"  :class="getCompanyAmountClass" :size="inputNumberSize"/>
                                <el-input-number v-if="newPlanningOrder.company.type === CompanyType.STOCK.value" v-model="newPlanningOrder.amount" :min="DEFAULT_STOCK_INITIAL_VALUE" :precision="DEFAULT_STOCK_DECIMAL" :step="DEFAULT_STOCK_INITIAL_VALUE" :class="getCompanyAmountClass" @change="orderChanged()" :size="inputNumberSize"/>
                            </div>
                            <div v-if="newPlanningOrder.company.type === null || newPlanningOrder.company.type === undefined">
                            -
                            </div>
                        </div>

                        <div class="pending-order-info-container">
                            <span class="title"> Preço </span>
                            <currency-input v-if="newPlanningOrder.company.id !== null && newPlanningOrder.price !== null" v-model="newPlanningOrder.price" :class="isDisabledClass" :disabled="atMarketValue === true" :options="getCompanyCurrency(newPlanningOrder.company.type)" @change="orderChanged()" class="price-input"/>
                            <div v-if="newPlanningOrder.company.id === null || newPlanningOrder.company.id === undefined || newPlanningOrder.price === null">
                            -
                            </div>
                            <el-switch v-if="newPlanningOrder.company.id !== null && newPlanningOrder.price !== null" v-model="atMarketValue" @change="atMarketValueChange()"/>
                        </div>

                    </div>

                </div>

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Total: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Ordem </span>
                        <currency-input v-if="newPlanningOrder.company.id !== null && newPlanningOrder.total !== undefined && newPlanningOrder.total !== null && newPlanningOrder.company.type === CompanyType.STOCK.value" v-model="newPlanningOrder.total" :options="getCompanyCurrency(newPlanningOrder.company.type)" @change="orderTotalChanged()" class="price-input"/>
                        <el-tag v-if="newPlanningOrder.company.type !== CompanyType.STOCK.value" :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewOrderTotalValue(newPlanningOrder) }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewCompanyTotalValue() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewCompanyFinalValue(newPlanningOrder) }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Quantidade: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Ordem </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getCompanyOrderAmount() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getCompanyUserAmount() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getFinalCompanyUserAmount() }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Preço médio: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getUserCompanyPriceValue() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getFinalCompanyPriceValue() }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import OrderStatus from '@/constants/OrderStatus';
    import OrderType from '@/constants/OrderType';
    import CompanyType from '@/constants/CompanyType';
    import CompanyStatus from '@/constants/CompanyStatus';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { ElMessage } from 'element-plus';
    import { getCompanyCurrentPrice } from '@/http/bff/planning-bff-service';
    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
    import { getTotalValue, getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'update-pending-order-dialog',
        components: { CurrencyInput },
        props: { planningOrder: Object },
        data() {
            return {
                OrderStatus,
                CompanyType,
                CompanyStatus,
                newPlanningOrder: null,
                loading: false,
                loadingSvg: DEFAULT_LOADING_SVG,
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                DEFAULT_LOADING_SVG,
                atMarketValue: false
            }
        },
        created() {
            this.newPlanningOrder = this.planningOrder;
        },
        methods: {
            atMarketValueChange() {

                if(this.atMarketValue === true) {
                    this.newPlanningOrder.price = this.newPlanningOrder.company.currentPrice;
                }

            },
            getStatusText(status) {
                return OrderStatus[status].description;
            },
            getCompanyText(type) {
                return CompanyType[type].description;
            },
            setCurrentPrice() {

                this.newPlanningOrder.price = null;
                const company = this.newPlanningOrder.company;
                this.loading = true;
                getCompanyCurrentPrice(company.name, this.newPlanningOrder.company.id).then(response => {
                    this.newPlanningOrder.price = response.data.currentPrice;
                    company.amount = response.data.amount;
                    company.balance = response.data.balance;
                    company.price = response.data.price;
                    this.loading = false;
                }).catch(() => {
                    ElMessage({
                        message: "Erro ao buscar valor atual do ativo",
                        type: 'error'
                    });
                    this.loading = false;
                });

            },
            resetPlanningOrderAndGetCompanies() {
                this.newPlanningOrder.company.id = null;
                this.newPlanningOrder.company.name = null;
                this.newPlanningOrder.company.balance = 0;
                this.newPlanningOrder.amount = 1;
                this.newPlanningOrder.price = null;
                this.newPlanningOrder.total = null;
                this.newPlanningOrder.type = OrderType.BUY.value;
                this.newPlanningOrder.companyId  = null;
            },
            statusChanged(order) {
                order.executedAt = null;
            },
            newPlanningOrderTypeChange(order) {

                if(order.type !== null && order.type !== undefined && order.company.id !== null && order.company.id !== undefined) {

                    if(order.type === OrderType.BUY.value) {

                        if(order.type === CompanyType.STOCK.value) {
                            order.amount = DEFAULT_STOCK_INITIAL_VALUE;
                        } else {
                            order.amount = 1;
                        }

                    } else {
                        const company = this.newPlanningOrder.company;
                        order.amount = company.amount;
                    }

                }

            },
            getCompanyCurrency(type) {

                if(type === CompanyType.STOCK.value) {
                return { currency: "USD" };
                }

                return { currency: "BRL" };

            },
            orderChanged() {
                this.newPlanningOrder.total = this.newPlanningOrder.amount * this.newPlanningOrder.price;
            },
            orderTotalChanged() {
                this.newPlanningOrder.amount = this.newPlanningOrder.total/this.newPlanningOrder.price;
            },
            getNewOrderTotalValue() {

                if(this.newPlanningOrder.company.id !== null) {

                    const companyType = this.newPlanningOrder.company.type;
                    const total = parseFloat(getTotalValue(this.newPlanningOrder.amount, this.newPlanningOrder.price));
                    const locale = CompanyType[companyType].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getNewCompanyTotalValue() {

                if(this.newPlanningOrder.company.id !== null) {

                    if(this.newPlanningOrder != null && this.newPlanningOrder.company.type != null) {
                        const locale = CompanyType[this.newPlanningOrder.company.type].locale;
                        return getFormattedValue(this.newPlanningOrder.company.balance, locale);
                    }

                }

                return "-";

            },
            getNewCompanyFinalValue(order) {

                if(this.newPlanningOrder.company.id !== null && this.newPlanningOrder.company.type !== null) {

                    const companyOrder = this.newPlanningOrder.company;
                    const orderTotalValue = parseFloat(getTotalValue(order.amount, order.price));

                    if(companyOrder != null && companyOrder.type != null && companyOrder.id !== null) {

                        let total = 0;
                        if(order.type === OrderType.BUY.value){
                        
                            const totalFinal = companyOrder.balance + orderTotalValue
                            total = parseFloat(totalFinal);

                        } else {

                            const totalFinal = companyOrder.balance - orderTotalValue
                            total = parseFloat(totalFinal);

                        }
                        const locale = CompanyType[companyOrder.type].locale;

                        return getFormattedValue(total, locale);

                    }

                }

                return "-";

            },
            getCompanyOrderAmount() {

                if(this.newPlanningOrder !== null && this.newPlanningOrder !== undefined && this.newPlanningOrder.amount !== null) {

                    if(this.newPlanningOrder.company.type === CompanyType.STOCK.value) {
                        return this.newPlanningOrder.amount.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return this.newPlanningOrder.amount;

                }

                return "-";

            },
            getCompanyUserAmount() {

                if(this.newPlanningOrder !== null && this.newPlanningOrder !== undefined && this.newPlanningOrder.company.type !== null) {

                    if(this.newPlanningOrder.company.type === CompanyType.STOCK.value) {
                        return this.newPlanningOrder.company.amount.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return this.newPlanningOrder.company.amount;

                }

                return "-";

            },
            getFinalCompanyUserAmount() {

                const company = this.newPlanningOrder.company;
                if(company !== null && company !== undefined && company.type !== null && this.newPlanningOrder.type !== null) {

                    const companyAmount = company.amount;
                    const orderAmount = this.newPlanningOrder.amount;

                    let total = 0;
                    if(this.newPlanningOrder.type == OrderType.BUY.value) {
                        total = orderAmount + companyAmount;
                    } else {
                        total = companyAmount - orderAmount;
                    }

                    if(this.newPlanningOrder.company.type === CompanyType.STOCK.value) {
                        return total.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return total;

                }

                return "-";

            },
            getUserCompanyPriceValue() {

                const company = this.newPlanningOrder.company;
                if(company !== null && company !== undefined && company.type !== null) {
                    const locale = CompanyType[company.type].locale;
                    return getFormattedValue(company.price, locale);
                }

                return "-";

            },
            getFinalCompanyPriceValue() {

                const company = this.newPlanningOrder.company;
                if(company !== null && company !== undefined && company.type !== null && this.newPlanningOrder.type !== null) {

                    const companyPrice = company.price;
                    const companyAmount = company.amount;
                    const orderPrice = this.newPlanningOrder.price;
                    const orderAmount = this.newPlanningOrder.amount;

                    let total = 0;
                    if(this.newPlanningOrder.type == OrderType.BUY.value) {
                        total = ((companyPrice * companyAmount) + (orderPrice * orderAmount))/(orderAmount + companyAmount);
                    } else {
                        total = companyAmount - orderAmount === 0 ? 0 : ((companyPrice * companyAmount) - (orderPrice * orderAmount))/(companyAmount - orderAmount);
                    }

                    const locale = CompanyType[company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getTypeText(type) {
                return OrderType[type].description;
            },
            getTagClass(type) {
                return CompanyType[type].className;
            },
            getCompanyTypeText(type) {
                return CompanyType[type].description;
            }
        },
        computed: {
            statusList() {
                let statusList = Object.keys(OrderStatus);
                return statusList.filter(status => status === OrderStatus.PENDING.value || status === OrderStatus.EXECUTED.value);
            },
            pendingOrder() {
                return this.newPlanningOrder;
            },
            companyTypeList() {
                return Object.keys(CompanyType);
            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            originalTypeList() {
                return Object.keys(OrderType);
            },
            inputNumberSize() {
                return this.isPhoneScreenWidth ? "small" : "large";
            },
            isDisabledClass() {
                return this.atMarketValue === true ? "price-disabled" : "";
            }
        }
    }

</script>

<style lang="scss" scoped>

    .update-pending-order-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .el-select {
        width: 80%;
    }

    .pending-order-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .pending-order-info-row {
        width: 100%;
        margin-bottom: 10px;
        border: 2px solid $default-primary-title-text-color;
        border-radius: 5px;
    }

    .pending-order-info-order-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .pending-order-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background-color: $dialog-background-color !important;
        margin-right: 50px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .continue {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $dialog-background-color !important;
        margin-top: 20px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 20px;
    }

    .table-tag {
        width: 90%;
    }

    .close-label {
        color: $default-primary-title-text-color !important;
    }

    .pending-order-info-type-title {
        font-weight: bold;
        font-size: 14px;
        margin-top: 5px;
        color: $default-primary-title-text-color !important;
    }

    .pending-order-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .pending-order-info-order {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: 2px solid $default-primary-title-text-color;
        border-radius: 5px;
    }

    .table-tag .el-tag__content .acao-company-type-class {
      color: $default-company-text-color !important;
    }

    .table-tag .el-tag__content .fii-company-type-class {
      color: $default-company-text-color !important;
    }

    .table-tag .el-tag__content .stock-company-type-class {
      color: $default-company-text-color !important;
    }

    .table-tag .el-tag__content .bdr-company-type-class {
      color: $default-company-text-color !important;
    }

    .table-tag .el-tag__content .fiagro-company-type-class {
      color: $default-company-text-color !important;
    }

    .table-tag .el-tag__content .etf-company-type-class {
      color: $default-company-text-color !important;
    }

    .price-disabled {
        opacity: 0.6;
    }

    @media screen and (max-width: 1024px) {

        .dates {
            width: 80%;
        }

        .dialog-confirm {
            width: 30%;
        }

        .dialog-close {
            width: 30%;
        }

        .pending-order-info-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 2px;
        }

        .pending-order-info-order-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .pending-order-info-order {
            margin: 2px;
        }

        .el-input-number {
            width: 100% !important;
        }

        .price-input {
            width: 100% !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .dates {
            width: 30%;
        }

        .dialog-confirm {
            width: 15%;
        }

        .dialog-close {
            width: 15%;
        }

        .pending-order-info-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin: 5px;
            padding: 5px;
        }

        .pending-order-info-order-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .pending-order-info-order {
            margin: 5px;
            padding: 5px;
        }

        .el-input-number {
            width: 80% !important;
        }

    }

</style>
