export default {
    IMPORT: {
        value: "IMPORT",
        description: "CEI",
        className: "import-order-origin-class"
    },
    MANUAL:  {
        value: "MANUAL",
        description: "Manual",
        className: "manual-order-origin-class"
    }
}
