// Views
export const HOME_PATH = "/";
export const USER_PATH = "/usuario";
export const DASHBOARD_PATH = "/usuario/dashboard";
export const COMMUNITY_PATH = "/usuario/comunidade";
export const COMPANIES_PATH = "/usuario/ativos";
export const TRANSACTIONS_PATH = "/usuario/transacoes";
export const USER_PROFILE_PATH = "/usuario/perfil";
export const CREATE_USER_PATH = "/usuario/cadastro";
export const USER_PLANNING_PATH = "/usuario/planejamento";
export const DIVIDEND_PATH = "/usuario/proventos";
export const RESET_USER_PASSWORD_TOKEN_PATH = "/usuario/esqueci-minha-senha";
export const RESET_USER_PASSWORD_PATH_WITHOUT_PARAM = "/usuario/atualizar-senha";
export const RESET_USER_PASSWORD_PATH = "/usuario/atualizar-senha/:token";
export const ACTIVATE_USER_PATH = "/usuario/ativacao/:activationToken";
export const ACTIVATE_USER_PATH_WITHOUT_PARAM = "/usuario/ativacao";
export const COMPANY_DETAILS_PATH = "/ativo/:name";
export const COMPANY_DETAILS_PATH_WITHOUT_PARAM = "/ativo";
export const DASHBOARD_NAME = "Dashboard";
export const COMMUNITY_NAME = "Community";
export const HOME_NAME = "Home";
export const COMPANIES_NAME = "Company";
export const TRANSACTIONS_NAME = "Transactions";
export const DIVIDEND_NAME = "Dividends";
export const USER_PROFILE_NAME = "PerfilUsuario";
export const CREATE_USER_NAME = "CadastrarUsuario";
export const USER_PLANNING_NAME = "PlanejamentoUsuario";
export const RESET_USER_PASSWORD_TOKEN_NAME = "EsqueciMinhaSenhaToken";
export const RESET_USER_PASSWORD_NAME = "EsqueciMinhaSenha";
export const ACTIVATE_USER_NAME = "AtivarUsuario";
export const COMPANY_DETAILS_NAME = "CompanyDetails";