export default class User {

    constructor() {
        this.id = null;
        this.name = "Anonimo";
        this.firstName = "";
        this.lastName = "";
        this.socialId = "";
        this.email = "";
        this.photoUrl = "";
        this.photoBase64 = "";
    }

}