import { createWebHistory, createRouter } from "vue-router";
import { isUserLogged } from "@/util/UserUtils";
import UserView from "@/view/user/User.vue";
import HomeView from "@/view/home/Home.vue";
import CompanyDetails from "@/view/company/CompanyDetails.vue";
import DashboardView from "@/view/dashboard/Dashboard.vue";
import CommunityView from "@/view/community/Community.vue";
import CompanyView from "@/view/company/Company.vue";
import TransactionView from "@/view/transaction/Transaction";
import DividendView from "@/view/dividend/Dividend";
import UserProfileView from "@/view/user/profile/UserProfile.vue";
import CreateUserView from "@/view/user/create-user/CreateUser.vue";
import ResetPasswordTokenView from "@/view/user/reset-user-password-token/ResetUserPasswordToken.vue";
import ResetPasswordView from "@/view/user/reset-user-password/ResetUserPassword.vue";
import PlanningView from "@/view/planning/Planning.vue";
import UserActivation from "@/view/user/user-activation/UserActivation.vue";
import {
  USER_PATH,
  HOME_PATH,
  DASHBOARD_PATH,
  COMMUNITY_PATH,
  COMPANIES_PATH,
  TRANSACTIONS_PATH,
  USER_PROFILE_PATH,
  CREATE_USER_PATH,
  USER_PLANNING_PATH,
  DIVIDEND_PATH,
  RESET_USER_PASSWORD_TOKEN_PATH,
  RESET_USER_PASSWORD_PATH,
  RESET_USER_PASSWORD_PATH_WITHOUT_PARAM,
  ACTIVATE_USER_PATH,
  ACTIVATE_USER_PATH_WITHOUT_PARAM,
  COMPANY_DETAILS_PATH,
  COMPANY_DETAILS_PATH_WITHOUT_PARAM,
  DASHBOARD_NAME,
  COMMUNITY_NAME,
  COMPANIES_NAME,
  TRANSACTIONS_NAME,
  DIVIDEND_NAME,
  USER_PROFILE_NAME,
  CREATE_USER_NAME,
  USER_PLANNING_NAME,
  HOME_NAME,
  RESET_USER_PASSWORD_TOKEN_NAME,
  RESET_USER_PASSWORD_NAME,
  ACTIVATE_USER_NAME,
  COMPANY_DETAILS_NAME
} from "../constants/RoutesConstants";

const routes = [
  {
    path: HOME_PATH,
    name: HOME_NAME,
    component: HomeView
  },
  {
    path: COMPANY_DETAILS_PATH,
    name: COMPANY_DETAILS_NAME,
    component: CompanyDetails
  },
  {
    path: USER_PATH,
    component: UserView,
    children: [
      {
        path: DASHBOARD_PATH,
        name: DASHBOARD_NAME,
        component: DashboardView
      },
      {
        path: COMMUNITY_PATH,
        name: COMMUNITY_NAME,
        component: CommunityView
      },
      {
        path: COMPANIES_PATH,
        name: COMPANIES_NAME,
        component: CompanyView
      },
      {
        path: TRANSACTIONS_PATH,
        name: TRANSACTIONS_NAME,
        component: TransactionView
      },
      {
        path: DIVIDEND_PATH,
        name: DIVIDEND_NAME,
        component: DividendView
      },
      {
        path: USER_PROFILE_PATH,
        name: USER_PROFILE_NAME,
        component: UserProfileView
      },
      {
        path: CREATE_USER_PATH,
        name: CREATE_USER_NAME,
        component: CreateUserView
      },
      {
        path: USER_PLANNING_PATH,
        name: USER_PLANNING_NAME,
        component: PlanningView
      },
      {
        path: RESET_USER_PASSWORD_TOKEN_PATH,
        name: RESET_USER_PASSWORD_TOKEN_NAME,
        component: ResetPasswordTokenView
      },
      {
        path: RESET_USER_PASSWORD_PATH,
        name: RESET_USER_PASSWORD_NAME,
        component: ResetPasswordView
      },
      {
        path: ACTIVATE_USER_PATH,
        name: ACTIVATE_USER_NAME,
        component: UserActivation
      }
    ]
  }
];

const router = createRouter({
  base: '/',
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {

  if(isUserLogged() == 1) {

    if(to.path === USER_PATH) {
      next({ path: DASHBOARD_PATH });
    }

    next();
    return;

  }

  if(isUserLogged() == 0 &&
    to.path !== HOME_PATH && 
    to.path !== CREATE_USER_PATH && 
    to.path !== RESET_USER_PASSWORD_TOKEN_PATH && 
    !to.path.startsWith(RESET_USER_PASSWORD_PATH_WITHOUT_PARAM) &&
    !to.path.startsWith(ACTIVATE_USER_PATH_WITHOUT_PARAM) &&
    !to.path.startsWith(COMPANY_DETAILS_PATH_WITHOUT_PARAM)) {
    next({ path: HOME_PATH });
  } else {
    next();
  }

});

export default router;
