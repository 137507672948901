<template>

  <div class="user-activation">
    
    <h3> {{ getMessage }} </h3>

    <div>
      <button @click="goToLogin()" class="back-button"> {{ getButtonText }} </button>
    </div>

  </div>

</template>

<script>

  import { activateUser } from '@/http/user/anonymous-user-service';
  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { ElMessage } from 'element-plus'

  export default {
    name: 'user-activation',
    data() {
      return {
        message: "Confirmando seu cadastro, por favor aguarde",
        confirmed: false
      }
    },
    created() {

      const activationToken = this.$route.params.activationToken;
      if(this.activationToken === null) {
        ElMessage({
          message: "Token de ativação inválido!",
          type: 'error'
        });
        this.$router.push({ path: HOME_PATH });
      } else {
        this.activateUser(activationToken);
      }

    },
    methods: {
      activateUser(activationToken) {

        activateUser(activationToken).then(() => {

          ElMessage({
            message: 'Cadastro confirmado com sucesso, você ja pode entrar em sua conta!',
            type: 'success'
          });

          this.message = "Cadastro confirmado com sucesso, você ja pode entrar em sua conta!";
          this.confirmed = true;

        }).catch(response => {
          ElMessage({
            message: response.data,
            type: 'error'
          });
        });

      },
      goToLogin() {
        this.$router.push({ path: HOME_PATH });
      }
    },
    computed: {
      getMessage() {
        return this.message;
      },
      getButtonText() {
        return this.confirmed ? "Entrar" : "Voltar";
      }
    }
  }

</script>

<style lang="scss" scoped>

  .user-activation {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .back-button {
    float: right;
  }

</style>
@/http/user/anonymous-user-service