export default {
    EXECUTED: {
        value: "EXECUTED",
        description: "Executada",
        className: "executed-order-status-class",
        tagTypeClassName: "executed-tag-order-status-class"
    },
    PENDING:  {
        value: "PENDING",
        description: "Pendente",
        className: "pending-order-status-class",
        tagTypeClassName: "pending-tag-order-status-class"
    },
    NOT_EXECUTED: {
        value: "NOT_EXECUTED",
        description: "Não executada",
        className: "not-executed-order-status-class",
        tagTypeClassName: "not-executed-tag-order-status-class"
    },
    CANCELED: {
        value: "CANCELED",
        description: "Cancelada",
        className: "cancelled-order-status-class",
        tagTypeClassName: "cancelled-tag-order-status-class"
    }
}
