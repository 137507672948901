<template>

    <div class="home-view">

        <div id="logo-id" class="logo-text">
            <img src="../../assets/logo.svg" width="100" height="100" class="img">
            <h1> Personal Invest </h1>
        </div>

        <el-skeleton :loading="banners.articles.length === 0" animated>

            <template #template>

                <h3 style="margin-top: 50px;">
                    NOTÍCIAS
                    <el-tooltip class="box-item" effect="dark" content="Notícias do dia" placement="top">
                        <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                </h3>
                <div class="skeleton-row" style="width: 100%;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 150px; margin-top: 10px; border-radius: 5px;"/>
                    <el-skeleton-item variant="image" style="width: 100%; height: 170px; margin-top: 10px; border-radius: 5px;"/>
                    <el-skeleton-item variant="image" style="width: 100%; height: 150px; margin-top: 10px; border-radius: 5px;"/>
                </div>
                <el-skeleton-item variant="text" style="width: 300px; height: 50px; margin-top: 50px;"/>

                <h3 style="margin-top: 100px;"> 
                    COTAÇÃO
                </h3>
                <div class="skeleton-row" style="width: 100%;">
                    <el-skeleton-item variant="text" style="width: 10%; height: 60px; margin-top: 10px; margin: 15px;"/>
                    <el-skeleton-item variant="text" style="width: 10%; height: 60px; margin-top: 10px; margin: 15px;"/>
                    <el-skeleton-item variant="text" style="width: 10%; height: 60px; margin-top: 10px; margin: 15px;"/>
                </div>
                <el-skeleton-item variant="button" style="width: 10%; height: 50px; margin-top: 10px;"/>
                <div class="skeleton-row" style="width: 100%; margin-top: 10px;">
                    <el-skeleton-item variant="text" style="width: 10%; height: 50px; margin-top: 10px; margin-right: 370px;"/>
                    <el-skeleton-item variant="text" style="width: 10%; height: 50px; margin-top: 10px; margin: 15px;"/>
                    <el-skeleton-item variant="text" style="width: 10%; height: 50px; margin-top: 10px; margin-left: 370px;"/>
                </div>
                <el-skeleton-item variant="text" style="width: 100%; height: 250px; margin-top: 30px;"/>

            </template>

            <template #default>

                <section-row id="banners-id">
                    <banner-view v-if="banners.articles.length > 0" bannerTitle="Notícias" bannerTitleTooltip="Notícias do dia" :banners="banners.articles" :imagePrefix="imagePrefix" />
                </section-row>

                <section-row id="search-company-id">
                    <company-search title="Busca de ativos"/>
                </section-row>
                
                <section-row id="currency-summary-id">
                    <currency-summary-section />
                </section-row>

            </template>

        </el-skeleton>

        <router-view></router-view>

    </div>

</template>

<script>

    import { convertBase64ToImage } from '../../util/AppUtils';
    import { isUserLogged, getUserFromCache } from '../../util/UserUtils';
    import Image from '../../models/Image';
    import User from '../../models/User';
    import SectionRow from '@/component/common/SectionRow';
    import CompanySearch from '@/component/common/CompanySearch';
    import BannerView from '@/layouts/Banner.vue';
    import CurrencySummarySection from '@/section/dashboard/tab/news/CurrencySummarySection';
    import { getBanners } from '@/http/user/anonymous-user-service';

    export default {
        name: 'home-view',
        components: {
            SectionRow,
            CompanySearch,
            BannerView,
            CurrencySummarySection
        },
        data() {
            return {
                image: new Image(),
                user: new User(),
                imagePrefix: "https://br.advfn.com",
                banners: {
                    count: 0,
                    articles: []
                }
            }
        },
        mounted() {
            document.title = "Personal Invest";
        },
        created() {

            this.getBanners();
            this.setUser();
            if(isUserLogged()) {
                this.user = getUserFromCache();
            }

        },
        methods: {
            setUser() {
                this.emitter.on('setUser', (user) => {
                    this.user = user;
                });
            },
            goToLogin() {
                this.user = getUserFromCache();
                this.loginDialogVisible = true;
            },
            getDescription() {
                return this.isUserOn ? "Olá, " + this.user.name : "Realize o login";
            },
            getBanners() {
                getBanners().then(response => this.banners = response.data);
            }
        },
        computed: {
            getImageUrl() {

                if(this.user !== null && this.user !== undefined && this.user.photoBase64 !== undefined && this.user.photoBase64 !== null && this.user.photoBase64 !== '') {
                    return convertBase64ToImage(this.user.photoBase64);
                }

                if(this.image.base64 !== undefined && this.image.base64 !== null && this.image.base64 !== '') {
                    return convertBase64ToImage(this.image.base64);
                }

                return "";

            },
            isUserOn() {
                return this.user !== null && this.user.id !== null;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .home-view{
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .home-login {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .left {
            width: 100%;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .right {
            width: 100%;
        }

        .logo-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .background {
            display: none;
        }

        #logo-id {
            order: 1;
        }

        #title-id {
            order: 2;
        }

        #search-company-id {
            order: 4;
        }

        #banners-id {
            order: 3;
        }

        #currency-summary-id {
            order: 5;
        }

        

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .home-view {
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .home-login {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .logo-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .background {
            border-radius: 25px;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
            width: 100%;
            height: 800px;
        }

    }

    h1 {
        margin-left: 10px;
    }

    span {
        font-size: 12px;
    }

</style>
