import { USER_INFO_CACHE_KEY, USER_TOKEN_CACHE_KEY, USER_REFRESH_TOKEN_CACHE_KEY, DOLLAR_VALUE_CACHE_KEY } from '../constants/CacheConstants';
import { DASHBOARD_PATH } from '../constants/RoutesConstants';
import { refreshTokenRequest } from '../http/user/anonymous-user-service';
import { getUserInfo } from '../http/bff/user-bff-service';
import { HOME_PATH } from '../constants/RoutesConstants';
import { EMITTER } from '../main';
import router from '../router/index';
import User from '../models/User';

export const addUserCache = (user) => {
    
    user.name = user.firstName + " " + user.lastName;
    const userCache = JSON.stringify(user);

    localStorage.setItem(USER_INFO_CACHE_KEY, userCache);

}

export const getUserCache = () => {
    return localStorage.getItem(USER_INFO_CACHE_KEY);
}

export const getUserFromCache = () => {
    const userCache = getUserCache();
    return JSON.parse(userCache);
}

export const removeUserCache = () => {
    localStorage.removeItem(USER_INFO_CACHE_KEY);
}

export const getUserIdentifierFromCache = () => {

    const userCache = getUserCache();
    const user = JSON.parse(userCache);

    return user !== null && user !== undefined ? user.userIdentifier : null;

}

export const isUserLogged = () => {
    const userCache = getUserCache();
    return userCache !== undefined && userCache !== null & userCache !== "";
}

export const addUserTokenCache = (token) => {
    localStorage.setItem(USER_TOKEN_CACHE_KEY, token);
}

export const getUserTokenCache = () => {
    return localStorage.getItem(USER_TOKEN_CACHE_KEY);
}

export const removeUserTokenCache = () => {
    localStorage.removeItem(USER_TOKEN_CACHE_KEY);
}

export const addUserRefreshTokenCache = (token) => {
    localStorage.setItem(USER_REFRESH_TOKEN_CACHE_KEY, token);
}

export const getUserRefreshTokenCache = () => {
    return localStorage.getItem(USER_REFRESH_TOKEN_CACHE_KEY);
}

export const removeUserRefreshTokenCache = () => {
    localStorage.removeItem(USER_REFRESH_TOKEN_CACHE_KEY);
}

export const refreshToken = (error) => {

    let promise;
    refreshTokenRequest().then(response => {

        const userToken = response.data;
        setUserTokens(userToken);
        router.go();

        promise = Promise.apply();

    }).catch(() => {

        removeUserCache();
        removeUserTokenCache();
        removeUserRefreshTokenCache();
        EMITTER.emit('setUserStatus', false);
        EMITTER.emit('setUser', new User());
        EMITTER.emit('setUserInfo', new User());

        router.push({ path: HOME_PATH });

        promise = Promise.reject(error);

    });

    return promise;
}

export const refreshTokenAndGetUserInfo = () => {
    return refreshTokenRequest().then(response => {
        const userToken = response.data;
        setUserTokens(userToken);
        setUserForUpdate();
    });
}

export const setUserTokens = (userToken) => {
    addUserTokenCache(userToken.access_token);
    addUserRefreshTokenCache(userToken.refresh_token);
}

export const setUser = () => {
    getUserInfo().then(userInfo => {
        addUserCache(userInfo);
    });
}

export const setUserForUpdate = () => {
    getUserInfo().then(response => {

        const user = response.data;
        user.id = user.userIdentifier;
        addUserCache(user);

        router.push({ path: DASHBOARD_PATH });

    });
}

export const removeDollarValueCache = () => {
    localStorage.removeItem(DOLLAR_VALUE_CACHE_KEY);
}
