import { httpConfiguration } from '../base-service';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/dashboard-bff-service/v1');

export const getSummary = (withDividends, userIdentifier) => {
  let params = { withDividends, userIdentifier };
  return http.get('/summary', { params });
};

export const refreshUserBalances = (body) => http.put('/balance/refresh', body);

export const getProfit = (types) => {

  let params = {};
  if(types.length !== null && types.length !== undefined) {
    params["types"] = types.toString();
  }

  return http.get('/profit', { params });

};
