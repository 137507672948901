<template>

    <div class="profile-line-section">
      
      <h3 style="margin-top: 0px;">
        RENTABILIDADE
        <el-tooltip class="box-item" effect="dark" content="Rentabilidade no período" placement="top">
            <el-icon class="info-icon"><InfoFilled/></el-icon>
        </el-tooltip>
      </h3>
      <div class="filter-collapse">

        <el-collapse>

            <el-collapse-item name="1">

              <template #title>
                <span class="filter-class collapse-border"> Filtros </span>
              </template>

              <div class="tags">
                <span> Intervalo: </span>
                <div class="tags-block">
                  <el-select v-model="selectedDateInterval" @change="changeData()" class="m-2 select interval-tags" placeholder="Selecione um intervalo" size="small">
                      <el-option v-for="dateIntervalOption in dateIntervalOptions" :key="dateIntervalOption" :label="dateIntervalMap[dateIntervalOption].label" :value="dateIntervalOption"/>
                  </el-select>
                </div>
              </div>

              <div class="tags">
                <span> Categoria: </span>
                <div class="tags-block">
                  <el-select v-if="typeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2 types-tags" placeholder="Selecione" size="small">
                    <el-option v-for="companyType in typeList" :key="companyType" :label="getTypeText(companyType)" :value="companyType"/>
                  </el-select>
                  <div class="selected-types">
                    <el-tag v-for="selectedType in selectedTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(selectedType)">
                        {{ getTypeText(selectedType) }}
                    </el-tag>
                  </div>
                </div>
              </div>

            </el-collapse-item>

        </el-collapse>

      </div>

      <div v-if="summary != null" class="statistics">

        <div class="statistic-item" v-for="walletProfitType in walletProfitTypes" :key="walletProfitType.type">
          <span style="margin-bottom: 5px;" :class="getTypeClassName(walletProfitType.type)"> <b> {{ getTypeText(walletProfitType.type) }} </b> </span>
          <el-tag key="1" class="mx-1 table-tag currency-tag" :disable-transitions="false">
            <span :class="getValueClassName(walletProfitType.profitPercentage)"> {{ getFormattedProfit(walletProfitType.profitPercentage) }} </span>
          </el-tag>
        </div>

      </div>
      <div v-if="summary != null" v-loading="!freshData" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" class="line">
        <Line v-if="showLine" :data="getData" :options="options"/>
        <div v-if="!showLine">
          <EmptyResult></EmptyResult>
        </div>
      </div>

    </div>
  
  </template>
  
<script>

  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js'
  import { Line } from 'vue-chartjs'
  import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
  import CompanyType from '@/constants/CompanyType';
  import EmptyResult from '@/component/common/EmptyResult';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  
  export default {
    name: 'profile-line-section',
    components: { Line, EmptyResult },
    mounted() {
      document.title = "Dashboard - Personal Invest";
    },
    data() {
      return {
        summary: null,
        loadingSvg: DEFAULT_LOADING_SVG,
        dateIntervalMap: {
          DAY: {
            value: "DAY",
            label: "Por dia"
          },
          MONTH: {
            value: "MONTH",
            label: "Por mês"
          }
        },
        dateIntervalOptions: [
          "DAY",
          "MONTH"
        ],
        selectedDateInterval: "DAY",
        data: {
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements:{
            point:{
              pointRadius: 0
            }
          },
          interaction: {
            intersect: false
          },
          scales: {
            y: {
              ticks: {
                stepSize: 10
              }
            }
          },
          tooltips: {
              callbacks: {
                  label: function(tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var index = tooltipItem.index;
                      return dataset.labels[index] + ': ' + dataset.data[index];
                  }
              }
          }
        },
        selectedType: null,
        selectedTypes: [],
        freshData: false,
        yearMonths: [
          {
            name: "Janeiro",
            index: 0
          },
          {
            name: "Fevereiro",
            index: 1
          },
          {
            name: "Março",
            index: 2
          },
          {
            name: "Abril",
            index: 3
          },
          {
            name: "Maio",
            index: 4
          },
          {
            name: "Junho",
            index: 5
          },
          {
            name: "Julho",
            index: 6
          },
          {
            name: "Agosto",
            index: 7
          },
          {
            name: "Setembro",
            index: 8
          },
          {
            name: "Outubro",
            index: 9
          },
          {
            name: "Novembro",
            index: 10
          },
          {
            name: "Dezembro",
            index: 11
          }
        ]
      }
    },
    created() {
      this.registerGetProfitEvent();
    },
    methods: {
      getTypeClassName(type) {
        return type === null ? "wallet-color" : CompanyType[type].tagTypeClassName;
      },
      getValueClassName(value) {
        return value >= 0 ? "above-limit" : "under-limit";
      },
      registerGetProfitEvent() {
        this.emitter.on('changeData', (summary) => {
          this.summary = summary;
          this.changeData();
        });
      },
      getTypeText(type) {
        return type === null ? "Carteira" : CompanyType[type].description;
      },
      changeTypeFilter() {

        this.selectedTypes.push(this.selectedType);
        this.selectedType = null;

        this.emitter.emit('getProfit', this.selectedTypes);

      },
      removeTypeFromList(type) {
      
        const typeIndex = this.selectedTypes.findIndex(typeFromList => typeFromList === type);
        if(typeIndex >= 0) {
          this.selectedTypes.splice(typeIndex, 1);
          this.selectedType = null;
        }

        this.emitter.emit('getProfit', this.selectedTypes);

      },
      formatDate(date) {

        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;

      },
      changeData() {

        this.freshData = false;
        this.data = {
          datasets: []
        };

        if(this.selectedDateInterval === "MONTH") {
          this.createMonthsProfitsData();
        } else {
          this.createDaysProfitsData();
        }
        
        this.freshData = true;

      },
      getColorByType(type) {
        return type === null ? "rgb(255, 182, 71)" : CompanyType[type].color;
      },
      addIbovespaMonthsProfitsData() {

        const color = "red";
        const data = this.summary.ibovespaProfit.monthProfits.filter(monthProfit => monthProfit.profitPercentage !== null && monthProfit.profitPercentage !== 0).map(monthProfit => {

          const dateSplit = monthProfit.date.split("/");
          const monthIndex = Number.parseInt(dateSplit[0]) - 1;
          const month = this.yearMonths[monthIndex];

          const formattedDate = month.name + "/" + dateSplit[1];

          return {
            x: formattedDate,
            y: monthProfit.profitPercentage
          };

        });

        if(data.length > 0) {

          const dataset = {
            label: "Ibovespa",
            color: color,
            backgroundColor: color,
            borderColor: color,
            data: data
          };

          this.data.datasets.push(dataset);

        }

      },
      addPrimeRateMonthsProfitsData() {

        const color = "#409eff";
        const data = this.summary.primeRateProfit.monthProfits.filter(monthProfit => monthProfit.profitPercentage !== null && monthProfit.profitPercentage !== 0).map(monthProfit => {

          const dateSplit = monthProfit.date.split("/");
          const monthIndex = Number.parseInt(dateSplit[0]) - 1;
          const month = this.yearMonths[monthIndex];

          const formattedDate = month.name + "/" + dateSplit[1];

          return {
            x: formattedDate,
            y: monthProfit.profitPercentage
          };

        });

        if(data.length > 0) {

          const dataset = {
            label: "CDI",
            color: color,
            backgroundColor: color,
            borderColor: color,
            data: data
          };

          this.data.datasets.push(dataset);

        }

      },
      addMonthsProfitsData() {

        this.summary.walletProfit.walletProfitTypes.forEach(walletProfitType => {

          const data = walletProfitType.monthProfits.filter(monthProfit => monthProfit.profitPercentage !== null && monthProfit.profitPercentage !== 0).map(monthProfit => {

            const dateSplit = monthProfit.date.split("/");
            const monthIndex = Number.parseInt(dateSplit[0]) - 1;
            const month = this.yearMonths[monthIndex];

            const formattedDate = month.name + "/" + dateSplit[1];

            return {
              x: formattedDate,
              y: monthProfit.profitPercentage
            };

          });

          if(data.length > 0) {

            const type = walletProfitType.type;
            const color = this.getColorByType(type);
            const label = type === null ? "Carteira" : CompanyType[walletProfitType.type].description;
            const dataset = {
              label: label,
              color: color,
              backgroundColor: color,
              borderColor: color,
              data: data
            };

            this.data.datasets.push(dataset);

          }

        });

      },
      createMonthsProfitsData() {
        this.addMonthsProfitsData();
        this.addIbovespaMonthsProfitsData();
        this.addPrimeRateMonthsProfitsData();
      },
      addIbovespaDaysProfitsData() {

        const color = "red";
        let finalData = [];
        this.summary.ibovespaProfit.monthProfits.forEach(monthProfit => {

          const dayProfitsFiltered = monthProfit.dayProfits.filter(dayProfit => {
            return dayProfit.profitPercentage !== null && dayProfit.profitPercentage !== 0;
          });
          let datas = dayProfitsFiltered.map(dayProfit => {

            const date = new Date(dayProfit.date);
            date.setHours(date.getHours() + 3);
            const formattedDate = this.formatDate(date);

            return {
              x: formattedDate,
              y: dayProfit.profitPercentage
            };

          });
          datas.forEach(data => finalData.push(data));

        });

        if(finalData.length > 0) {

          const dataset = {
            label: "Ibovespa",
            color: color,
            backgroundColor: color,
            borderColor: color,
            data: finalData
          };

          this.data.datasets.push(dataset);

        }

      },
      addPrimeRateDaysProfitsData() {

        const color = "#409eff";
        let finalData = [];
        this.summary.primeRateProfit.monthProfits.forEach(monthProfit => {

          const dayProfitsFiltered = monthProfit.dayProfits.filter(dayProfit => {
            return dayProfit.profitPercentage !== null && dayProfit.profitPercentage !== 0;
          });
          let datas = dayProfitsFiltered.map(dayProfit => {

            const date = new Date(dayProfit.date);
            date.setHours(date.getHours() + 3);
            const formattedDate = this.formatDate(date);

            return {
              x: formattedDate,
              y: dayProfit.profitPercentage
            };

          });
          datas.forEach(data => finalData.push(data));

        });

        if(finalData.length > 0) {

          const dataset = {
            label: "CDI",
            color: color,
            backgroundColor: color,
            borderColor: color,
            data: finalData
          };

          this.data.datasets.push(dataset);

        }

      },
      addDaysProfitsData() {
        
        this.summary.walletProfit.walletProfitTypes.forEach(walletProfitType => {

          let finalData = [];
          walletProfitType.monthProfits.forEach(monthProfit => {

            const dayProfitsFiltered = monthProfit.dayProfits.filter(dayProfit => {
              return dayProfit.profitPercentage !== null && dayProfit.profitPercentage !== 0;
            });
            let datas = dayProfitsFiltered.map(dayProfit => {

              const date = new Date(dayProfit.date);
              date.setHours(date.getHours() + 3);
              const formattedDate = this.formatDate(date);

              return {
                x: formattedDate,
                y: dayProfit.profitPercentage
              };

            });
            datas.forEach(data => finalData.push(data));

          });

          if(finalData.length > 0) {

            const type = walletProfitType.type;
            const color = this.getColorByType(type);
            const label = type === null ? "Carteira" : CompanyType[walletProfitType.type].description;
            const dataset = {
              label: label,
              color: color,
              backgroundColor: color,
              borderColor: color,
              data: finalData
            };

            this.data.datasets.push(dataset);

          }

        });

      },
      createDaysProfitsData() {
        this.addDaysProfitsData();
        this.addIbovespaDaysProfitsData();
        this.addPrimeRateDaysProfitsData();
      },
      getFormattedProfit(profitValue) {
        return profitValue + "%";
      }
    },
    computed: {
      showLine() {
        return this.data.datasets.length > 0 && this.freshData;
      },
      getData() {
        return this.data;
      },
      typeList() {
        let typeList = Object.keys(CompanyType);
        return typeList.filter(type => !this.selectedTypes.includes(type));
      },
      walletProfitTypes() {
        return this.summary.walletProfit.walletProfitTypes;
      }
    }
  }
</script>
  
<style lang="scss" scoped>
  
  .profile-line-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .date-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .select {
    margin: 5px;
    margin-left: 0px;
  }

  .statistics {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }

  .statistic-item {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wallet-color {
    color: "rgb(255, 182, 71)" !important;
  }

  .tags {
    width: 100%;
  }

  .tags-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .types-tags {
    flex-direction: row;
  }

  .selected-types {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {  

    .line {
      height: 300px !important;
    }

    .select {
      width: 100px;
    }

    .statistics {
      overflow: auto;
    }

    .statistic-item {
      width: 30%;
    }

    .interval-tags {
      width: 45%;
    }

    .types-tags {
      width: 65%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .line {
      height: 400px;
    }

    .statistic-item {
      width: 15%;
    }

  }
  
</style>