<template>

  <div class="magic-number-section">

    <h3> MAGIC NUMBER </h3>
    <div class="filters">

      <el-collapse v-model="magicNumberActiveNames">

        <el-collapse-item :name="1">

          <template #title>
            <span class="filter-class collapse-border"> Filtros </span>
          </template>

          <div class="filter">

            <div>
              <el-select v-model="magicNumberSelectedMonth" class="month-select" placeholder="Selecione um mês">
                <el-option
                  v-for="month in allowedMonths"
                  :key="month.label"
                  :label="month.label"
                  :value="month.label">
                </el-option>
              </el-select>
            </div>

          </div>

        </el-collapse-item>

      </el-collapse>

    </div>

    <div class="table-info">

      <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">
        
        <thead>
          <tr>
            <th> Categoria </th>
            <th> Nome </th>
            <th> Quantidade </th>
            <th> Multiplicador </th>
            <th> Total </th>
            <th> Atingido </th>
            <th> Próximo </th>
            <th> Preço </th>
            <th> Próximo (R$/US$) </th>
          </tr>
        </thead>
        <tbody v-if="currentCompaniesDividendsList.length > 0">
          <tr v-for="companyConfig in currentCompaniesDividendsList" :key="companyConfig">

            <td>
              <span :class="companyConfig.typeClassName"> {{ getType(companyConfig.type) }} </span>
            </td>

            <td @click="goToCompanyDetails(companyConfig)">

              <div class="image-name">
                <img v-if="companyConfig.imageUrl != null" style="margin-right: 10px" :src="companyConfig.imageUrl" width="30" height="20">
                <span :class="companyConfig.tagTypeClassName"> {{ companyConfig.name }} </span>
              </div>

            </td>
            
            <td>
              <span> {{ companyConfig.amount.toString().replace(".", ",") }} </span>
            </td>

            <td>
              <span> {{ companyConfig.rate.toString().replace(".", ",") }} </span>
              <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent(null, companyConfig)" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
            </td>

            <td>
              <span> {{ formatValue(companyConfig.total, companyConfig.type) }} </span>
              <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Total', companyConfig)" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
            </td>

            <td>
              <span :class="isMagicNumberReached(companyConfig.reachedMagicNumbers)"> {{ companyConfig.reachedMagicNumbers.toString().replace(".", ",") }} </span>
              <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Atingido', companyConfig)" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
            </td>

            <td>
              <span> {{ companyConfig.amountToNextMagicNumber.toString().replace(".", ",") }} </span>
              <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Próximo Magic Number', companyConfig)" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
            </td>

            <td>
              <span> {{ formatValue(companyConfig.price, companyConfig.type) }} </span>
            </td>

            <td>
              <span> {{ formatValue(companyConfig.moneyToNextMagicNumberNeeded, companyConfig.type) }} </span>
              <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Próximo Magic Number', companyConfig)" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
              </el-tooltip>
            </td>

          </tr>
        </tbody>
      
      </table>
      <div v-if="currentCompaniesDividendsList.length === 0">
        <EmptyResult></EmptyResult>
      </div>

    </div>
    <div class="table-info" v-if="currentCompaniesDividendsList.length > 0">
      <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Pelo menos um magic number atigindo'"/>
      <table-information :bgcolor="'yellow'" :bcolor="'yellow'" :text="'Nenhum magic number atigindo'"/>
    </div>

  </div>

</template>

<script>

  import { DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '@/constants/AppConstants';
  import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
  import CompanyType from '@/constants/CompanyType';
  import { getFormattedValue } from '@/util/MoneyUtils';
  import EmptyResult from '@/component/common/EmptyResult';

  export default {
    name: 'magic-number-section',
    components: { EmptyResult },
    props: {
      companiesDividends: Object,
      tableLoading: Boolean
    },
    data() {
      return {
        loadingSvg: DEFAULT_LOADING_SVG,
        magicNumberActiveNames: 1,
        magicNumberSelectedMonth: this.getCurrentMonthLabel().label
      }
    },
    methods: {
      isMagicNumberReached(value) {
        return value >= 1 ? "magic-number-reached" : "magic-number-not-reached";
      },
      formatValue(value, type) {

        if(type !== null) {
          const locale = CompanyType[type].locale;
          return getFormattedValue(value, locale);
        }

        return "-";

      },
      getMagicNumberPaymentDateContent(prefix, companyConfig) {
        const paymentDate = new Date(companyConfig.paymentDate);
        return prefix != null ? 
          prefix + " com multiplicador referente ao dia " + this.formatDate(paymentDate) : 
          "Multiplicador referente ao dia " + this.formatDate(paymentDate);
      },
      getType(type) {
        return CompanyType[type].description;
      },
      goToCompanyDetails(company) {
        const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
        window.open(formattedUrl, BLANK_PAGE_ARG).focus();
      },
      getCurrentMonthLabel() {

        const yearMonths = [
          {
            name: "Janeiro",
            index: 0
          },
          {
            name: "Fevereiro",
            index: 1
          },
          {
            name: "Março",
            index: 2
          },
          {
            name: "Abril",
            index: 3
          },
          {
            name: "Maio",
            index: 4
          },
          {
            name: "Junho",
            index: 5
          },
          {
            name: "Julho",
            index: 6
          },
          {
            name: "Agosto",
            index: 7
          },
          {
            name: "Setembro",
            index: 8
          },
          {
            name: "Outubro",
            index: 9
          },
          {
            name: "Novembro",
            index: 10
          },
          {
            name: "Dezembro",
            index: 11
          }
        ];
        const now = new Date();

        return {
          label: yearMonths[now.getMonth()].name + "/" + now.getFullYear(),
          name: yearMonths[now.getMonth()].name,
          index: now.getMonth(),
          year: now.getFullYear()
        };

      },
      monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
      }
    },
    computed: {
      allowedMonths() {

        const yearMonths = [
          {
            name: "Janeiro",
            index: 0
          },
          {
            name: "Fevereiro",
            index: 1
          },
          {
            name: "Março",
            index: 2
          },
          {
            name: "Abril",
            index: 3
          },
          {
            name: "Maio",
            index: 4
          },
          {
            name: "Junho",
            index: 5
          },
          {
            name: "Julho",
            index: 6
          },
          {
            name: "Agosto",
            index: 7
          },
          {
            name: "Setembro",
            index: 8
          },
          {
            name: "Outubro",
            index: 9
          },
          {
            name: "Novembro",
            index: 10
          },
          {
            name: "Dezembro",
            index: 11
          }
        ];

        let firstDate = new Date();

        if(this.companiesDividends.firstDividendDate) {
          firstDate = new Date(this.companiesDividends.firstDividendDate);
        }
        const allowedMonths = [];
        const monthDiff = this.monthDiff(firstDate, new Date());
        for (let index = 0; index <= monthDiff; index++) {
          
          const monthToPush = {};
          Object.assign(monthToPush, yearMonths.find(yearMonth => yearMonth.index === firstDate.getMonth()));
          
          const year = firstDate.getFullYear();
          monthToPush.label = monthToPush.name + "/" + year;
          monthToPush.year = year;

          allowedMonths.push(monthToPush);

          firstDate.setMonth(firstDate.getMonth() + 1);
          
        }

        return allowedMonths;

      },
      currentCompaniesDividendsList() {

        let companies = [];
        const allowedMonth = this.allowedMonths.find(am => am.label === this.magicNumberSelectedMonth);
        const now = new Date();
        if(allowedMonth.index === now.getMonth() && allowedMonth.year === now.getFullYear()) {
          companies = this.companiesDividends.currentCompaniesMagicNumber;
        } else {

          companies = this.companiesDividends.companies.filter(company => {

            if(company.type === CompanyType.FII.value) {
              const paymentDate = new Date(company.paymentDate);
              return allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();
            }

            return false;

          });

        }

        companies.forEach(company => {

          company.typeClassName = CompanyType[company.type].className;
          company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;
          const paymentDate = new Date(company.paymentDate);
          if(allowedMonth.index !== paymentDate.getMonth() || allowedMonth.year !== paymentDate.getFullYear()) {
            company.previousMagicNumber = true;
          }

        });

        companies.sort((c1, c2) => {
          let nameComparison = 0;
          if(c1.name < c2.name) {
            nameComparison = -1;
          }

          if(c1.name > c2.name) {
            nameComparison = 1;
          }

          return nameComparison;

        });

        return companies;

      }
    }
  }

</script>

<style lang="scss" scoped>

  .magic-number-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .filters {
    width: 100%;
  }

  .filter {
    margin: 10px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {

    .table-tag {
      width: 80%;
    }

    .table-info {
      width: 100%;
      overflow: auto;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width:  90%;
    }

    .table-info {
      width: 100%;
    }

  }

</style>
