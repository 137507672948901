<template>

	<confirm-dialog :visible="isDeleteUserViewerDialogVisible"
		headerText="Remover compartilhamento"
		confirmText="Confirmar"
		cancelText="Cancelar"
		@confirm="deleteUserViewer()"
		@cancel="closeDeleteUserViewerDialog()">
	</confirm-dialog>

	<confirm-dialog :visible="isSendWalletToShareDialogVisible"
		headerText="Enviar solicitação de compartilhamento"
		confirmText="Confirmar"
		cancelText="Cancelar"
		@confirm="sendWalletShare()"
		@cancel="closeSendWalletSharingDialog()">
	</confirm-dialog>

	<confirm-dialog :visible="isUpdateUserViewerDialogVisible"
		headerText="Aprovar compartilhamento"
		confirmText="Confirmar"
		cancelText="Cancelar"
		@confirm="updateUserViewerConfig()"
		@cancel="closeUpdateUserViewerDialog()">
	</confirm-dialog>

	<confirm-dialog :visible="isCreateUserViewerDialogVisible" 
		headerText="Solicitar compartilhamento de carteira"
		confirmText="Confirmar"
		cancelText="Cancelar"
		@confirm="openSendWalletSharingDialog(selectedUserSearch.userIdentifier)"
		@cancel="closeCreateUserViewerDialog()">

		<template #body>
			
			<div class="user-search-config-table">

				<span> <b> Buscar usuários </b> </span>
				<el-input v-model="userSearch" @keyup="getUsersByUsername()" minlength="3" maxlength="6" name="company-name" placeholder="ex: warren" size="small" class="company-search-input">
					<template #suffix>
						<el-icon class="search-icon"><Search/></el-icon>
					</template>
				</el-input>
				<el-select v-if="users.length > 0" v-model="selectedUserSearch" class="m-2 user-search-select" size="small" filterable>
					<el-option v-for="user in users" :key="user.userIdentifier" :value="user">
						<div class="user-option">
							<img :src="user.photoUrl" width="40" height="40" class="img">
							<span style="margin-left: 5px;"> {{ user.name }} </span>
						</div>
					</el-option>
				</el-select>
				<div v-if="selectedUserSearch !== null" class="create-viewer-profile">

					<img :src="selectedUserSearch.photoUrl" width="40" height="40" class="img">
					<span style="margin-left: 15px;"> {{ selectedUserSearch.name }} </span>

				</div>

			</div>

		</template>

	</confirm-dialog>

	<div class="community-user-option">
		<div class="viewers-config-table">

			<h3> Compartilhado com </h3>
			<table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

				<thead>
					<tr>
						<th> Usuário </th>
						<th> Status </th>
						<th> Ações </th>
					</tr>
				</thead>
				<tbody v-if="walletViewers.length > 0">

					<tr v-for="viewer in walletViewers" :key="viewer.id">

						<td>

							<div class="viewer-profile">

								<img :src="viewer.viewer.imageUrl" width="40" height="40" class="img">
								<span style="margin-left: 15px;"> @{{ viewer.viewer.username }} </span>

							</div>
							
						</td>

						<td>
							<span v-if="viewer.status !== null && viewer.status !== undefined"> {{ PrivacyViewerConfigStatus[viewer.status].description }} </span>
							<span v-if="viewer.status === null || viewer.status === undefined"> - </span>
						</td>

						<td>
						
							<div class="actions">

							<el-tooltip v-if="viewer.status === PrivacyViewerConfigStatus.PENDING_CONFIRMATION.value" class="box-item" effect="dark" content="Aprovar compartilhamento" placement="top">
								<el-button type="success" icon="Check" circle @click="showUpdateUserViewerDialog(viewer.id)" class="action-button"/>
							</el-tooltip>

							<el-tooltip class="box-item" effect="dark" content="Remover compartilhamento" placement="top">
								<el-button type="danger" icon="Delete" circle @click="showDeleteUserViewerDialog(viewer.id)" class="action-button"/>
							</el-tooltip>

							</div>

						</td>

					</tr>

				</tbody>

			</table>
			<div v-if="walletViewers.length === 0">
				<EmptyResult></EmptyResult>
			</div>

		</div>

		<div class="viewers-config-table">

			<div class="table-buttons">
				<h3> Com acesso </h3>
				<el-button type="success" @click="isCreateUserViewerDialogVisible = true"> Solicitar </el-button>
			</div>
			<table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

				<thead>
					<tr>
						<th> Usuário </th>
						<th> Status </th>
						<th> Ações </th>
					</tr>
				</thead>
				<tbody v-if="walletViewing.length > 0">

					<tr v-for="viewing in walletViewing" :key="viewing.id">

						<td>

							<div class="viewer-profile">

							<img :src="viewing.viewer.imageUrl" width="40" height="40" class="img">
							<span style="margin-left: 15px;"> @{{ viewing.viewer.username }} </span>

							</div>
							
						</td>

						<td>
							<span v-if="viewing.status !== null && viewing.status !== undefined"> {{ PrivacyViewerConfigStatus[viewing.status].description }} </span>
							<span v-if="viewing.status === null || viewing.status === undefined"> - </span>
						</td>

						<td>
						
							<div class="actions">
								<el-tooltip v-if="viewing.status === PrivacyViewerConfigStatus.ACTIVE.value" class="box-item" effect="dark" content="Visualizar carteira" placement="top">
									<el-button type="success" icon="View" circle :disabled="userView !== null && userView.userIdentifier === viewing.viewer.userIdentifier" @click="getSummary(viewing.viewer)" class="action-button"/>
								</el-tooltip>
								<el-tooltip class="box-item" effect="dark" content="Remover compartilhamento" placement="top">
									<el-button type="danger" icon="Delete" circle @click="showDeleteUserViewerDialog(viewing.id)" class="action-button"/>
								</el-tooltip>
							</div>

						</td>

					</tr>

				</tbody>

			</table>
			<div v-if="walletViewing.length === 0">
				<EmptyResult></EmptyResult>
			</div>

		</div>

	</div>

</template>

<script>

import { ElMessage } from 'element-plus';
import { getUserPrivacyConfig, getUsersByUsername, createUserViewerConfig, deleteUserViewerConfig, updateUserViewerConfig } from '@/http/bff/user-bff-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import PrivacyViewerConfigStatus from '@/constants/PrivacyViewerConfigStatus';
import ConfirmDialog from "@/component/common/ConfirmDialog";
import EmptyResult from '@/component/common/EmptyResult';

export default {
	name: 'community-user-option-section',
	components: { ConfirmDialog, EmptyResult },
	data() {
		return {
			PrivacyViewerConfigStatus,
			privacyConfig: {
				configurations: [],
				viewers: [],
				viewing: []
			},
			isDeleteUserViewerDialogVisible: false,
			userViewerToDelete: null,
			userSearch: null,
			usersFromSearch: [],
			isSendWalletToShareDialogVisible: false,
			selectedUserIdentifierToShareWallet: null,
			isUpdateUserViewerDialogVisible: false,
			isCreateUserViewerDialogVisible: false,
			userViewerToUpdate: null,
			selectedUserSearch: null,
			tableLoading: false,
			loadingSvg: DEFAULT_LOADING_SVG,
			userView: null
		}
	},
	created() {
		this.getUserPrivacyConfig();
	},
	methods: {
		getUserPrivacyConfig() {

			this.tableLoading = true;
			getUserPrivacyConfig().then(response => {
				this.privacyConfig = response.data;
				this.tableLoading = false;
			}).catch(() => this.tableLoading = false);

		},
		getUsersByUsername() {

			if(this.userSearch !== null && this.userSearch.length >= 3) {
			
				getUsersByUsername(this.userSearch).then(response => {
					this.usersFromSearch = response.data.users;
				});
			}

		},
		getSummary(viewer) {
			this.emitter.emit('viewerUser', viewer);
			this.userView = viewer;
		},
		openSendWalletSharingDialog(userIdentifier) {
			this.selectedUserIdentifierToShareWallet = userIdentifier;
			this.isSendWalletToShareDialogVisible = true;
		},
		closeSendWalletSharingDialog() {
			this.selectedUserIdentifierToShareWallet = null;
			this.isSendWalletToShareDialogVisible = false;
			this.isCreateUserViewerDialogVisible = false;
			this.selectedUserIdentifierToShareWallet = null;
			this.selectedUserSearch = null;
		},
		closeCreateUserViewerDialog() {
			this.usersFromSearch = [];
			this.isCreateUserViewerDialogVisible = false;
			this.selectedUserIdentifierToShareWallet = null;
			this.selectedUserSearch = null;
		},
		sendWalletShare() {

			const body = {
				viewerUserIdentifier: this.selectedUserIdentifierToShareWallet
			};
			createUserViewerConfig(body).then(() => {

				ElMessage({
					message: 'Solicitação enviada com sucesso!',
					type: 'success'
				});
				this.isSendWalletToShareDialogVisible = false;
				this.isCreateUserViewerDialogVisible = false;
				this.getUserPrivacyConfig();
				this.usersFromSearch = [];
				this.userSearch = null;
				this.selectedUserIdentifierToShareWallet = null;
				this.selectedUserSearch = null;

			}).catch(() => {

				ElMessage({
					message: 'Solicitação não pode ser enviada, tente novamente mais tarde!',
					type: 'error'
				});
				this.isSendWalletToShareDialogVisible = false;
				this.usersFromSearch = [];

			});
			
		},
		showDeleteUserViewerDialog(id) {
			this.userViewerToDelete = id;
			this.isDeleteUserViewerDialogVisible = true;
		},
		closeDeleteUserViewerDialog() {
			this.userViewerToDelete = null;
			this.isDeleteUserViewerDialogVisible = false;
		},
		deleteUserViewer() {
		
			deleteUserViewerConfig(this.userViewerToDelete).then(() => {

				ElMessage({
					message: 'Compartilhamento removido com sucesso!',
					type: 'success'
				});
				this.isDeleteUserViewerDialogVisible = false;
				this.getUserPrivacyConfig();
				this.userViewerToDelete = null;

			}).catch(() => {

				ElMessage({
					message: 'Compartilhamento não pode ser removido, tente novamente mais tarde!',
					type: 'error'
				});
				this.isDeleteUserViewerDialogVisible = false;
				this.userViewerToDelete = null;

			});
		},
		updateUserViewerConfig() {

			updateUserViewerConfig(this.userViewerToUpdate).then(() => {

			ElMessage({
				message: 'Compartilhamento aprovado com sucesso!',
				type: 'success'
			});
			this.isUpdateUserViewerDialogVisible = false;
			this.getUserPrivacyConfig();
			this.userViewerToUpdate = null;

			}).catch(() => {

			ElMessage({
				message: 'Compartilhamento não pode ser aprovado, tente novamente mais tarde!',
				type: 'error'
			});
			this.isUpdateUserViewerDialogVisible = false;
			this.userViewerToUpdate = null;

			});

		},
		showUpdateUserViewerDialog(id) {
			this.userViewerToUpdate = id;
			this.isUpdateUserViewerDialogVisible = true;
		},
		closeUpdateUserViewerDialog() {
			this.userViewerToUpdate = null;
			this.isUpdateUserViewerDialogVisible = false;
		}
	},
	computed: {
		users() {
			return this.usersFromSearch;
		},
		walletViewers() {
			return this.privacyConfig.viewers;
		},
		walletViewing() {
			return this.privacyConfig.viewing;
		}
	}
}
</script>

<style lang="scss" scoped>

	.community-user-option {
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.viewer-profile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.create-viewer-profile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
	}

	.user-option {
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 2px;
	}

	.user-search-config-table {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
	}

	.company-search-input {
		margin-top: 2px;
	}

	.table-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}

	@media screen and (min-width: 1024px) and (max-width: 1920px) {

		.company-search-input {
            width: 130px;
            height: 30px;
            margin-bottom: 2px;
        }

	}

</style>