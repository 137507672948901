export default {
    ACTIVE: {
        value: "ACTIVE",
        description: "Ativo",
        className: "active-company-status-class"
    },
    INACTIVE:  {
        value: "INACTIVE",
        description: "Inativo",
        className: "inactive-company-status-class"
    }
}
