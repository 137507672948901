<template>

    <div class="filter-collapse">

      <h3> ORDENS </h3>
      <el-collapse>

        <el-collapse-item name="1">

          <template #title>
              <span class="filter-class collapse-border"> Filtros </span>
          </template>

          <div class="tags">
            <span> Operação: </span>
            <div>
              <el-select v-if="typeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2" placeholder="Selecione" size="small">
                <el-option v-for="companyType in typeList" :key="companyType" :label="getTypeText(companyType)" :value="companyType"/>
              </el-select>
              <el-tag v-for="selectedType in filter.selectedTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(selectedType)">
                {{ getTypeText(selectedType) }}
              </el-tag>
            </div>
          </div>

          <div class="tags">
            <span> Status: </span>
            <div>
              <el-select v-if="statusList.length > 0" v-model="selectedStatus" @change="changeStatusFilter()" class="m-2" placeholder="Selecione" size="small">
                <el-option v-for="status in statusList" :key="status" :label="getStatusText(status)" :value="status"/>
              </el-select>
              <el-tag v-for="status in filter.selectedStatus" :key="status" class="mx-1 tag" closable :disable-transitions="false" @close="removeStatusFromList(status)">
                {{ getStatusText(status) }}
              </el-tag>
            </div>
          </div>

          <div class="tags">
            <span> Categoria: </span>
            <div>
              <el-select v-if="companyTypeList.length > 0" v-model="selectedCompanyType" @change="changeCompanyTypeFilter()" class="m-2" placeholder="Selecione" size="small">
                <el-option v-for="companyType in companyTypeList" :key="companyType" :label="getCompanyTypeText(companyType)" :value="companyType"/>
              </el-select>
              <el-tag v-for="selectedType in filter.selectedCompanyTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeCompanyTypeFromList(selectedType)">
                  {{ getCompanyTypeText(selectedType) }}
              </el-tag>
            </div>
          </div>

          <div class="dates">
            <span> Data de execução </span>
            <div>
              <el-date-picker v-model="filter.range" type="daterange" unlink-panels format="DD/MM/YYYY" range-separator="até" start-placeholder="Data de execução" end-placeholder="Data de execução" size="medium"/>
            </div>
          </div>

        </el-collapse-item>

      </el-collapse>

    </div>

    <div class="filter-buttons">
      <button @click="clearFilters()" class="clear-button"> Limpar </button>
      <button @click="getOrdersByFilter()" class="search-button"> Buscar </button>
    </div>

    <div class="table-buttons">
      
      <el-tooltip class="box-item" effect="dark" content="Buscar pelo nome do ativo" placement="top">
        <el-input v-model="filter.searchText" suffix-icon="Search" class="w-10 m-2 search-input" placeholder="ex.: AMZN" @keyup="getOrderWithText()"/>
      </el-tooltip>

    </div>

    <table v-loading="tableLoading" class="table-responsive" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

        <thead>

          <tr>
            <th> <span> Categoria </span> </th>
            <th> Ativo </th>
            <th> Quantidade </th>
            <th> Preço </th>
            <th> Total da ordem </th>
            <th> Ação </th>
            <th> Status </th>
            <th>
              <span> Executada em </span>
              <el-icon v-if="getOrder('executedAt') === 'decrescente'" @click="changeSortOrder('executedAt')"><CaretBottom/></el-icon>
              <el-icon v-if="getOrder('executedAt') === 'crescente' || getOrder('executedAt') === 'cresc'" @click="changeSortOrder('executedAt')"><CaretTop/></el-icon>
            </th>
            <th> Criada em </th>
            <th> Origem </th>
            <th> Ação </th>
          </tr>

        </thead>

        <tbody v-if="orders.length > 0">

          <tr v-for="order in orderList" :key="order.id">
            <td>
              <span :class="order.companyTypeClassName"> {{ getType(order) }} </span>
            </td>
            <td @click="goToCompanyDetails(order.company)">

              <div class="image-name">
                <img v-if="order.company.imageUrl != null" style="margin-right: 10px" :src="order.company.imageUrl" width="30" height="20">
                <span :class="order.tagCompanyTypeClassName"> {{ order.company.name }} </span>
              </div>
              
            </td>
            <td>
              <span> {{ getFormattedAmount(order) }} </span>
            </td>
            <td>
              <span> {{ getOrderPriceValue(order) }} </span>
            </td>
            <td>
              <span> {{ getOrderTotalValue(order) }} </span>
            </td>
            <td>
              <span :class="order.tagOrderTypeClassName"> {{ getAction(order) }} </span>
            </td>
            <td>
              <span :class="order.statusClassName"> {{ getStatus(order) }} </span>
            </td>
            <td>
              <span>  {{ getDateTime(order.executedAt) }} </span>
            </td>
            <td>
              <span>  {{ getDateTime(order.createdAt) }} </span>
            </td>
            <td>
              <span :class="order.originClassName"> {{ getOrigin(order.origin) }} </span>
            </td>
            <td>
              <el-tooltip class="box-item" effect="dark" content="Cancelar ordem executada" placement="top">
                <el-button type="danger" icon="Delete" circle @click="setOrderCanceledAux(order)" class="action-button"/>
              </el-tooltip>
            </td>
          </tr>

          <tr>
            <td class="total-row"> GERAL </td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"> {{ ordersTotal }} </td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"></td>
            <td class="total-row"></td>
          </tr>

        </tbody>

    </table>

    <div v-if="orders.length === 0 && !tableLoading">
        <EmptyResult></EmptyResult>
    </div>

    <div v-if="orders.length > 0">

      <div class="pagination">
        <el-select v-model="pagination.limit" @change="getOrders()" class="pagination-select">

          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>

        </el-select>
        <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" :current-page="page" @current-change="setPage"/>
      </div>

      <div class="number-register">
        {{ getNumberOfRegisterText() }}
      </div>

    </div>

</template>

<script>

import { DEFAULT_STOCK_DECIMAL, COMMA, DOT, DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '@/constants/AppConstants';
import { getTotalValue, getFormattedValue } from '@/util/MoneyUtils';
import { getOrders } from '@/http/bff/transaction-bff-service';
import { DEFAULT_SORT_DIVISOR } from '@/constants/AppConstants';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
import OrderStatus from '@/constants/OrderStatus';
import OrderType from '@/constants/OrderType';
import OrderOrigin from '@/constants/OrderOrigin';
import CompanyType from '@/constants/CompanyType';
import EmptyResult from '@/component/common/EmptyResult';

export default {
  name: 'order-section',
  components: { EmptyResult },
  props: {
    userIdentifier: String
  },
  data() {
    return {
      orders: [],
      pagination: {
        offset: 0,
        limit: 10,
        total: 0,
        sorts: [
          "executedAt###desc",
          "type###asc",
          "name###asc"
        ]
      },
      OrderStatus,
      OrderType,
      OrderOrigin,
      page: 1,
      filter: {
        selectedTypes: [],
        selectedStatus: [ OrderStatus.EXECUTED.value ],
        selectedCompanyTypes: [],
        startDateAt: null,
        endDateAt: null,
        range: []
      },
      selectedType: null,
      selectedStatus: null,
      selectedCompanyType: null,
      tableLoading: false,
      loadingSvg: DEFAULT_LOADING_SVG,
      options: [{
        value: '5',
        label: '5'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '10000000',
        label: 'TODOS'
      }],
      dollarValue: null
    }
  },
  mounted() {
    document.title = "Transações - Personal Invest";
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrdersByFilter() {
      this.pagination.limit = 10;
      this.getOrders();
    },
    getFormattedAmount(order) {

      if(order.company.type !== null && order.company.type === CompanyType.STOCK.value) {
        return order.amount.toFixed(DEFAULT_STOCK_DECIMAL).replace(DOT, COMMA);
      }

      return order.amount;

    },
    getOrderWithText() {
      if(this.filter.searchText !== null && this.filter.searchText !==  undefined && (this.filter.searchText.length >= 3 || this.filter.searchText.length === 0)) {
        this.getOrders();
      }
    },
    clearFilters() {
      this.selectedType = null;
      this.selectedStatus = null;
      this.selectedCompanyType = null;
      this.filter = {
        selectedTypes: [],
        selectedStatus: [ OrderStatus.EXECUTED.value ],
        selectedCompanyTypes: [],
        startDateAt: null,
        endDateAt: null,
        range: []
      };
    },
    changeSortOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "desc";
        } else if (sortOrder === "desc") {
          this.pagination.sorts[sortIndex] = fieldName + DEFAULT_SORT_DIVISOR + "asc";
        }

        this.getOrders();

      }

    },
    getOrder(fieldName) {

      let sortIndex = this.pagination.sorts.findIndex(sort => sort.split(DEFAULT_SORT_DIVISOR)[0] === fieldName);
      if(sortIndex !== null && sortIndex !== undefined && sortIndex >= 0) {

        const sortOrder = this.pagination.sorts[sortIndex].split(DEFAULT_SORT_DIVISOR)[1];
        if(sortOrder === "asc") {
          return "crescente";
        } else if (sortOrder === "desc") {
          return "decrescente"
        }

      }

      return "cresc";

    },
    removeStatusFromList(status) {
      
      const statusIndex = this.filter.selectedStatus.findIndex(statusFromList => statusFromList === status);
      if(statusIndex >= 0) {
        this.filter.selectedStatus.splice(statusIndex, 1);
        this.selectedStatus = null;
      }

    },
    removeTypeFromList(type) {
      
      const typeIndex = this.filter.selectedTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedTypes.splice(typeIndex, 1);
        this.selectedType = null;
      }

    },
    removeCompanyTypeFromList(type) {

      const typeIndex = this.filter.selectedCompanyTypes.findIndex(typeFromList => typeFromList === type);
      if(typeIndex >= 0) {
        this.filter.selectedCompanyTypes.splice(typeIndex, 1);
        this.selectedCompanyType = null;
      }

    },
    getNumberOfRegisterText() {

      const offset = this.pagination.offset + 1;
      const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

      return offset + " - " + limit + " de " + this.pagination.total + " ordens";

    },
    getOrders() {

      this.tableLoading = true;
      this.setDatesFilter();
      this.filter.userIdentifier = this.userIdentifier;

      this.orders = getOrders(this.pagination.offset, this.pagination.limit, this.pagination.sorts, this.filter).then(response => {
        this.orders = response.data.orders;
        this.pagination = response.data.pagination;
        this.dollarValue = response.data.dollarValue;
        this.tableLoading = false;
      }).catch(() => this.tableLoading = false);
      
    },
    getType(order) {
      return CompanyType[order.company.type].description;
    },
    getAction(order) {
      return OrderType[order.type].description;
    },
    getStatus(order) {
      return OrderStatus[order.status].description;
    },
    getDateTime(dateTime) {

      if(dateTime !== undefined && dateTime !== null) {
        var date = new Date(dateTime);
        return date.toLocaleDateString();
      }

      return null;

    },
    getOrigin(origin) {
      return OrderOrigin[origin].description;
    },
    getEditStatusText(status) {
      return status == OrderStatus.EXECUTED.value ? "Pendente" : "Executada";
    },
    setOrderCanceledAux(order) {

      if(this.isCancelable(order)) {
        this.orderCanceledAux = order;
        this.orderCanceledAuxVisible = true;
      }

    },
    minus() {
      return "<";
    },
    plus() {
      return ">";
    },
    setPage(page) {
      this.page = page;
      this.pagination.offset = (this.page - 1) * this.pagination.limit;
      this.getOrders();
    },
    lastPage() {
      if(this.page !== undefined && this.page > 1) {
        this.page--;
        this.pagination.offset = (this.page - 1) * this.pagination.limit;
        this.getOrders();
      }
    },
    nextPage() {
      if(this.page < this.pages()) {
        this.page++;
        this.pagination.offset = (this.page - 1) * this.pagination.limit;
        this.getOrders();
      }
    },
    pages() {
      return this.pagination.total % this.pagination.limit == 0 ? this.pagination.total / this.pagination.limit : parseInt(this.pagination.total / this.pagination.limit) + 1;
    },
    changeTypeFilter() {
      this.filter.selectedTypes.push(this.selectedType);
      this.selectedType = null;
    },
    changeStatusFilter() {
      this.filter.selectedStatus.push(this.selectedStatus);
      this.selectedStatus = null;
    },
    changeCompanyTypeFilter() {
      this.filter.selectedCompanyTypes.push(this.selectedCompanyType);
      this.selectedCompanyType = null;
    },
    getStatusText(status) {
      return status === null ? "Todos" : OrderStatus[status].description;
    },
    getTypeText(type) {
      return type === null ? "Todos" : OrderType[type].description;
    },
    getCompanyTypeText(type) {
      return type === null ? "Todos" : CompanyType[type].description;
    },
    getOrderPriceValue(order) {

      if(order.company !== null && order.company.type !== null) {
        const locale = CompanyType[order.company.type].locale;
        return getFormattedValue(order.price, locale);
      }

      return "-";

    },
    getOrderTotalValue(order) {

      if(order.company.type !== null) {

        const total = parseFloat(getTotalValue(order.amount, order.price));
        const locale = CompanyType[order.company.type].locale;

        return getFormattedValue(total, locale);

      }

      return "-";

    },
    setDatesFilter() {
      this.filter.startDateAt = this.filter.range.length >= 1 ? this.filter.range[0] : null;
      this.filter.endDateAt = this.filter.range.length >= 2 ? this.filter.range[1] : null;
    },
    goToCompanyDetails(company) {
      const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
      window.open(formattedUrl, BLANK_PAGE_ARG).focus();
    }
  },
  computed: {
    orderList() {
      
      this.orders.forEach(order => {
        order.typeClassName = OrderType[order.type].className;
        order.statusClassName = OrderStatus[order.status].className;
        order.companyTypeClassName = CompanyType[order.company.type].className;
        order.tagOrderTypeClassName = OrderType[order.type].tagTypeClassName;
        order.tagCompanyTypeClassName = CompanyType[order.company.type].tagTypeClassName;
        order.originClassName = order.origin === "MANUAL" ? "local-origin-tag-class" : "cei-origin-tag-class";
      });

      return this.orders;

    },
    selectedStatusColumnText() {
      return this.filter.selectedStatus == OrderStatus.EXECUTED.value ? "Executada" : "Pendente";
    },
    typeList() {
      let typeList = Object.keys(OrderType);
      return typeList.filter(type => !this.filter.selectedTypes.includes(type));
    },
    statusList() {
      let statusList = Object.keys(OrderStatus);
      return statusList.filter(status => !this.filter.selectedStatus.includes(status));
    },
    companyTypeList() {
      let companyTypeList = Object.keys(CompanyType);
      return companyTypeList.filter(type => !this.filter.selectedCompanyTypes.includes(type));
    },
    ordersTotal() {
      const total = this.orders.map(order => {

        let orderTotal = order.amount * order.price;
        if(order.company !== null && order.company.type !== null && CompanyType.STOCK.value === order.company.type && this.dollarValue !== null) {
          orderTotal = orderTotal * this.dollarValue;
        }

        return orderTotal;

      }).reduce((partialSum, a) => partialSum + a, 0); 
      return getFormattedValue(total, 'pt_br');
    }
  }
}
</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-responsive {
      width: 100%;
      display: block;
      font-size: 8px;
      overflow: auto;
    }

    .table-tag {
      width: 90%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width: 70%;
    }

  }

  .orderedHeader {
    cursor: pointer;
  }

  .executed-order {
    color: #42b983;
  }

  .pending-order {
    color: rgb(124, 124, 14);
  }
  .number-register {
    display: flex;
    flex-direction: row;
    margin: 1px;
    margin-top: 15px;
    text-align: center;
    float: right;
    color: gray;
  }

  .name-item {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
  }

  .name {
    margin-top: 10px;
  }

  .date-picker {
    margin-left: 5px;
    margin-right: 5px;
  }

  .current-sort-order {
    color: #ddd;
    font-size: 12px;
  }

  .filter-buttons {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .dates {
    margin: 10px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .date-filter {
    margin: 5px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }

  .file-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

</style>

